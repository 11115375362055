import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

function ProjectOverView({ slug }) {
    const [isToggled, setIsToggled] = useState(false);

    const handleToggle = () => {
        setIsToggled(!isToggled);
    };

    const listSkills = [
        {
            item: "html",
        },
        {
            item: "css",
        },
        {
            item: "js",
        },
        {
            item: "react",
        },
        {
            item: "html",
        },
        {
            item: "html",
        },
    ];

    return (
        <MainContainer>
            <Container>
                <HeadContainer>
                    <HeadLeft>
                        <EditButton onClick={() => ""}>
                            <EditIcon>
                                <img
                                    src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/11-03-2024/edit.svg"
                                    alt="edit-icon"
                                />
                            </EditIcon>
                            <EditText to={`/projects/add-project/?pk=${slug}`}>
                                Edit info
                            </EditText>
                        </EditButton>
                    </HeadLeft>
                    <HeadRight>
                        <TextContainer>
                            <LeftText>Unpublish</LeftText>
                            <ToggleContainer>
                                <ToggleButton
                                    onClick={handleToggle}
                                    toggled={isToggled}
                                />
                            </ToggleContainer>
                            <RightText>Publish</RightText>
                        </TextContainer>
                        <AssignButton>
                            <AssignIcon>
                                <img
                                    src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/11-03-2024/icon.svg"
                                    alt="plusicon"
                                />
                            </AssignIcon>
                            <AssignText>Assigned to</AssignText>
                        </AssignButton>
                    </HeadRight>
                </HeadContainer>
                <BottomContainer>
                    <BottonLeft>
                        <VideoSection>
                            <img
                                src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/11-03-2024/frame.png"
                                alt="image"
                            />
                        </VideoSection>
                        <TextSection>
                            Welcome to the WebDev Showcase project! This
                            exciting journey is all about learning web
                            development in a hands-on way. Imagine creating your
                            own special webpage – that's what we're going to do
                            together. First, we'll use different codes (HTML
                            tags) to build the structure of your webpage. Then,
                            we'll make it look cool and work well on phones and
                            computers with another code called CSS. You'll even
                            get to add a picture and a video to make it unique!
                            We'll make it easy for people to explore your page
                            with a simple menu. You can add buttons and forms to
                            make it interactive, like a mini-website. As you
                            work on your project, you'll keep track of your
                            changes on a special website called GitHub. And
                            don't worry, we'll make sure it looks good on all
                            web browsers! You have about 15 days to finish your
                            project – a perfect amount of time to learn and have
                            fun. For every cool thing you do, you'll earn
                            points, and everyone will see your achievements on
                            your profile
                        </TextSection>
                    </BottonLeft>
                    <BottomRight>
                        <Label>Project Assets</Label>
                        <AssetsCard>
                            <Card>
                                <TextDiv>
                                    <Icon>
                                        <img
                                            src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/11-03-2024/group.svg"
                                            alt="icon"
                                        />
                                    </Icon>
                                    <IconText>Figma Design</IconText>
                                </TextDiv>
                                <Size>235 Kb</Size>
                                <Button>View File</Button>
                            </Card>
                            <Card>
                                <TextDiv>
                                    <Icon>
                                        <img
                                            src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/11-03-2024/group.svg"
                                            alt="icon"
                                        />
                                    </Icon>
                                    <IconText>Figma Design</IconText>
                                </TextDiv>
                                <Size>235 Kb</Size>
                                <Button>View File</Button>
                            </Card>
                            <Card>
                                <TextDiv>
                                    <Icon>
                                        <img
                                            src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/11-03-2024/group.svg"
                                            alt="icon"
                                        />
                                    </Icon>
                                    <IconText>Figma Design</IconText>
                                </TextDiv>
                                <Size>235 Kb</Size>
                                <Button>View File</Button>
                            </Card>
                        </AssetsCard>
                        <Label>Skill</Label>
                        <SkillCard>
                            <Skills>
                                {listSkills?.map((item, index) => (
                                    <SkillList key={index}>
                                        {item.item}
                                    </SkillList>
                                ))}
                            </Skills>
                        </SkillCard>
                    </BottomRight>
                </BottomContainer>
            </Container>
        </MainContainer>
    );
}

export default ProjectOverView;

const MainContainer = styled.div``;
const Container = styled.div``;
const HeadContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    border: 1px solid #2c2c2c;
    padding: 16px;
    border-radius: 4px;
    background-color: #1b1c1f;
    margin-bottom: 20px;
`;
const HeadLeft = styled.div``;
const EditButton = styled.div`
    cursor: pointer;
    background-color: rgb(27, 28, 31);
    border: 1px solid rgb(254, 103, 58);
    display: flex;
    align-items: center;
    padding: 6px 14px;
    border-radius: 6px;
`;
const EditIcon = styled.span`
    display: inline-block;
    width: 16px;
    margin-right: 6px;
    img {
        display: block;
        width: 100%;
    }
`;
const EditText = styled(Link)`
    font-family: "gordita_medium";
    font-size: 14px;
    color: #fff;
`;
const HeadRight = styled.div`
    display: flex;
    align-items: center;
`;
const TextContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
`;
const AssignButton = styled.div`
    margin-left: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px 6px;
    border-radius: 8px;
    width: 160px;
    height: 45px;
    background-color: #fe673a;
    cursor: pointer;
`;
const AssignIcon = styled.span`
    display: inline-block;
    width: 15px;
    margin-right: 10px;
    img {
        display: block;
        width: 100%;
    }
`;
const AssignText = styled.h5`
    font-family: "gordita_medium";
    font-size: 15px;
    color: #fff;
`;
const BottomContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
`;

const LeftText = styled.h4`
    color: #9c9c9c;
    height: 20px;
    font-family: "gordita_medium";
    font-size: 16px;
`;

const RightText = styled.h4`
    color: #9c9c9c;
    height: 20px;
    font-family: "gordita_medium";
    font-size: 16px;
`;

const ToggleContainer = styled.div`
    height: 33px;
`;

const ToggleButton = styled.button`
    width: 40px;
    height: 22px;
    border: none;
    border-radius: 12px;
    background-color: ${({ toggled }) => (toggled ? "#fe673a" : "#2c2c2c")};
    cursor: pointer;
    position: relative;

    &::before {
        content: "";
        width: ${({ toggled }) => (toggled ? "19px" : "16px")};
        height: ${({ toggled }) => (toggled ? "19px" : "16px")};
        border-radius: 50%;
        background-color: #fff;
        position: absolute;
        top: ${({ toggled }) => (toggled ? "1px" : "3px")};
        left: ${({ toggled }) => (toggled ? "calc(100% - 22px)" : "2px")};
        transition: left 0.3s ease-in-out;
    }
`;

const BottonLeft = styled.div`
    width: 65%;
`;
const BottomRight = styled.div`
    width: 30%;
`;

const VideoSection = styled.div`
    margin-bottom: 40px;
    img {
        display: block;
        width: 100%;
    }
`;
const TextSection = styled.h5`
    color: #fff;
    font-family: "gordita_medium";
    font-size: 14px;
`;
const AssetsCard = styled.div`
    background-color: #1b1c1f;
    border: 1px solid #2c2c2c;
    border-radius: 6px;
    padding: 22px;
    margin-bottom: 10px;
    width: 100%;
`;
const Label = styled.p`
    color: #fff;
    font-family: "gordita_medium";
    font-size: 16px;
    margin-bottom: 10px;
`;
const SkillCard = styled.div`
    background-color: #1b1c1f;
    border: 1px solid #2c2c2c;
    border-radius: 6px;
    padding: 22px;
    margin-bottom: 20px;
    width: 100%;
`;

const TextDiv = styled.div`
    display: flex;
    align-items: center;
    width: 50%;
`;

const Icon = styled.span`
    display: inline-block;
    width: 16%;

    img {
        display: block;
        width: 100%;
    }
`;
const IconText = styled.p`
    color: #4d4d4d;
    font-size: 16px;
    font-family: "gordita_medium";
    margin-left: 15px;
`;
const Size = styled.small`
    display: inline-block;
    width: 20%;
    color: #a4a4a4;
    font-size: 16px;
    font-family: "gordita_medium";
`;
const Button = styled.button`
    display: inline-block;
    width: 25%;
    color: #fe673a;
    font-size: 15px;
    font-family: "gordita_medium";
    cursor: pointer;
`;

const Card = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
    &:last-child {
        margin-bottom: 0px;
    }
`;

const Skills = styled.ul`
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
`;
const SkillList = styled.li`
    color: #027a48;
    font-size: 14px;
    font-family: "gordita_medium";
    border: 1px solid #027a48;
    border-radius: 8px;
    padding: 8px 12px;
    width: fit-content;
    text-transform: uppercase;
`;
