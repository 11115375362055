import React, { useContext, useEffect, useRef } from "react";
import styled from "styled-components";
import { Context } from "../../../contexts/Store";
import Moment from "react-moment";
import $ from "jquery";
import { Link, useNavigate } from "react-router-dom";

function TechUpdateNotification({
    isFilter,
    notificationData,
    readAll,
    // setDataPk,
    markAsRead,
    // dataPk,
    articlePK,
    setArticlePK,
    loader,
}) {
    const { state } = useContext(Context);
    const role = state.user_data.role[0];
    const {
        state: {
            notification_modal_data: { isModal, notificationButtonRef },
        },
        dispatch,
    } = useContext(Context);

    const wrapperRef = useRef(null);
    const navigate = useNavigate();

    useEffect(() => {
        if (isModal) {
            $("html").addClass("modal-enabled");
        } else {
            $("html").removeClass("modal-enabled");
        }
    }, [isModal]);

    function useOutsideClick(ref) {
        useEffect(() => {
            if (isModal) {
                function handleClickOutside(event) {
                    if (
                        ref.current &&
                        !ref.current.contains(event.target) &&
                        !notificationButtonRef.contains(event.target)
                    ) {
                        dispatch({
                            type: "UPDATE_TECH_NOTIFICATION",
                            notification_modal_data: {
                                isModal: !isModal,
                            },
                        });
                    }
                }
                document.addEventListener("mousedown", handleClickOutside);
                return () => {
                    document.removeEventListener(
                        "mousedown",
                        handleClickOutside
                    );
                };
            }
        }, [ref, isModal]);
    }
    useOutsideClick(wrapperRef);

    useEffect(() => {}, [articlePK]);

    return (
        <TopFilter>
            <CoverSet ref={wrapperRef}>
                <Popup className={isFilter ? "dropdown" : ""}>
                    <Head>
                        <HeadText>Notification</HeadText>
                        <MarkAllButton
                            onClick={() => {
                                readAll();
                            }}
                        >
                            {loader ? "Loading..." : "Mark all as read"}
                        </MarkAllButton>
                    </Head>
                    <ContentContainer>
                        {notificationData.map((data, index) => (
                            <Container
                                key={index}
                                onClick={() => {
                                    {
                                        !data.is_read && markAsRead(data.id);
                                    }
                                }}
                            >
                                <SubContainer>
                                    <Dot
                                        show={data.is_read ? false : true}
                                    ></Dot>
                                    <ProfileIcon>
                                        <ImgSpan
                                            style={{
                                                backgroundImage: `url(${data.sender_data.image})`,
                                            }}
                                        ></ImgSpan>
                                    </ProfileIcon>
                                    <TextContainer>
                                        <Name>{data.sender_data.name}</Name>
                                        <Message>{data.message}</Message>
                                        <Time>
                                            <Moment fromNow>
                                                {data.date_added}
                                            </Moment>
                                        </Time>
                                    </TextContainer>
                                </SubContainer>
                                {data?.blog_status === "re_work" ? (
                                    role === "content_editor" && (
                                        <Button
                                            to={`/content-editor/update/${data?.article_data.id}`}
                                            key={index}
                                            onClick={() => {
                                                setArticlePK(
                                                    data?.article_data.id
                                                );
                                            }}
                                        >
                                            View details
                                        </Button>
                                    )
                                ) : (
                                    <ThumbnailImageSpan key={index}>
                                        {data?.article_data.featured_image ? (
                                            <ThumbnailImage
                                                src={
                                                    data?.article_data
                                                        .featured_image
                                                }
                                                alt="thumbnail"
                                            />
                                        ) : (
                                            <ThumbnailImage
                                                src={require("../../../../assets/images/no-thumbnail.jpg")}
                                                alt="thumbnail"
                                            />
                                        )}
                                    </ThumbnailImageSpan>
                                )}
                            </Container>
                        ))}
                    </ContentContainer>
                </Popup>
            </CoverSet>
        </TopFilter>
    );
}

export default TechUpdateNotification;

const TopFilter = styled.div`
    display: flex;
    justify-content: flex-end;
    position: relative;
`;
const CoverSet = styled.div``;
const Popup = styled.div`
    min-height: 100vh;
    font-size: 14px;
    color: #fff;
    position: fixed;
    overflow: hidden;
    transition: 0.5s ease;
    z-index: 200;
    border: 1px solid #28292d;
    width: 507px;
    top: 59px;
    right: -900px;
    @media all and (max-width: 480px) {
        width: 345px;
    }
    @media all and (max-width: 360px) {
        width: 310px;
    }
    &.dropdown {
        overflow: visible;
        background: #161619;
        border-radius: 6px;
        border: 1px solid #2c2c2c;
        /* max-height: auto; */
        transition: 0.5s ease;
        /* min-height: 100%; */
        right: 0;
        top: 59px;
        /* overflow-y: scroll;
        height: 100%; */
    }

    &::-webkit-scrollbar {
        display: none;
    }
`;

const Head = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    border-bottom: 1px solid #3b3b3b;
    margin-bottom: 18px;
`;

const HeadText = styled.div`
    font-size: 18px;
    font-family: "gordita_medium";
`;
const MarkAllButton = styled.h5`
    font-size: 12px;
    font-family: "gordita_regular";
    color: #5188e5;
    cursor: pointer;
`;

const Container = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 10px;
    cursor: pointer;
    &:last-child {
        margin-bottom: 70px;
    }
`;
const SubContainer = styled.div`
    display: flex;
    align-items: center;
    width: 75%;
`;
const Dot = styled.div`
    margin-right: 15px;
    min-width: 10px;
    height: 10px;
    border-radius: 50%;
    background: ${({ show }) => (show ? "#fe673a" : "transparent")};
`;
const ProfileIcon = styled.div`
    margin-right: 15px;
`;
const ImgSpan = styled.span`
    display: inline-block;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-position: center;
    background-size: cover;
`;
const TextContainer = styled.div``;

const Name = styled.h4`
    font-size: 15px;
    font-family: "gordita_medium";
    color: #fff;
`;

const Message = styled.p`
    font-size: 13px;
    line-height: normal;
    font-family: "gordita_regular";
    color: #e6e2e2;
    /* width: 90%; */
`;

const Time = styled.small`
    font-size: 12px;
    font-family: "gordita_regular";
    color: #707070;
`;

const Button = styled(Link)`
    border-radius: 8px;
    border: 1px solid #2c2c2c;
    display: flex;
    padding: 8px 8px;
    align-items: center;
    justify-content: center;
    height: 30px;
    width: 19%;
    color: #fe673a;
    font-size: 12px;
    font-family: "gordita_regular";
    cursor: pointer;
`;

const ThumbnailImageSpan = styled.span`
    display: inline-block;
    width: 18%;
`;

const ThumbnailImage = styled.img`
    display: block;
    width: 100%;
`;
const ContentContainer = styled.div`
    overflow-y: scroll;
    max-height: 90vh;
    &::-webkit-scrollbar {
        display: none;
    }
`;
