import React, { useEffect } from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";

function ChiefSidebar({
    setSubMenu,
    isSyllabusMenu,
    setSyllabusMenu,
    isSelected,
    setSelected,
    isExpand,
    isStaff,
    isProjects,
    setExpand,
    wrapperRef,
    setSubCommunity,
    setSubJobDeskMenu,
    isRequest,
    setSubRequestMenu,
    setStaff,
    setProjects,
}) {
    const current_path = window.location.pathname;
    // const params = new URLSearchParams(queryString);
    // const listValue = params.get("list");
    useEffect(() => {
        if (
            current_path.includes("staffs") ||
            current_path.includes("engineers")
        ) {
            setStaff(true);
        }
    }, [isRequest]);
    return (
        <Container
            id="menu"
            ref={wrapperRef}
            className={isExpand && "expand"}
            onMouseEnter={() => setExpand(true)}
            onMouseLeave={() => setExpand(false)}
        >
            <Button
                to="/dashboard/"
                onClick={() => {
                    setSubMenu(false);
                    setSyllabusMenu(false);
                    setSubCommunity(false);
                    setSubJobDeskMenu(false);
                    setSubRequestMenu(false);
                    setProjects(false);
                    setStaff(false);
                }}
            >
                <Menu className="menu-item">
                    <MenuItemIcon
                        src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/yiaai/01-02-2022/images/tefun/dashboard.svg"
                        alt="Icon"
                    />
                    <MenuItemIcon1
                        src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/yiaai/01-02-2022/images/tefun/dashboard-orange.svg
                                "
                        alt="Icon"
                    />
                    <Title>Dashboard</Title>
                </Menu>
            </Button>
            <Button
                to="/students"
                className="sub-menu"
                onClick={() => {
                    setSubMenu(false);
                    setSyllabusMenu(false);
                    setSubCommunity(false);
                    setSubJobDeskMenu(false);
                    setSubRequestMenu(false);
                    setStaff(false);
                    setProjects(false);
                }}
            >
                <Menu className="menu-item">
                    <MenuItemIcon
                        src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/yiaai/01-02-2022/images/tefun/tefunicon.svg"
                        alt="Icon"
                    />
                    <MenuItemIcon1
                        src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/yiaai/01-02-2022/images/tefun/tefun.svg"
                        alt="Icon"
                    />
                    <Title>Students</Title>
                </Menu>
            </Button>
            <Button
                to="/renewal/"
                className="sub-menu"
                onClick={() => {
                    setSubMenu(false);
                    setSyllabusMenu(false);
                    setSubCommunity(false);
                    setSubJobDeskMenu(false);
                    setSubRequestMenu(false);
                    setStaff(false);
                    setProjects(false);
                }}
            >
                <Menu className="menu-item">
                    <MenuItemIcon
                        src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/24-05-2022/renewal-notactive.svg"
                        alt="Icon"
                    />
                    <MenuItemIcon1
                        src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/24-05-2022/renewal-active.svg"
                        alt="Icon"
                    />
                    <Title>Renewals</Title>
                </Menu>
            </Button>
            <Button
                to="/admission/"
                className="sub-menu"
                onClick={() => {
                    setSubMenu(false);
                    setSyllabusMenu(false);
                    setSubCommunity(false);
                    setSubJobDeskMenu(false);
                    setSubRequestMenu(false);
                    setStaff(false);
                    setProjects(false);
                }}
            >
                <Menu className="menu-item">
                    <MenuItemIcon
                        src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/24-05-2022/admission-not-active.svg"
                        alt="Icon"
                    />
                    <MenuItemIcon1
                        src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/24-05-2022/admissions-active.svg"
                        alt="Icon"
                    />
                    <Title>Admissions</Title>
                </Menu>
            </Button>
            <Button
                to="/prime-programs/"
                className="selected"
                onClick={() => {
                    setSubMenu(false);
                    setSyllabusMenu(false);
                    setSubCommunity(false);
                    setSubJobDeskMenu(false);
                    setSubRequestMenu(false);
                    setStaff(false);
                    setProjects(false);
                }}
            >
                <Menu className="menu-item">
                    <MenuItemIcon
                        src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/16-03-2022/prime-notactive.svg"
                        alt="Icon"
                    />
                    <MenuItemIcon1
                        src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/16-03-2022/prime-active.svg"
                        alt="Icon"
                    />
                    <Title>Prime Programs</Title>
                </Menu>
            </Button>

            {/* <Button
                to={"/projects/"}
                className={
                    window.location.pathname.includes("projects") &&
                    "selected active"
                }
                activeClassName="active"
                onClick={() => {
                    setSubMenu(false);
                    setSyllabusMenu(false);
                    setSubCommunity(false);
                    setSubJobDeskMenu(false);
                    setProjects(!isProjects);

                    setStaff(false);
                    setProjects(false);
                    <ArrowImageContainer
                        src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/24-05-2022/arrow.svg"
                        alt="Icon"
                        style={{
                            transition: "all 0.4s ease",
                        }}
                    />;
                }}
            >
                <Menu
                    className="menu-item"
                    style={{
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                width: "60%",
                            }}
                        >
                            <MenuItemIcon
                                src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/26-07-2023/verification.svg"
                                alt="Icon"
                            />
                            <MenuItemIcon1
                                src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/26-07-2023/verification-active.svg"
                                alt="Icon"
                            />
                            <Title>Verifications</Title>
                        </div>
                        <div
                            style={{
                                display: isExpand ? "block" : "none",
                                transition: "all .1s ease",
                            }}
                        >
                            <ArrowImageContainer
                                src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/24-05-2022/arrow.svg"
                                alt="Icon"
                                style={{
                                    transition: "all 0.4s ease",
                                }}
                            />
                            <ArrowImageContainer1
                                src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/24-05-2022/arrow-active.svg"
                                alt="Icon"
                            />
                        </div>
                    </div>
                </Menu>
            </Button>

            {isProjects && (
                <>
                    <Sub>
                        <SubButton
                            to="/projects/profile-pictures/"
                            // className={
                            //     window.location.pathname.includes(
                            //         "projects"
                            //     ) && "selected active"
                            // }
                            className={
                                window.location.pathname.includes(
                                    "projects"
                                ) || "selected active"
                            }
                            activeClassName="active"
                            onClick={() => {
                                setSelected(true);
                            }}
                        >
                            <SubMenu className="menu-item">
                                <MenuItemIcon
                                    src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/26-07-2023/profile-icon.svg"
                                    alt="Icon"
                                />
                                <MenuItemIcon1
                                    src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/26-07-2023/profile-icon-active.svg"
                                    alt="Icon"
                                />
                                <SubTitle>Profile Pictures</SubTitle>
                            </SubMenu>
                        </SubButton>
                    </Sub>
                    <Sub>
                        <SubButton
                            to="/projects/campus"
                            // className={
                            //     window.location.pathname.includes(
                            //         "projects"
                            //     ) && "selected active"
                            // }
                            onClick={() => {
                                setSelected(true);
                            }}
                        >
                            <SubMenu className="menu-item">
                                <MenuItemIcon
                                    src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/26-07-2023/campus.svg"
                                    alt="Icon"
                                />
                                <MenuItemIcon1
                                    src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/26-07-2023/campus-active.svg"
                                    alt="Icon"
                                />
                                <SubTitle>Campus</SubTitle>
                            </SubMenu>
                        </SubButton>
                    </Sub>
                    <Sub>
                        <SubButton
                            to="/projects/campus-list"
                            // className={
                            //     window.location.pathname.includes(
                            //         "projects"
                            //     ) && "selected active"
                            // }
                            onClick={() => {
                                setSelected(true);
                            }}
                        >
                            <SubMenu className="menu-item">
                                <MenuItemIcon
                                    src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/26-07-2023/campus.svg"
                                    alt="Icon"
                                />
                                <MenuItemIcon1
                                    src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/26-07-2023/campus-active.svg"
                                    alt="Icon"
                                />
                                <SubTitle>Campus List</SubTitle>
                            </SubMenu>
                        </SubButton>
                    </Sub>
                </>
            )} */}

            {/* Project Section */}
            <Button
                to="/projects/current/"
                className={
                    window.location.pathname.includes("projects") &&
                    "selected active"
                }
                activeClassName="active"
                onClick={() => {
                    setSubMenu(false);
                    setProjects(!isProjects);
                    setSubCommunity(false);
                    setSubJobDeskMenu(false);
                    setSubRequestMenu(false);
                    setStaff(false);
                    setSyllabusMenu(false);
                }}
            >
                <Menu
                    className="menu-item"
                    style={{
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                width: "60%",
                            }}
                        >
                            <MenuItemIcon
                                src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/17-01-2024/folders.svg"
                                alt="Icon"
                            />
                            <MenuItemIcon1
                                src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/17-01-2024/folders.svg"
                                alt="Icon"
                            />
                            <Title>Projects</Title>
                        </div>
                        <div
                            style={{
                                display: isExpand ? "block" : "none",
                                transition: "all .1s ease",
                            }}
                        >
                            <ArrowImageContainer
                                src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/24-05-2022/arrow.svg"
                                alt="Icon"
                                style={{
                                    transition: "all 0.4s ease",
                                }}
                            />
                            <ArrowImageContainer1
                                src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/24-05-2022/arrow-active.svg"
                                alt="Icon"
                            />
                        </div>
                    </div>
                </Menu>
            </Button>

            {isProjects && (
                <Sub>
                    <SubButton
                        to="/projects/current"
                        activeClassName=""
                        className={`"selected"  ${
                            window.location.pathname ===
                                "/projects/common-rules" ||
                            (window.location.pathname ===
                                "/projects/add-project" &&
                                "active")
                        }`}
                        onClick={() => {
                            // setProjects(true);
                        }}
                    >
                        <SubMenu className="menu-item">
                            <MenuItemIcon
                                src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/24-05-2022/events.svg"
                                alt="Icon"
                            />{" "}
                            <MenuItemIcon1
                                src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/24-05-2022/events-active.svg"
                                alt="Icon"
                            />
                            <SubTitle>Current projects</SubTitle>
                        </SubMenu>
                    </SubButton>
                    <SubButton to="/projects/evaluation" className="selected">
                        <SubMenu className="menu-item">
                            <MenuItemIcon
                                src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/24-05-2022/enquires.svg"
                                alt="Icon"
                            />
                            <MenuItemIcon1
                                src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/24-05-2022/enquires-active.svg"
                                alt="Icon"
                            />
                            <SubTitle>Evaluations</SubTitle>
                        </SubMenu>
                    </SubButton>
                </Sub>
            )}

            {/* Project Section End */}

            <Button
                to="/challenges/"
                className="selected"
                onClick={() => {
                    setSubMenu(false);
                    setSyllabusMenu(false);
                    setSubCommunity(false);
                    setSubJobDeskMenu(false);
                    setSubRequestMenu(false);
                    setStaff(false);
                    setProjects(false);
                }}
            >
                <Menu className="menu-item">
                    <MenuItemIcon
                        src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/16-03-2022/challenges-notactive.svg"
                        alt="Icon"
                    />
                    <MenuItemIcon1
                        src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/16-03-2022/challenges-active.svg"
                        alt="Icon"
                    />
                    <Title>Challenges</Title>
                </Menu>
            </Button>
            <Button
                to="/followup/status/"
                className="sub-menu"
                onClick={() => {
                    setSubMenu(false);
                    setSyllabusMenu(false);
                    setSubCommunity(false);
                    setSubJobDeskMenu(false);
                    setSubRequestMenu(false);
                    setProjects(false);
                }}
            >
                <Menu className="menu-item">
                    <MenuItemIcon
                        src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/24-05-2022/renewal-notactive.svg"
                        alt="Icon"
                    />
                    <MenuItemIcon1
                        src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/24-05-2022/renewal-active.svg"
                        alt="Icon"
                    />
                    <Title>Follow Up</Title>
                </Menu>
            </Button>
            {/* Request profile pic */}
            <Button
                to={"/verifications/profile-pictures/"}
                className={
                    window.location.pathname.includes("verifications") &&
                    "selected active"
                }
                activeClassName="active"
                onClick={() => {
                    setSubMenu(false);
                    setSyllabusMenu(false);
                    setSubCommunity(false);
                    setSubJobDeskMenu(false);
                    setSubRequestMenu(!isRequest);
                    setStaff(false);
                    setProjects(false);
                    <ArrowImageContainer
                        src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/24-05-2022/arrow.svg"
                        alt="Icon"
                        style={{
                            transition: "all 0.4s ease",
                        }}
                    />;
                }}
            >
                <Menu
                    className="menu-item"
                    style={{
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                width: "60%",
                            }}
                        >
                            <MenuItemIcon
                                src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/26-07-2023/verification.svg"
                                alt="Icon"
                            />
                            <MenuItemIcon1
                                src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/26-07-2023/verification-active.svg"
                                alt="Icon"
                            />
                            <Title>Verifications</Title>
                        </div>
                        <div
                            style={{
                                display: isExpand ? "block" : "none",
                                transition: "all .1s ease",
                            }}
                        >
                            <ArrowImageContainer
                                src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/24-05-2022/arrow.svg"
                                alt="Icon"
                                style={{
                                    transition: "all 0.4s ease",
                                }}
                            />
                            <ArrowImageContainer1
                                src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/24-05-2022/arrow-active.svg"
                                alt="Icon"
                            />
                        </div>
                    </div>
                </Menu>
            </Button>

            {isRequest && (
                <>
                    <Sub>
                        <SubButton
                            to="/verifications/profile-pictures/"
                            // className={
                            //     window.location.pathname.includes(
                            //         "verifications"
                            //     ) && "selected active"
                            // }
                            className={
                                window.location.pathname.includes(
                                    "verifications"
                                ) || "selected active"
                            }
                            activeClassName="active"
                            onClick={() => {
                                setSelected(true);
                            }}
                        >
                            <SubMenu className="menu-item">
                                <MenuItemIcon
                                    src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/26-07-2023/profile-icon.svg"
                                    alt="Icon"
                                />
                                <MenuItemIcon1
                                    src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/26-07-2023/profile-icon-active.svg"
                                    alt="Icon"
                                />
                                <SubTitle>Profile Pictures</SubTitle>
                            </SubMenu>
                        </SubButton>
                    </Sub>
                    <Sub>
                        <SubButton
                            to="/verifications/campus"
                            // className={
                            //     window.location.pathname.includes(
                            //         "verifications"
                            //     ) && "selected active"
                            // }
                            onClick={() => {
                                setSelected(true);
                            }}
                        >
                            <SubMenu className="menu-item">
                                <MenuItemIcon
                                    src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/26-07-2023/campus.svg"
                                    alt="Icon"
                                />
                                <MenuItemIcon1
                                    src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/26-07-2023/campus-active.svg"
                                    alt="Icon"
                                />
                                <SubTitle>Campus</SubTitle>
                            </SubMenu>
                        </SubButton>
                    </Sub>
                    <Sub>
                        <SubButton
                            to="/verifications/campus-list"
                            // className={
                            //     window.location.pathname.includes(
                            //         "verifications"
                            //     ) && "selected active"
                            // }
                            onClick={() => {
                                setSelected(true);
                            }}
                        >
                            <SubMenu className="menu-item">
                                <MenuItemIcon
                                    src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/26-07-2023/campus.svg"
                                    alt="Icon"
                                />
                                <MenuItemIcon1
                                    src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/26-07-2023/campus-active.svg"
                                    alt="Icon"
                                />
                                <SubTitle>Campus List</SubTitle>
                            </SubMenu>
                        </SubButton>
                    </Sub>
                </>
            )}
            {/* End Request profile pic */}
            <Button
                to={"/syllabus/"}
                className={isSelected ? "selected" : "selected"}
                onClick={() => {
                    setSubMenu(false);
                    setSyllabusMenu(!isSyllabusMenu);
                    setSubCommunity(false);
                    setSubJobDeskMenu(false);
                    setSubRequestMenu(false);
                    setStaff(false);
                    setProjects(false);
                }}
            >
                <Menu
                    className="menu-item"
                    style={{
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                width: "60%",
                            }}
                        >
                            <MenuItemIcon
                                src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/24-05-2022/sat.svg"
                                alt="Icon"
                            />
                            <MenuItemIcon1
                                src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/24-05-2022/sat-active.svg"
                                alt="Icon"
                            />
                            <Title>Syllabus</Title>
                        </div>
                        <div
                            style={{
                                display: isExpand ? "block" : "none",
                                transition: "all .1s ease",
                            }}
                        >
                            <ArrowImageContainer
                                src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/24-05-2022/arrow.svg"
                                alt="Icon"
                                style={{
                                    transition: "all 0.4s ease",
                                }}
                            />
                            <ArrowImageContainer1
                                src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/24-05-2022/arrow-active.svg"
                                alt="Icon"
                            />
                        </div>
                    </div>
                </Menu>
            </Button>
            {isSyllabusMenu && (
                <Sub>
                    <SubButton
                        to="/syllabus/"
                        className="selected"
                        onClick={() => {
                            setSelected(true);
                        }}
                    >
                        <SubMenu className="menu-item">
                            <MenuItemIcon
                                src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/24-05-2022/events.svg"
                                alt="Icon"
                            />{" "}
                            <MenuItemIcon1
                                src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/24-05-2022/events-active.svg"
                                alt="Icon"
                            />
                            <SubTitle>Normal</SubTitle>
                        </SubMenu>
                    </SubButton>
                    <SubButton to="/syllabus/day-by-day/" className="selected">
                        <SubMenu className="menu-item">
                            <MenuItemIcon
                                src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/24-05-2022/enquires.svg"
                                alt="Icon"
                            />
                            <MenuItemIcon1
                                src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/24-05-2022/enquires-active.svg"
                                alt="Icon"
                            />
                            <SubTitle>Day-by-day</SubTitle>
                        </SubMenu>
                    </SubButton>
                </Sub>
            )}

            {/* Staff Section */}

            <Button
                to={"/employees/staffs/"}
                className={
                    window.location.pathname.includes("employees") && "active"
                }
                // activeClassName="active"
                onClick={() => {
                    setSubMenu(false);
                    setStaff(!isStaff);
                    setSubCommunity(false);
                    setSubJobDeskMenu(false);
                    setSubRequestMenu(false);
                    setSyllabusMenu(false);
                    setProjects(false);
                    <ArrowImageContainer
                        src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/24-05-2022/arrow.svg"
                        alt="Icon"
                        style={{
                            transition: "all 0.4s ease",
                        }}
                    />;
                }}
            >
                <Menu
                    className="menu-item"
                    style={{
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                width: "60%",
                            }}
                        >
                            <MenuItemIcon
                                src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/04-10-2023/staff-engg-active.svg"
                                alt="Icon"
                            />
                            <MenuItemIcon1
                                src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/04-10-2023/staffengg-active.svg"
                                alt="Icon"
                            />
                            <Title>Employees</Title>
                        </div>
                        <div
                            style={{
                                display: isExpand ? "block" : "none",
                                transition: "all .1s ease",
                            }}
                        >
                            <ArrowImageContainer
                                src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/24-05-2022/arrow.svg"
                                alt="Icon"
                                style={{
                                    transition: "all 0.4s ease",
                                }}
                            />
                            <ArrowImageContainer1
                                src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/24-05-2022/arrow-active.svg"
                                alt="Icon"
                            />
                        </div>
                    </div>
                </Menu>
            </Button>
            {isStaff && (
                <>
                    <Sub>
                        <SubButton
                            to="/employees/staffs/"
                            className={
                                current_path.includes("staffs") &&
                                "selected active"
                            }
                            onClick={() => {
                                setSelected(true);
                            }}
                        >
                            <SubMenu className="menu-item">
                                <MenuItemIcon
                                    src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/04-10-2023/staff.svg"
                                    alt="Icon"
                                />
                                <MenuItemIcon1
                                    src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/04-10-2023/staff-active.svg"
                                    alt="Icon"
                                />
                                <SubTitle>Staffs</SubTitle>
                            </SubMenu>
                        </SubButton>
                    </Sub>
                    <Sub>
                        <SubButton
                            to="/employees/engineers/"
                            className={
                                current_path.includes("engineers") &&
                                "selected active"
                            }
                            onClick={() => {
                                setSelected(true);
                            }}
                        >
                            <SubMenu className="menu-item">
                                <MenuItemIcon
                                    src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/13-10-2023/engineers.svg"
                                    alt="Icon"
                                />
                                <MenuItemIcon1
                                    src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/13-10-2023/engineers-active.svg"
                                    alt="Icon"
                                />
                                <SubTitle>Engineers</SubTitle>
                            </SubMenu>
                        </SubButton>
                    </Sub>
                    <Sub>
                        <SubButton
                            to="/employees/deleted/"
                            className={
                                current_path.includes("deleted") &&
                                "selected active"
                            }
                            onClick={() => {
                                setSelected(true);
                            }}
                        >
                            <SubMenu className="menu-item">
                                <MenuItemIcon
                                    src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/13-10-2023/delete-staff.svg"
                                    alt="Icon"
                                />
                                <MenuItemIcon1
                                    src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/13-10-2023/delete-staff-active.svg"
                                    alt="Icon"
                                />
                                <SubTitle>Delete Staffs</SubTitle>
                            </SubMenu>
                        </SubButton>
                    </Sub>
                </>
            )}
            {/* staff Section End */}

            {/* <Button
                to="/chief-examiner/"
                className="selected"
                onClick={() => {
                    setSubMenu(false);
                    setSyllabusMenu(false);
                    setSubCommunity(false);
                    setSubJobDeskMenu(false);
                    setSubRequestMenu(false);
                }}
            >
                <Menu className="menu-item">
                    <MenuItemIcon
                        src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/24-05-2022/clock.svg"
                        alt="Icon"
                    />
                    <MenuItemIcon1
                        src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/24-05-2022/clock-active.svg"
                        alt="Icon"
                    />
                    <Title>Examiner</Title>
                </Menu>
            </Button> */}
            {/* <Button to="/support/">
                <Menu className="menu-item">
                    <MenuItemIcon src={Support} alt="Icon" />
                    <MenuItemIcon1 src={Support} alt="Icon" />
                    <Title>Support</Title>
                </Menu>
            </Button> */}
        </Container>
    );
}

export default ChiefSidebar;

const Sub = styled.div`
    display: flex;
    flex-direction: column;
    background: #161616;
`;

const Title = styled.span`
    font-family: "gordita_regular";
    color: inherit;
    font-size: 16px;
    color: #fff;
    font-weight: 500;
    white-space: nowrap;
    @media only screen and (max-width: 1550px) {
        display: none;
    }
    @media only screen and (max-width: 768px) {
        display: block;
    }
    &.selected {
        color: #f7684c;
    }
`;
const SubTitle = styled.p`
    font-family: "gordita_medium";
    font-size: 14px;
    white-space: nowrap;
    color: #fff;
    @media only screen and (max-width: 1550px) {
        display: none;
    }
    @media only screen and (max-width: 768px) {
        display: block;
    }
    &.selected {
        color: #1ec569;
    }
`;
const Menu = styled.div`
    padding: 10px 21px;
    display: flex;
    align-items: baseline;
    width: 100%;
    position: relative;
    transition: all 0.4s ease;
`;
const SubMenu = styled.div`
    padding: 3px;
    display: flex;
    align-items: baseline;
    width: 100%;
    position: relative;
`;
const Dot = styled.span`
    display: block;
    width: 12px;
    height: 12px;
    background-color: #fff;
    transform: translate(0px, -1px);
    margin-right: 12px;
    border-radius: 12px;
`;
const MenuItemIcon = styled.img`
    display: block;
    width: 16px;
    margin-right: 1.125rem;
    @media only screen and (max-width: 768px) {
        margin-right: 1.125rem;
    }
`;

const MenuItemIcon1 = styled.img`
    display: none;
    width: 16px;
    margin-right: 1.125rem;
    @media only screen and (max-width: 1550px) {
        margin-right: 0;
    }
    @media only screen and (max-width: 768px) {
        margin-right: 1.125rem;
    }
`;
const MenuItemIcon2 = styled.img`
    display: block;
    width: 16px;
    margin-right: 1.125rem;
    @media only screen and (max-width: 1550px) {
        margin-right: 1.125rem;
    }
    @media only screen and (max-width: 768px) {
        margin-right: 1.125rem;
    }
`;
const ArrowImageContainer = styled.img`
    display: block;
    width: 16px;
    transform: rotate(-90deg);
    transition: all 0.4s ease;
    @media only screen and (max-width: 768px) {
        margin-right: 1.125rem;
    }
`;

const ArrowImageContainer1 = styled.img`
    display: none;
    width: 16px;
    /* margin-right: 1.125rem; */
    transform: rotate(0deg);
    @media only screen and (max-width: 1550px) {
        margin-right: 0;
    }
    @media only screen and (max-width: 768px) {
        margin-right: 1.125rem;
    }
`;
const DownIcon = styled.img``;
const DownIcon1 = styled.img``;

const Button = styled(NavLink)`
    width: -webkit-fill-available;
    height: 46px;
    display: flex;
    color: #fff;
    transition: all 0.4s ease;
    &.active {
        border-left: 5px solid #fe673a;
        color: #1ec569 !important;
        background: #161616;
        ${MenuItemIcon} {
            display: none;
        }
        ${MenuItemIcon1} {
            display: block;
        }
        ${DownIcon} {
            display: none;
        }
        ${DownIcon1} {
            display: block;
        }

        ${Title} {
            color: #f7684c;
        }
        ${ArrowImageContainer} {
            display: none;
        }
        ${ArrowImageContainer1} {
            display: block;
        }
    }
`;

const SubButton = styled(NavLink)`
    display: flex;
    align-items: center;
    padding: 10px 20px 10px 35px;
    width: -webkit-fill-available;
    height: 46px;
    transition: all 0.4s ease;

    &.active {
        color: red;

        ${MenuItemIcon} {
            display: none;
        }
        ${MenuItemIcon1} {
            display: block;
        }
        ${DownIcon} {
            display: none;
        }
        ${DownIcon1} {
            display: block;
        }
        ${SubTitle} {
            color: #f7684c;
        }
        ${Dot} {
            background-color: #f7684c;
        }
        transition: all 0.4s ease;
    }
`;

const Container = styled.aside`
    &.expand {
        width: 218px;
        transition: all 0.4s ease;
        ${Title} {
            display: block;
        }
        ${SubTitle} {
            display: block;
        }
        ${Menu} {
        }
        ${MenuItemIcon} {
            margin-right: 1.125rem;
        }
        ${MenuItemIcon1} {
            margin-right: 1.125rem;
        }
        ${Button} {
        }
    }
`;
