import React, { useState, useEffect, Suspense, useContext } from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
//routes
import RegistrationManagerRoute from "../routes/roles/RegistrationManagerRoute";
import SubscriptionManagerRoute from "../routes/roles/SubscriptionManagerRoute";
import VerificationManagerRoute from "../routes/roles/VerificationManagerRoute";
import AdmissionManagerRoute from "../routes/roles/AdmissionManagerRoute";
import ContenetManagerRoute from "../routes/roles/ContenetManagerRoute";
import SchoolScientistRoute from "../routes/roles/SchoolScientistRoute";
import SalesExecutiveRoute from "../routes/roles/SalesExecutiveRoute";
import ContentEditorRoute from "../routes/roles/ContentEditorRoute";
import CareerAdvisorRoute from "../routes/roles/CareerAdvisorRoute";
import ExaminerHeadRoute from "../routes/roles/ExaminerHeadRoute";
import ChatScreenRoute from "../routes/roles/ChatScreenRoute";
import IntroducerRoute from "../routes/roles/IntroducerRoute";
import ExecutiveRoute from "../routes/roles/ExecutiveRoute";
import ExaminerRoute from "../routes/roles/ExaminerRoute";
import SatAgentRoute from "../routes/roles/SatAgentRoute";
import ManagerRoute from "../routes/roles/ManagerRoute";
import EmployRoute from "../routes/roles/EmployRoute";
import ChiefRoute from "../routes/roles/ChiefRoute";
import BdaRoute from "../routes/roles/BdaRoute";
import BdmRoute from "../routes/roles/BdmRoute";
import AepRoute from "../routes/roles/AepRoute";
import SeRoute from "./roles/SeRoute";

//routers
import RegistrationManagerRouter from "./roles/RegistrationManagerRouter";
import SubscriptionManagerRouter from "./roles/SubscriptionManagerRouter";
import SchoolScientistRouter from "./roles/SchoolScientistRouter";
import VerificationRouter from "./verifications/VerificationRouter";
import ContentManagerRouter from "./roles/ContentManagerRouter";
import SalesExecutiveRouter from "./roles/SalesExecutiveRouter";
import CareerAdvisorRouter from "./roles/CareerAdvisorRouter";
import ContentEditorRouter from "./roles/ContentEditorRouter";
import ExaminerHeadRouter from "./roles/ExaminerHeadRouter";
import IntroducerRouter from "./roles/IntroducerRouter";
import ExecutiveRouter from "./roles/ExecutiveRouter";
import StudentRouter from "./students/StudentRouter";
import ExaminerRouter from "./roles/ExaminerRouter";
import SatAgentRouter from "./roles/SatAgentRouter";
import ManagerRouter from "./roles/ManagerRouter";
import EmployRouter from "./roles/EmployRouter";
import ChiefRouter from "./roles/ChiefRouter";
import AepRouter from "./roles/AepRouter";
import BdaRouter from "./roles/BdaRouter";
import BdmRouter from "./roles/BdmRouter";

//components
import SectionLoader from "../../helpers/SectionLoader";
import ChatScreen from "../../screens/chat/ChatScreen";
import SideBar from "../../includes/sidebars/SideBar";
import { Context } from "../../contexts/Store";
import TopBar from "../../includes/TopBar";
import ProjectManagerRoute from "../routes/roles/ProjectManagerRoute";
import ProjectsRouter from "./roles/ProjectsRouter";

function AppRouter() {
    const { state } = useContext(Context);
    const role = state.user_data.role[0];

    const [isExpand, setExpand] = useState(false);
    const [isLoading, setLoading] = useState(true);
    const {
        state: { user_data },
    } = useContext(Context);
    const navigate = useNavigate();

    useEffect(() => {
        if (user_data.uid) {
            setLoading(false);
        }
    }, [user_data]);

    return (
        <div>
            {role !== "support_engineer" && <SideBar setExpand={setExpand} />}
            {/* <SideBar setExpand={setExpand} /> */}
            <TopBar isExpand={isExpand} setExpand={setExpand} />
            <Suspense fallback={<SectionLoader />}>
                <Routes>
                    <Route
                        path="/*"
                        element={
                            <ChiefRoute>
                                <ChiefRouter />
                            </ChiefRoute>
                        }
                    />
                    <Route
                        path="/executive/*"
                        element={
                            <ExecutiveRoute>
                                <ExecutiveRouter />
                            </ExecutiveRoute>
                        }
                    />
                    <Route
                        path="/manager/*"
                        element={
                            <ManagerRoute>
                                <ManagerRouter />
                            </ManagerRoute>
                        }
                    />
                    <Route
                        path="/registration-manager/*"
                        element={
                            <RegistrationManagerRoute>
                                <RegistrationManagerRouter />
                            </RegistrationManagerRoute>
                        }
                    />
                    <Route
                        path="/sat-examiner-head/*"
                        element={
                            <ExaminerHeadRoute>
                                <ExaminerHeadRouter />
                            </ExaminerHeadRoute>
                        }
                    />
                    <Route
                        path="/examiner/*"
                        element={
                            <ExaminerRoute>
                                <ExaminerRouter />
                            </ExaminerRoute>
                        }
                    />
                    <Route
                        path="/career-advisor/*"
                        element={
                            <CareerAdvisorRoute>
                                <CareerAdvisorRouter />
                            </CareerAdvisorRoute>
                        }
                    />
                    <Route
                        path="/sales-executive/*"
                        element={
                            <SalesExecutiveRoute>
                                <SalesExecutiveRouter />
                            </SalesExecutiveRoute>
                        }
                    />
                    <Route
                        path="/introducer/*"
                        element={
                            <IntroducerRoute>
                                <IntroducerRouter />
                            </IntroducerRoute>
                        }
                    />
                    <Route
                        path="/aep/*"
                        element={
                            <AepRoute>
                                <AepRouter />
                            </AepRoute>
                        }
                    />
                    <Route
                        path="/bdm/*"
                        element={
                            <BdmRoute>
                                <BdmRouter />
                            </BdmRoute>
                        }
                    />
                    {/* <Route
                        path="/support/"
                        element={
                            <ChatScreenRoute>
                                {isLoading ? <SectionLoader /> : <ChatScreen />}
                            </ChatScreenRoute>
                        }
                    /> */}
                    <Route
                        path="/support/*"
                        element={
                            <ChatScreenRoute>
                                {isLoading ? <SectionLoader /> : <SeRoute />}
                            </ChatScreenRoute>
                        }
                    />
                    <Route
                        path="/sat-agent/*"
                        element={
                            <SatAgentRoute>
                                <SatAgentRouter />
                            </SatAgentRoute>
                        }
                    />
                    <Route
                        path="/subscription-manager/*"
                        element={
                            <SubscriptionManagerRoute>
                                <SubscriptionManagerRouter />
                            </SubscriptionManagerRoute>
                        }
                    />
                    <Route
                        path="/bda-manager/*"
                        element={
                            <BdaRoute>
                                <BdaRouter />
                            </BdaRoute>
                        }
                    />
                    <Route
                        path="/school-scientist/*"
                        element={
                            <SchoolScientistRoute>
                                <SchoolScientistRouter />
                            </SchoolScientistRoute>
                        }
                    />
                    <Route
                        path="/admission-manager/*"
                        element={
                            <AdmissionManagerRoute>
                                <StudentRouter />
                            </AdmissionManagerRoute>
                        }
                    />
                    <Route
                        path="/content-manager/*"
                        element={
                            <ContenetManagerRoute>
                                <ContentManagerRouter />
                            </ContenetManagerRoute>
                        }
                    />
                    <Route
                        path="/content-editor/*"
                        element={
                            <ContentEditorRoute>
                                <ContentEditorRouter />
                            </ContentEditorRoute>
                        }
                    />
                    {role === "verification_manager" && (
                        <Route
                            path="/verifications/*"
                            element={
                                <VerificationManagerRoute>
                                    <VerificationRouter />
                                </VerificationManagerRoute>
                            }
                        />
                    )}
                    <Route
                        path="/manage/*"
                        element={
                            <EmployRoute>
                                <EmployRouter />
                            </EmployRoute>
                        }
                    />
                    <Route
                        path="/projects/*"
                        element={
                            <ProjectManagerRoute>
                                <ProjectsRouter />
                            </ProjectManagerRoute>
                        }
                    />
                </Routes>
            </Suspense>
        </div>
    );
}

export default AppRouter;
