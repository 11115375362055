import React, { lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import ContentManagerDashboard from "../../../screens/content-manager/ContentManagerDashboard";
import TechUpdateEditingScreen from "../../../screens/content-manager/TechUpdateEditingScreen";
import TechUpdateCategoryDashbord from "../../../screens/content-manager/TechUpdateCategoryDashbord";
import TechUpdateReworkScreen from "../../../screens/content-manager/TechUpdateRework";
import TechUpdateViewRework from "../../../screens/content-manager/TechUpdateViewRework";

function ContentManagerRouter() {
    return (
        <Routes>
            <Route path="dashboard/" element={<ContentManagerDashboard />} />
            <Route path="categorys/" element={<TechUpdateCategoryDashbord />} />
            <Route
                path="create/:article_pk/"
                element={<TechUpdateEditingScreen />}
            />
            <Route
                path="update/:article_pk/"
                element={<TechUpdateEditingScreen />}
            />
            <Route
                path="rework/:article_pk/"
                element={<TechUpdateReworkScreen />}
            />
            <Route
                path="view-rework/:history_pk/"
                element={<TechUpdateViewRework />}
            />
        </Routes>
    );
}

export default ContentManagerRouter;
