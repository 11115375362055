import React, { lazy } from "react";
import { Routes, Route } from "react-router-dom";
import DaybydayRouter from "./DaybydayRouter";
//components
const SyllabusTopics = lazy(() =>
    import("../../../screens/syllabus/SyllabusTopics")
);
const SyllabusSkills = lazy(() =>
    import("../../../screens/syllabus/SyllabusSkills")
);
const SyllabusCompletedStudents = lazy(() =>
    import("../../../screens/syllabus/SyllabusCompletedStudents")
);
const Syllabus = lazy(() => import("../../../screens/syllabus/Syllabus"));

function SyllabusRouter() {
    return (
        <Routes>
            <Route path="/" element={<Syllabus />} />
            <Route path=":prof_id/skills/" element={<SyllabusSkills />} />
            <Route path=":skill_id/topics/" element={<SyllabusTopics />} />
            <Route path="day-by-day/*" element={<DaybydayRouter />} />
            <Route
                path="profession/:prof_name/:prof_id/"
                element={<SyllabusCompletedStudents />}
            />
            <Route
                path="skill/:prof_name/:skill_id/"
                element={<SyllabusCompletedStudents />}
            />
            <Route
                path="lesson/:prof_name/:lesson_id/"
                element={<SyllabusCompletedStudents />}
            />
        </Routes>
    );
}

export default SyllabusRouter;
