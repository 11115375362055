import React, { useEffect } from "react";
import styled from "styled-components";
import moment from "moment/moment";
import png from "../../../../assets/images/project-icons/png.svg";
import jpeg from "../../../../assets/images/project-icons/jpeg.svg";
import jpg from "../../../../assets/images/project-icons/jpg.svg";
import img from "../../../../assets/images/project-icons/img.svg";
import webp from "../../../../assets/images/project-icons/webp.svg";
import mkv from "../../../../assets/images/project-icons/mkv.svg";
import avi from "../../../../assets/images/project-icons/avi.svg";
import mpeg from "../../../../assets/images/project-icons/mpeg.svg";
import mp4 from "../../../../assets/images/project-icons/mp4.svg";
import $ from "jquery";

function ProjectViewDetialsModal({
    modal,
    setModal,
    issues,
    EvaluationID,
    bugCreate,
}) {
    //Background Scroll----

    useEffect(() => {
        if (modal) {
            $("html").addClass("modal-enabled");
        } else {
            $("html").removeClass("modal-enabled");
        }
    }, [modal]);

    return (
        <MainContainer>
            {modal && <Overlay onClick={() => setModal(false)}></Overlay>}
            <ContainerDiv className={modal ? "active" : ""}>
                <ContainerHead>
                    <HeadLeft>
                        <HeadTextDiv>
                            <HeadText>
                                Bug ID: {issues?.issue_id ?? "--"}
                            </HeadText>
                            <HeadTime>
                                {issues?.date_added
                                    ? moment(issues.date_added).format("LT")
                                    : "--"}{" "}
                                ,{" "}
                                {issues?.date_added
                                    ? moment(issues.date_added).format("MMM DD")
                                    : "--"}
                            </HeadTime>
                        </HeadTextDiv>
                        <HeadButton>
                            <Button
                                onClick={() => {
                                    bugCreate(
                                        EvaluationID,
                                        "re-open",
                                        issues.id
                                    );
                                }}
                            >
                                Re-open
                            </Button>
                            <Button
                                className="fixed"
                                onClick={() => {
                                    bugCreate(EvaluationID, "fixed", issues.id);
                                }}
                            >
                                Fixed
                            </Button>
                        </HeadButton>
                    </HeadLeft>
                    <HeadRight>
                        <HeadIcon>
                            <img
                                src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/20-01-2024/x-close.svg "
                                alt="close-icon"
                                onClick={() => setModal(false)}
                            />
                        </HeadIcon>
                    </HeadRight>
                </ContainerHead>
                <MainSection>
                    <LeftSection>
                        <MainTextHead>QA report</MainTextHead>
                        <Label>Bug status</Label>
                        <StatusButton>
                            <Dote></Dote>
                            <StatusText>
                                {issues.status
                                    ? issues.status
                                          .replace(/_/g, " ")
                                          .charAt(0)
                                          .toUpperCase() +
                                      issues.status.replace(/_/g, " ").slice(1)
                                    : "--"}
                            </StatusText>
                        </StatusButton>
                        <Label>QA comment</Label>
                        <Comment>{issues.qa_note ?? "--"}</Comment>
                        <Label>Severity</Label>
                        <Severity>{issues?.severity}</Severity>
                        {issues?.chief_attachments?.length > 0 && (
                            <>
                                <Label>Attachments</Label>

                                <AttachmentsSection>
                                    {issues?.chief_attachments?.map(
                                        (item, index) => (
                                            <AttachmentCard index={index}>
                                                <Card>
                                                    <Icon>
                                                        <img
                                                            src={
                                                                item.file_extension ===
                                                                "JPG"
                                                                    ? jpg
                                                                    : item.file_extension ===
                                                                      "JPEG"
                                                                    ? jpeg
                                                                    : item.file_extension ===
                                                                      "PNG"
                                                                    ? png
                                                                    : item.file_extension ===
                                                                      "WEBP"
                                                                    ? webp
                                                                    : item.file_extension ===
                                                                      "AVI"
                                                                    ? avi
                                                                    : item.file_extension ===
                                                                      "MKV"
                                                                    ? mkv
                                                                    : item.file_extension ===
                                                                      "MPEG"
                                                                    ? mpeg
                                                                    : item.file_extension ===
                                                                      "MP4"
                                                                    ? mp4
                                                                    : item.file_extension ===
                                                                      "IMG"
                                                                    ? img
                                                                    : "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/05-02-2024/blue-icon.svg"
                                                            }
                                                            alt="file"
                                                        />
                                                    </Icon>
                                                    <UploadName>
                                                        <Name>
                                                            {item.file_name
                                                                ? item.file_name
                                                                      .length >
                                                                  20
                                                                    ? item.file_name.slice(
                                                                          0,
                                                                          17
                                                                      ) +
                                                                      "..." +
                                                                      item.file_name.slice(
                                                                          -4
                                                                      )
                                                                    : item.file_name
                                                                : item.file
                                                                      ?.name ||
                                                                  ""}
                                                        </Name>
                                                        <Size>
                                                            {" "}
                                                            {item.file_size}
                                                        </Size>
                                                    </UploadName>
                                                </Card>
                                                <ViewBotton
                                                    href={item.file}
                                                    target="_blank"
                                                >
                                                    View
                                                </ViewBotton>
                                            </AttachmentCard>
                                        )
                                    )}
                                </AttachmentsSection>
                            </>
                        )}
                    </LeftSection>
                    <RightSection>
                        <MainTextHead>Developer report</MainTextHead>
                        <>
                            <Label>Dev status</Label>
                            <StatusButton statusColor={issues.developer_status}>
                                <StatusText
                                    statusColor={issues.developer_status}
                                >
                                    {issues.developer_status
                                        ? issues.developer_status
                                              .replace(/_/g, " ")
                                              .charAt(0)
                                              .toUpperCase() +
                                          issues.developer_status
                                              .replace(/_/g, " ")
                                              .slice(1)
                                        : "--"}
                                </StatusText>
                            </StatusButton>
                        </>
                        <>
                            <Label>Dev comment</Label>
                            <Comment>
                                {issues.developer_comment ?? "--"}
                            </Comment>
                        </>
                        {issues?.developer_attachments?.length > 0 && (
                            <>
                                <Label>Attachments</Label>

                                <AttachmentsSection>
                                    {issues?.developer_attachments?.map(
                                        (item, index) => (
                                            <AttachmentCard index={index}>
                                                <Card>
                                                    <Icon>
                                                        <img
                                                            src={
                                                                item.file_extension ===
                                                                "JPG"
                                                                    ? jpg
                                                                    : item.file_extension ===
                                                                      "JPEG"
                                                                    ? jpeg
                                                                    : item.file_extension ===
                                                                      "PNG"
                                                                    ? png
                                                                    : item.file_extension ===
                                                                      "WEBP"
                                                                    ? webp
                                                                    : item.file_extension ===
                                                                      "AVI"
                                                                    ? avi
                                                                    : item.file_extension ===
                                                                      "MKV"
                                                                    ? mkv
                                                                    : item.file_extension ===
                                                                      "MPEG"
                                                                    ? mpeg
                                                                    : item.file_extension ===
                                                                      "MP4"
                                                                    ? mp4
                                                                    : item.file_extension ===
                                                                      "IMG"
                                                                    ? img
                                                                    : "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/05-02-2024/blue-icon.svg"
                                                            }
                                                            alt="file-icon"
                                                        />
                                                    </Icon>
                                                    <UploadName>
                                                        <Name>
                                                            {item.file_name
                                                                ? item.file_name
                                                                      .length >
                                                                  20
                                                                    ? item.file_name.slice(
                                                                          0,
                                                                          17
                                                                      ) +
                                                                      "..." +
                                                                      item.file_name.slice(
                                                                          -4
                                                                      )
                                                                    : item.file_name
                                                                : item.file
                                                                      ?.name ||
                                                                  ""}
                                                        </Name>
                                                        <Size>
                                                            {item.file_size}
                                                        </Size>
                                                    </UploadName>
                                                </Card>
                                                <ViewBotton
                                                    href={item.file}
                                                    target="_blank"
                                                >
                                                    View
                                                </ViewBotton>
                                            </AttachmentCard>
                                        )
                                    )}
                                </AttachmentsSection>
                            </>
                        )}
                    </RightSection>
                </MainSection>
            </ContainerDiv>
        </MainContainer>
    );
}

export default ProjectViewDetialsModal;
const MainContainer = styled.div`
    /* padding: 24px; */
`;
const Overlay = styled.div`
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(2px);
    position: fixed;
    z-index: 10;
    left: 0;
    top: 0px;
    width: 100%;
    z-index: 1000;
    min-height: 100vh;
    max-height: 100vh;
    filter: blur(1px);
`;
const ContainerDiv = styled.div`
    position: fixed;
    z-index: 1001;
    right: -70%;
    top: 0;
    min-height: 100vh;
    max-height: 100vh;
    width: 70%;
    transition: all ease 200ms;
    /* padding: 24px; */
    overflow-y: scroll;
    &::-webkit-scrollbar {
        display: none;
    }
    &.active {
        right: 0;
    }
    border-left: 1px solid #2c2c2c;
    background: #1b1c1f;
    box-shadow: 0px 8px 8px -4px rgba(16, 24, 40, 0.03),
        0px 20px 24px -4px rgba(16, 24, 40, 0.08);
    @media all and (max-width: 1280px) {
        width: 75%;
        right: -75%;
    }
    @media all and (max-width: 1080px) {
        width: 80%;
        right: -80%;
    }
    @media all and (max-width: 980px) {
        min-width: 555px;
        max-width: 555px;
        right: -555px;
    }
`;
const ContainerHead = styled.div`
    padding: 24px;
    background: #0f1011;
    display: flex;
    justify-content: space-between;
    height: 100px;
`;

const HeadLeft = styled.div`
    display: flex;
    gap: 80px;
`;
const HeadTextDiv = styled.div``;
const HeadText = styled.h4`
    color: #fff;
    font-size: 20px;
    font-family: "gordita_medium";
    margin-bottom: 4px;
`;
const HeadTime = styled.p`
    font-size: 14px;
    font-family: "gordita_regular";
`;

const HeadRight = styled.div``;
const HeadButton = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
`;
const Button = styled.button`
    border-radius: 6px;
    border: 1px solid #d0d5dd;
    background: #fff;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    display: flex;
    height: 40px;
    padding: 10px 16px;
    justify-content: center;
    align-items: center;
    color: #344054;
    cursor: pointer;
    &.fixed {
        border-radius: 6px;
        background: #fe673a;
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        color: #fff;
        border: none;
    }
`;

const HeadIcon = styled.span`
    display: inline-block;
    cursor: pointer;
    width: 20px;
    img {
        display: block;
        width: 100%;
    }
`;

const MainSection = styled.div`
    display: flex;
    justify-content: space-between;
    min-height: calc(100vh - 100px);
    @media all and (max-width: 980px) {
        flex-direction: column;
    }
    /* gap: 24px; */
    /* padding: 24px; */
`;
const LeftSection = styled.div`
    width: 50%;
    padding: 24px;
    color: #3d3d3d;
    @media all and (max-width: 980px) {
        width: 100%;
    }
`;
const RightSection = styled.div`
    width: 50%;
    border-left: 1px solid #2c2c2c;
    background: #2d2e33;
    box-shadow: 0px 8px 8px -4px rgba(16, 24, 40, 0.03),
        0px 20px 24px -4px rgba(16, 24, 40, 0.08);
    padding: 24px;
    @media all and (max-width: 980px) {
        width: 100%;
    }
`;

const MainTextHead = styled.h5`
    color: #cbd5e1;
    font-size: 16px;
    margin-bottom: 5px;
    text-align: center;
    padding: 0.75rem;
    border-bottom: 1px solid #64748b;
    margin-bottom: 24px;
`;

const Label = styled.small`
    display: inline-block;
    color: #94a3b8;
    font-size: 0.875rem;
    font-family: "gordita_medium";
    margin-bottom: 0.75rem;
`;

const StatusButton = styled.div`
    border-radius: 0.375rem;
    border: 1px solid
        ${({ statusColor }) =>
            statusColor === "not_fixed"
                ? "#F04438"
                : statusColor === "not_a_bug"
                ? "#3B82F6"
                : statusColor === "fixed"
                ? "#ABEFC6"
                : "#fff"};
    background: #1b1c1f;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    display: flex;
    /* height: 1.5rem; */
    padding: 8px 12px;
    align-items: center;
    width: fit-content;
    margin-bottom: 40px;
`;

const Dote = styled.span`
    display: inline-block;
    width: 0.5rem;
    height: 0.5rem;
    background: #2e90fa;
    margin-right: 0.5rem;
    border-radius: 50%;
`;
const StatusText = styled.h6`
    color: ${({ statusColor }) =>
        statusColor === "not_fixed"
            ? "#F04438"
            : statusColor === "not_a_bug"
            ? "#3B82F6"
            : statusColor === "fixed"
            ? "#ABEFC6"
            : "#fff"};
    font-family: "gordita_regular";
    font-size: 0.875rem;
    height: 18px;
`;

const Comment = styled.p`
    font-size: 1rem;
    color: #fff;
    font-family: "gordita_regular";
    width: 90%;
    margin-bottom: 2.5em;
`;

const Severity = styled.span`
    border-radius: 1rem;
    border: 1.5px solid #de3024;
    display: flex;
    height: 1.5rem;
    padding: 0.125rem 0.625rem;
    align-items: center;
    color: #f04438;
    font-size: 0.875rem;
    font-family: "gordita_medium";
    width: fit-content;
    margin-bottom: 2.5em;
`;

const AttachmentsSection = styled.div``;

const AttachmentCard = styled.div`
    border-radius: 0.75rem;
    border: 1px solid #3d3d3d;
    background: #161619;
    padding: 1rem 1.5rem;
    margin-bottom: 0.75rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &:last-child {
        margin-bottom: 0;
    }
`;

const Icon = styled.span`
    display: inline-block;
    width: 2.5rem;
    height: 2.5rem;
    img {
        width: 100%;
        display: block;
    }
`;
const UploadName = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 0.75rem;
`;
const Name = styled.span`
    display: inline-block;
    font-size: 0.875rem;
    color: #cbd5e1;
    font-family: "gordita_medium";
    margin-bottom: 0.25rem;
`;
const Size = styled.span`
    color: #94a3b8;
    font-family: "gordita_regular";
    font-size: 0.875rem;
    display: inline-block;
`;
const Card = styled.div`
    display: flex;
    align-items: center;
`;

const ViewBotton = styled.a`
    color: #fe673a;
    font-family: "gordita_medium";
    border: none;
    background: none;
    font-size: 0.875rem;
    cursor: pointer;
`;
