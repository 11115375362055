import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { learnConfig } from "../../../axiosConfig";
import { Context } from "../../contexts/Store";
import ButtonLoader from "../../genaral/Loader/ButtonLoader";
import { Link } from "react-router-dom";

function ProjectViewPageHead() {
    const [countData, setCountData] = useState(null);
    const [loader, setLoader] = useState(false);
    const {
        state: {
            user_data: { access_token },
        },
    } = useContext(Context);
    const fetchCount = () => {
        setLoader(true);
        learnConfig
            .get("/projects/counts/", {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            })
            .then((response) => {
                let { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setCountData(data);
                    setLoader(false);
                } else {
                    setCountData(null);
                    setLoader(false);
                }
            })
            .catch((err) => {
                console.log(err);
                setLoader(false);
            });
    };

    useEffect(() => {
        fetchCount();
    }, []);

    const convertDigits = (value) => {
        if (value > 1000) {
            return (value / 1000).toFixed(1) + "k";
        } else {
            return value;
        }
    };

    const cardData = [
        {
            title: "Total projects",
            count: countData?.projects_count
                ? convertDigits(countData?.projects_count)
                : "--",
            redirect: "/projects/current",
        },
        {
            title: "Total student enrolled",
            count: countData?.students_count
                ? convertDigits(countData?.students_count)
                : "--",
            redirect: "/projects/evaluation",
        },
        {
            title: "Pending evaluations",
            count: countData?.pending_evaluations_count
                ? convertDigits(countData?.pending_evaluations_count)
                : "--",
            redirect: "/projects/evaluation",
        },
    ];

    return (
        <Container>
            <Top>
                <HeadTitile>HTML Level 1</HeadTitile>
                <SubHead>Manage and create projects here.</SubHead>
            </Top>
            <Bottom>
                {cardData.map((item, index) => (
                    <CardSection key={index} to={item?.redirect}>
                        <CardHead>
                            <CardTitle>
                                {item.title ? item.title : "--"}
                            </CardTitle>
                            {/* <CardIcon>
                                <img
                                    src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/17-01-2024/dots-vertical.svg "
                                    alt="Icon"
                                />
                            </CardIcon> */}
                        </CardHead>
                        <CardBody>
                            {loader ? (
                                <CountLoader>
                                    <ButtonLoader width={44} height={44} />
                                </CountLoader>
                            ) : (
                                <Count>{item.count ? item.count : "--"}</Count>
                            )}
                        </CardBody>
                    </CardSection>
                ))}
            </Bottom>
        </Container>
    );
}

export default ProjectViewPageHead;

const Container = styled.div`
    margin-bottom: 24px;
`;
const Top = styled.div`
    margin-bottom: 24px;
`;
const Bottom = styled.div`
    display: flex;
    justify-content: space-between;
`;
const HeadTitile = styled.h1`
    color: rgb(255, 255, 255);
    margin-bottom: 15px;
    font-family: gordita_medium;
    font-size: 22px;
`;
const SubHead = styled.h3`
    font-size: 16px;
    color: #fff;
    font-family: "gordita_regular";
`;
const CardSection = styled(Link)`
    border-radius: 12px;
    border: 1px solid #fe673a;
    background: #1b1c1f;
    width: 32%;
    padding: 24px;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    cursor: pointer;
`;
const CardHead = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
`;
const CardBody = styled.div``;

const CardIcon = styled.div`
    width: 20px;
    img {
        display: block;
        width: 100%;
    }
    cursor: pointer;
`;
const CardTitle = styled.h4`
    color: #fff;
    font-size: 16px;
    font-family: "gordita_medium";
`;
const Count = styled.h5`
    color: #fff;
    font-size: 36px;
    font-family: "gordita_medium";
`;
const CountLoader = styled.span`
    display: inline-block;
    width: 52px;
    height: 52px;
`;
