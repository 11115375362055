import React, { useState, useEffect } from "react";
import toast, { Toaster } from "react-hot-toast";
import { requestForToken, onMessageListener } from "./fiebase";
import useSound from "use-sound";
import boopSfx from "./assets/sounds/notification.mp3";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useContext } from "react";
import { Context } from "./components/contexts/Store";

const Notification = () => {
    const [notification, setNotification] = useState({
        title: "",
        body: "",
        send_to: "",
        img: "",
    });
    const [play] = useSound(boopSfx);
    const [newNotify, setNewNotify] = useState(false);
    const { dispatch } = useContext(Context);

    const notify = () => toast(<ToastDisplay />);
    function ToastDisplay() {
        const closeToast = () => {
            toast.dismiss();
        };

        return notification.send_to === "tech_updates" ? (
            <MainContainer>
                <Container>
                    <CloseButton onClick={closeToast}>
                        <Close>
                            <CloseImg
                                src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/05-04-2022/close.png"
                                alt="Close Icon"
                            />
                        </Close>
                    </CloseButton>
                    <ImgContainer
                        style={{ backgroundImage: `url(${notification?.img})` }}
                    ></ImgContainer>
                    <ContentContainer>
                        <Text>
                            <Small>{notification?.title}</Small>
                        </Text>
                        <Text>{notification?.message}</Text>
                    </ContentContainer>
                    <ButtonContainer>
                        <Button
                            to={`/content-editor/update/${notification?.article_id}`}
                        >
                            View details
                        </Button>
                    </ButtonContainer>
                </Container>
            </MainContainer>
        ) : (
            <div>
                <p>
                    <b>{notification?.title}</b>
                </p>
                <p>{notification?.body}</p>
            </div>
        );
    }

    useEffect(() => {
        if (notification?.title) {
            //   play();
            notify();
            localStorage.setItem("notify", newNotify);
            dispatch({
                type: "UPDATE_TECH_NOTIFICATION",
                notification_modal_data: {
                    new_notification: newNotify,
                },
            });
        }
    }, [notification]);

    useEffect(() => {
        requestForToken();
    }, []);

    onMessageListener()
        .then((payload) => {
            setNewNotify(!newNotify);
            if (payload?.notification) {
                setNotification({
                    title: payload?.notification?.title,
                    body: payload?.notification?.body,
                });
            } else {
                setNotification({
                    title: payload?.data?.title,
                    message: payload?.data?.message,
                    body: payload?.data?.body,
                    send_to: payload?.data?.notification_for,
                    img: payload?.data?.photo_url,
                    article_id: payload?.data?.article_id,
                });
            }
        })
        .catch((err) => console.log("failed: ", err));

    return (
        <Toaster
            position={
                notification.send_to === "tech_updates"
                    ? "top-right"
                    : "top-center"
            }
            toastOptions={{
                duration: 2000,
                style: {
                    background:
                        notification.send_to === "tech_updates"
                            ? "#1B1C1F"
                            : "#fff",
                },
            }}
        />
    );
};

export default Notification;

const MainContainer = styled.div`
    position: relative;
    background: "#1B1C1F";
    color: "#fff";
    border: "1px solid #3B3B3B";
    padding: "8px";
    animation: "slideInRight 0.5s ease-in-out";
    margin-bottom: "10px";
`;

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const ImgContainer = styled.div`
    min-height: 45px;
    min-width: 45px;
    border-radius: 50%;
    background-position: center;
    background-size: cover;
    margin-right: 8px;
`;

const ContentContainer = styled.div`
    width: 60%;
`;

const ButtonContainer = styled.div`
    width: 40%;
    display: flex;
    padding: 6px 12px;
    align-items: center;
    border-radius: 8px;
    border: 1px solid #2c2c2c;
    cursor: pointer;
`;

const Text = styled.p`
    font-family: "gordita_regular";
    font-size: 12px;
    color: #fff;
`;

const Small = styled.small`
    font-size: 12px;
    font-family: "gordita_medium";
    color: #fff;
`;
const Button = styled(Link)`
    color: #fe673a;
    font-size: 12px;
    font-family: "gordita_regular";
    cursor: pointer;
`;

const CloseButton = styled.button`
    position: absolute;
    top: -20px;
    right: -30px;
    background-color: transparent;
    border: none;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
`;
const Close = styled.span`
    display: inline-block;
    width: 24px;
    border-radius: 50%;
    background-color: #2c2c2c;
    padding: 6px;
`;

const CloseImg = styled.img`
    display: block;
    width: 100%;
`;
