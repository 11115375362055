import React from "react";
import styled from "styled-components";
import moment from "moment/moment";
import Avatar from "react-avatar";

function EvaluvationStudentDetails({
    studentData,
    setActivityModal,
    setCommitModal,
}) {
    return (
        // <MainContainer>
        //     <ContainerDiv>
        <StudentDetialsContainer>
            <StudentDetials>
                <Card type="student">
                    <Label>Student</Label>
                    <List>
                        <Item>
                            <StudentImg>
                                {studentData?.student_project?.student
                                    ?.student_photo ? (
                                    <img
                                        src={
                                            studentData?.student_project
                                                ?.student?.student_photo
                                        }
                                        alt="avathar"
                                    />
                                ) : (
                                    <Avatar
                                        name={
                                            studentData?.student_project
                                                ?.student?.student_name
                                        }
                                        size="40"
                                        round
                                    />
                                )}
                            </StudentImg>
                            <Student>
                                <Name>
                                    {studentData?.student_project?.student
                                        ?.student_name ?? "--"}
                                </Name>
                                <Id>
                                    ID:{" "}
                                    {studentData?.student_project?.student
                                        ?.user_pk ?? "--"}
                                </Id>
                            </Student>
                        </Item>
                    </List>
                </Card>
                <Card type="project">
                    <Label>Project</Label>
                    <List>
                        <Item>
                            <ProjectName>
                                {" "}
                                {studentData?.student_project?.project ?? "--"}
                            </ProjectName>
                        </Item>
                    </List>
                </Card>
                <Card type="repo">
                    <Label>Repo link</Label>
                    <List>
                        <Item>
                            <RepoLink
                                href={`${
                                    studentData?.student_project?.repo_url ??
                                    "https://steyp.com/?action=login&next=/dashboard/"
                                }}`}
                                target="_blank"
                            >
                                View link
                            </RepoLink>
                        </Item>
                    </List>
                </Card>
                <Card type="status">
                    <Label>Project status</Label>
                    <List>
                        <Item>
                            <StatusType>
                                {studentData?.student_project
                                    ?.evaluation_status ?? "--"}
                            </StatusType>
                        </Item>
                    </List>
                </Card>
                <Card type="time">
                    <Label>Date & time</Label>
                    <List>
                        <Item className="time">
                            <TimeContainerDate>
                                {" "}
                                {studentData?.student_project?.date_updated
                                    ? moment(
                                          studentData?.student_project
                                              ?.date_updated
                                      ).format("MMM DD")
                                    : "--"}
                            </TimeContainerDate>
                            <TimeContainerTime>
                                {" "}
                                {studentData?.student_project?.date_updated
                                    ? moment(
                                          studentData?.student_project
                                              ?.date_updated
                                      ).format("LT")
                                    : "--"}
                            </TimeContainerTime>
                        </Item>
                    </List>
                </Card>
                <Card type="activity">
                    <Label>QA activities</Label>
                    <List>
                        <Item>
                            <Button onClick={() => setActivityModal(true)}>
                                View Activity
                            </Button>
                        </Item>
                    </List>
                </Card>
                <Card type="commit">
                    <Label>Commit history</Label>
                    <List>
                        <Item>
                            <Button onClick={() => setCommitModal(true)}>
                                View history
                            </Button>
                        </Item>
                    </List>
                </Card>
            </StudentDetials>
        </StudentDetialsContainer>
        // </ContainerDiv>
        // </MainContainer>
    );
}

export default EvaluvationStudentDetails;

const MainContainer = styled.div`
    width: 100%;
    overflow-x: scroll;
    &::-webkit-scrollbar {
        display: none;
    }
`;

const ContainerDiv = styled.div`
    min-width: 1620px;
`;
const StudentDetialsContainer = styled.div`
    border-radius: 12px;
    border: 1px solid #2c2c2c;
    background: #161619;
    padding: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
const HrLine = styled.hr`
    background: #28292d;
    height: 1px;
    margin: 24px 0;
    width: 100px;
    transform: rotate(90deg);
`;

const StudentDetials = styled.div`
    display: flex;
    /* gap: 70px; */
    /* flex-wrap: wrap; */
    width: 100%;
    justify-content: space-between;
`;
const StudentActivity = styled.div`
    width: 45%;
`;

const Card = styled.div`
    width: ${({ type }) =>
        type === "student"
            ? "15%"
            : type === "project"
            ? "18%"
            : type === "repo"
            ? "10%"
            : type === "status"
            ? "18%"
            : type === "time"
            ? "8%"
            : type === "activity"
            ? "10%"
            : type === "commit"
            ? "11%"
            : ""};
`;
const Label = styled.h5`
    color: #94a3b8;
    font-size: 14px;
    font-family: "gordita_regular";
    margin-bottom: 12px;
`;
const List = styled.ul``;
const Item = styled.li`
    display: flex;
    align-items: center;
    gap: 12px;
    &.time {
        flex-direction: column;
        align-items: flex-start;
        gap: 4px;
    }
`;
const Student = styled.div``;
const StudentImg = styled.span`
    width: 40px;
    height: 40px;
    overflow: hidden;
    border-radius: 50%;
    margin-right: 10px;
    display: inline-block;
    img {
        display: block;
        width: 100%;
    }
`;
const Icon = styled.img`
    width: 100%;
    display: block;
`;
const Name = styled.h4`
    color: #fff;
    font-size: 14px;
    font-family: "gordita_medium";
    text-transform: capitalize;
`;
const Id = styled.h5`
    color: #fe673a;
    font-size: 14px;
    font-family: "gordita_regular";
`;

const ProjectName = styled.h5`
    color: #e2e8f0;
    font-size: 16px;
    font-family: "gordita_regular";
`;

const RepoLink = styled.a`
    color: #fe673a;
    font-size: 16px;
    font-family: "gordita_regular";
    border-bottom: 1px solid #fe673a;
    cursor: pointer;
`;
const TimeContainerDate = styled.h5`
    color: #fff;
    font-size: 14px;
    font-family: "gordita_medium";
`;
const TimeContainerTime = styled.h5`
    color: #d2caca;
    font-size: 14px;
    font-family: "gordita_regular";
`;
const StatusType = styled.div`
    color: #d0d5dd;
    display: flex;
    height: 26px;
    padding: 2px 8px;
    align-items: center;
    border-radius: 6px;
    border: 1px solid #d0d5dd;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
`;

const ActivityHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
const HeadText = styled.h5`
    color: #e2e8f0;
    font-size: 16px;
    font-family: "gordita_medium";
`;
const HeadIcon = styled.span`
    width: 20px;
    cursor: pointer;
    display: inline-block;
`;

const ActivitySection = styled.div`
    border-radius: 12px;
    border: 1px solid #2c2c2c;
    background: #161619;
    padding: 24px;
    margin-top: 15px;
    h1 {
        color: #e2e8f0;
        font-size: 16px;
        font-family: "gordita_medium";
        margin-bottom: 24px;
    }
`;

const Button = styled(RepoLink)``;
