import React from "react";
import styled from "styled-components";
function NoData() {
    return (
        <Container>
            {" "}
            <ImageBox>
                <CardImage
                    src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/yiaai/01-02-2022/images/not_loaded.svg"
                    alt="Image"
                />
                <p>No data found</p>
            </ImageBox>
        </Container>
    );
}
const Container = styled.div`   
    padding: 75px 0;
    height: 70vh;
`;
const ImageBox = styled.div`
    margin: auto;
    width: 20%;
    height: calc(100% - (-50px));
    @media all and (max-width: 980px) {
        width: 200px;
    }
    p {
        color: #465663;
        font-size: 18px;
        font-family: "gordita_medium";
        text-align: center;
        margin-top: 15px;
    }
`;

const CardImage = styled.img`
    width: 100%;
    display: block;
`;
export default NoData;
