import React, { useState } from "react";
import styled from "styled-components";
import SteypHelmet from "../../helpers/SteypHelmet";
import ProjectViewPageHead from "../../includes/projects-section/ProjectViewPageHead";
import ProjectViewNav from "../../includes/projects-section/ProjectViewNav";
import ProjectOverView from "../../includes/projects-section/ProjectOverView";
import ProjectAssignedPage from "../../includes/projects-section/ProjectAssignedPage";
import ProjectEnrolledPage from "../../includes/projects-section/ProjectEnrolledPage";
import ProjectSubmissionsPage from "../../includes/projects-section/ProjectSubmissionsPage";
import { useParams, useSearchParams } from "react-router-dom";

function ProjectOverViewScreen() {
    const [selectedNav, setSelectedNav] = useState("Overview");
    const [searchParams, setSearchParams] = useSearchParams();
    const project_pk = searchParams.get("slug");

    const { slug } = useParams();
    console.log(slug, "slug");

    const navList = [
        {
            name: "Overview",
        },
        {
            name: "Assigned",
        },
        {
            name: "Enrolled",
        },
        {
            name: "Submissions",
        },
    ];

    return (
        <Container id="main">
            <MainContainer>
                <ContainerDiv>
                    <SteypHelmet title="Project Overview" />
                    <ProjectViewPageHead />
                    <ProjectViewNav
                        navList={navList}
                        setSelectedNav={setSelectedNav}
                    />
                    {selectedNav === "Overview" ? (
                        <ProjectOverView slug={slug} />
                    ) : selectedNav === "Assigned" ? (
                        <ProjectAssignedPage />
                    ) : selectedNav === "Enrolled" ? (
                        <ProjectEnrolledPage />
                    ) : selectedNav === "Submissions" ? (
                        <ProjectSubmissionsPage />
                    ) : null}
                </ContainerDiv>
            </MainContainer>
        </Container>
    );
}

export default ProjectOverViewScreen;
const MainContainer = styled.div`
    width: 100%;
    overflow-x: scroll;
    &::-webkit-scrollbar {
        display: none;
    }
`;
const ContainerDiv = styled.div`
    /* min-width: 1620px; */
    min-width: 1100px;
`;
const Container = styled.div``;
const HrLine = styled.hr`
    background: #28292d;
    height: 1px;
    margin: 24px 0;
`;
