import React, {
    useState,
    useCallback,
    useContext,
    useEffect,
    useRef,
} from "react";
import styled from "styled-components";
import BrowsFiles from "../../../assets/images/browsfile.png";
import { useDropzone } from "react-dropzone";
import TechEditor from "../../includes/content-manager/TechEditor";
import { useParams } from "react-router-dom";
import { error } from "jquery";
import { learnConfig } from "../../../axiosConfig";
import { Context } from "../../contexts/Store";
import ButtonLoader from "../../genaral/Loader/ButtonLoader";
import SectionLoader from "../../helpers/SectionLoader";
import NoData from "../../includes/jobdesk/NoData";
import { useLocation } from "react-router-dom";
import TechUpdateHistoryModal from "../../includes/content-manager/modal/TechUpdateHistoryModal";

function TechUpdateEditingScreen() {
    //imgprev
    const [droppedFile, setDroppedFile] = useState({});
    const [imagePreview, setImagePreview] = useState("");
    //   console.log(editorContent, "editorContent");

    //category
    const [isCategory, setCategory] = useState(false);
    const [showCategory, setShowCategory] = useState("");
    const [categories, setCategories] = useState([]);
    const [categorySearch, setCategorySearch] = useState("");

    //tags
    const [tag, setTag] = useState([]);
    const [newTag, setNewTag] = useState("");

    //reference
    const [references, setReferences] = useState([]);
    const [referanceTitle, setReferanseTitle] = useState("");
    const [referenceURL, setReferanceURL] = useState("");

    const [editorContent, setEditorContent] = useState("");
    const [isDraft, setDraft] = useState(false);
    const [articleTitle, setArticleTitle] = useState("");
    const [subTitle, setSubTitile] = useState("");
    const { article_pk } = useParams();

    //loaders
    const [saveLoader, setSaveLoader] = useState(false);
    const [categoryLoader, setcategoryLoader] = useState(false);
    const [isPageLoading, setPageLoading] = useState(false);

    //filter
    const [activeClick, setActiveClick] = useState("History");
    const [isDropdown, setDropdown] = useState(false);

    const wrapperRef = useRef(null);

    // outside click
    function useOutsideClick(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setDropdown(false);
                }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }
    useOutsideClick(wrapperRef);

    // const [editorLoader, setEditLoader] = useState(false);

    const [isError, setError] = useState("");
    const location = useLocation();

    const {
        state: {
            user_data: { access_token },
        },
    } = useContext(Context);

    useEffect(() => {
        if (showCategory) {
            setError("");
        }
    }, [isError, showCategory]);

    //imgprev
    const onDrop = useCallback((acceptedFiles) => {
        acceptedFiles.forEach((file) => {
            const reader = new FileReader();

            reader.onabort = () => console.log("file reading was aborted");
            reader.onerror = () => console.log("file reading has failed");
            reader.onload = () => {
                const binaryStr = reader.result;
                setImagePreview(binaryStr);
                setDroppedFile(file);
            };
            reader.onloadend = () => {
                const img = new Image();
                img.onload = () => {
                    console.log("==img-width==", img.width);
                };
            };
            reader.readAsDataURL(file);
        });
    }, []);
    const { getRootProps, getInputProps } = useDropzone({ onDrop });

    //TagRemove
    const removeSelectedTags = (tagname) => {
        const newTags = tag.filter((item) => item.name !== tagname);
        setTag(newTags);
    };

    //------Save Add Datas To The Draft --------
    const saveDraft = () => {
        if (showCategory) {
            const formData = new FormData();

            formData.append("title", articleTitle ? articleTitle : "");
            formData.append("short_description", subTitle ? subTitle : "");
            formData.append("content", JSON.stringify(editorContent));
            if (droppedFile && droppedFile.name) {
                formData.append("featured_image", droppedFile);
            }
            formData.append("category", showCategory.id);
            formData.append("tags", JSON.stringify(tag));
            formData.append("referances", JSON.stringify(references));
            setSaveLoader(true);
            learnConfig
                .post(`tech-updates/update-article/${article_pk}/`, formData, {
                    headers: {
                        Authorization: `Bearer ${access_token}`,
                    },
                })
                .then((response) => {
                    let { StatusCode } = response.data;
                    if (StatusCode === 6000) {
                        setDraft(false);
                        setSaveLoader(false);
                    } else {
                        setSaveLoader(false);
                        console.error(error);
                    }
                })
                .catch((error) => {
                    setSaveLoader(false);
                    console.error(error);
                });
        } else {
            setError("please select category");
        }
    };

    //To. get all categories
    const categoryList = () => {
        setcategoryLoader(true);
        learnConfig
            .get("/tech-updates/get-categories/", {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
                params: {
                    q: categorySearch,
                },
            })
            .then((response) => {
                let { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setCategories(data);
                    setcategoryLoader(false);
                } else {
                    console.log(error);
                    setcategoryLoader(false);
                }
            })
            .catch((error) => {
                console.error(error);
                setcategoryLoader(false);
            });
    };

    // to get last updated article
    useEffect(() => {
        setPageLoading(true);
        learnConfig
            .get(`tech-updates/get-updated-article/${article_pk}/`, {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            })
            .then((response) => {
                let { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    console.log("data.referances", data.referances);
                    setDraft(false);
                    setPageLoading(false);
                    setEditorContent(
                        data.content === null ? {} : JSON.parse(data.content)
                    );
                    setArticleTitle(data.title);
                    setSubTitile(data.short_description);
                    setImagePreview(data.featured_image);
                    setShowCategory(data.category);
                    setTag(data.tags);
                    setReferences(data.referances);
                } else {
                    console.error(error);
                    setPageLoading(false);
                }
            })
            .catch((error) => {
                console.error(error);
                setPageLoading(false);
            });
    }, []);

    useEffect(() => {
        categoryList();
    }, [categorySearch]);

    return (
        <SectionMain id="main">
            <Container>
                <Left>
                    <HeadContainer>
                        <EditorHeadContainer>
                            <EditorImgContainer>
                                <img
                                    src={
                                        require("../../../assets/images/t.svg")
                                            .default
                                    }
                                    alt="Category"
                                />
                            </EditorImgContainer>
                            <EditorHead>Write article here:</EditorHead>
                        </EditorHeadContainer>
                        <ButtonContainer>
                            {!location.pathname.includes(
                                "/content-editor/view/"
                            ) &&
                            isDraft &&
                            editorContent.blocks?.length > 0 ? (
                                <PreviewContainer
                                    onClick={() => {
                                        saveDraft();
                                    }}
                                >
                                    <Review
                                        style={{
                                            background: "green",
                                        }}
                                    >
                                        {saveLoader ? (
                                            <ButtonLoader />
                                        ) : (
                                            "Save To Draft"
                                        )}
                                    </Review>
                                    <p> {isError && isError}</p>
                                </PreviewContainer>
                            ) : (
                                ""
                            )}
                        </ButtonContainer>
                    </HeadContainer>

                    {isPageLoading ? (
                        <RichTextContainer>
                            <SectionLoader />
                        </RichTextContainer>
                    ) : editorContent ? (
                        <RichTextContainer>
                            <TechEditor
                                article_pk={article_pk}
                                editorContent={editorContent}
                                setEditorContent={setEditorContent}
                                setDraft={setDraft}
                                isDraft={isDraft}
                            />
                        </RichTextContainer>
                    ) : (
                        <NoData />
                    )}
                </Left>
                <Right>
                    <Editorr>
                        <EditorHeadingContainer>
                            <EditorHeading>Post Settings</EditorHeading>
                            <EditorButtonContainer>
                                <TechUpdateHistoryModal
                                    activeClick={activeClick}
                                    isDropdown={isDropdown}
                                    setDropdown={setDropdown}
                                    wrapperRef={wrapperRef}
                                    accessToken={access_token}
                                    articlePK={article_pk}
                                />
                            </EditorButtonContainer>
                        </EditorHeadingContainer>
                        <EditorContainer>
                            <EditorHeadContainer>
                                <EditorImgContainer>
                                    <img
                                        src={
                                            require("../../../assets/images/pointlist.svg")
                                                .default
                                        }
                                        alt="Category"
                                    />
                                </EditorImgContainer>
                                <EditorHead>Title</EditorHead>
                            </EditorHeadContainer>
                            <TitleInput>
                                <InputContainer
                                    value={articleTitle}
                                    onChange={(e) => {
                                        setArticleTitle(e.target.value);
                                        if (!isDraft) {
                                            setDraft(true);
                                        }
                                    }}
                                    placeholder="Type here..."
                                />
                            </TitleInput>
                            <EditorHeadContainer>
                                <EditorImgContainer>
                                    <img
                                        src={
                                            require("../../../assets/images/pointlist.svg")
                                                .default
                                        }
                                        alt="Category"
                                    />
                                </EditorImgContainer>
                                <EditorHead>Short-Description</EditorHead>
                            </EditorHeadContainer>
                            <SubTitile>
                                <SubInput
                                    value={subTitle}
                                    onChange={(e) => {
                                        setSubTitile(e.target.value);
                                        if (!isDraft) {
                                            setDraft(true);
                                        }
                                    }}
                                    placeholder="Type here..."
                                />
                            </SubTitile>
                            <EditorHeadContainer>
                                <EditorImgContainer>
                                    <img
                                        src={
                                            require("../../../assets/images/category.svg")
                                                .default
                                        }
                                        alt="Category"
                                    />
                                </EditorImgContainer>
                                <EditorHead>Category</EditorHead>
                            </EditorHeadContainer>
                            <CategoryModalContainer
                                onClick={() => {
                                    setCategory(!isCategory);
                                    // console.log("isCategory", isCategory);
                                }}
                            >
                                <CategorySelectContainer
                                    className={isCategory ? "active" : ""}
                                    // onClick={() => {
                                    //     setCategory(!isCategory);
                                    // }}
                                >
                                    <CategorySelect
                                        type="text"
                                        placeholder="Select a category"
                                        value={
                                            showCategory
                                                ? showCategory?.title
                                                : categorySearch
                                        }
                                        onChange={(e) => {
                                            setCategorySearch(e.target.value);
                                            setShowCategory(null);
                                            if (!isCategory) {
                                                setCategory(true);
                                            }
                                            if (!isDraft) {
                                                setDraft(true);
                                            }
                                        }}
                                    />
                                    <CategorySelectIcon>
                                        <img
                                            src={
                                                require("../../../assets/images/arrow.svg")
                                                    .default
                                            }
                                            alt="Downarrow"
                                        />
                                    </CategorySelectIcon>
                                </CategorySelectContainer>
                                {categoryLoader ? (
                                    <MainModal
                                        className={
                                            categoryLoader ? "active" : ""
                                        }
                                    >
                                        <ButtonLoader />
                                    </MainModal>
                                ) : (
                                    isCategory && (
                                        <MainModal>
                                            <CategorySelectModal>
                                                {categories.length > 0 ? (
                                                    categories?.map(
                                                        (category) => (
                                                            <div
                                                                key={
                                                                    category.id
                                                                }
                                                            >
                                                                <ModalSelect
                                                                    onClick={() => {
                                                                        setShowCategory(
                                                                            category
                                                                        );
                                                                        setCategory(
                                                                            !isCategory
                                                                        );
                                                                        setCategorySearch(
                                                                            category.title
                                                                        );
                                                                    }}
                                                                >
                                                                    {
                                                                        category.title
                                                                    }
                                                                </ModalSelect>
                                                            </div>
                                                        )
                                                    )
                                                ) : (
                                                    <div>
                                                        <ModalSelect>
                                                            No data
                                                        </ModalSelect>
                                                    </div>
                                                )}
                                            </CategorySelectModal>
                                        </MainModal>
                                    )
                                )}
                            </CategoryModalContainer>
                            <EditorHeadContainer>
                                <EditorImgContainer>
                                    <img
                                        src={
                                            require("../../../assets/images/category.svg")
                                                .default
                                        }
                                        alt="Category"
                                    />
                                </EditorImgContainer>
                                <EditorHead>Thumbnail</EditorHead>
                            </EditorHeadContainer>
                            <BrowsFileContainer
                                onClick={(e) => {
                                    e.preventDefault();
                                    document
                                        .getElementById("drop-input")
                                        .click();
                                }}
                                {...getRootProps()}
                            >
                                {imagePreview && (
                                    <Close
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setDroppedFile("");
                                            setImagePreview("");
                                            if (!isDraft) {
                                                setDraft(true);
                                            }
                                        }}
                                    >
                                        X
                                    </Close>
                                )}
                                <DropFile
                                    id="drop-input"
                                    type="file"
                                    onChange={(e) => {
                                        setDroppedFile(e.target.files);
                                        if (!isDraft) {
                                            setDraft(true);
                                        }
                                        console.log(
                                            "e.target.naturalWidth",
                                            e.target.naturalWidth
                                        );
                                    }}
                                    // value={droppedFile}
                                    {...getInputProps()}
                                />
                                {imagePreview ? (
                                    <PreviewImage>
                                        <img
                                            src={imagePreview}
                                            alt="SelectedImage"
                                        />
                                    </PreviewImage>
                                ) : (
                                    <FileAdd>
                                        <FileImg>
                                            <img
                                                src={BrowsFiles}
                                                alt="BrowsFiles"
                                            />
                                        </FileImg>
                                        <FileHead>
                                            Drag and Drop photo here.
                                        </FileHead>
                                        <BrowsFile>Browse file</BrowsFile>
                                    </FileAdd>
                                )}
                            </BrowsFileContainer>
                            <EditorHeadContainer>
                                <EditorImgContainer>
                                    <img
                                        src={
                                            require("../../../assets/images/tag.svg")
                                                .default
                                        }
                                        alt="Category"
                                    />
                                </EditorImgContainer>
                                <EditorHead>Tags</EditorHead>
                            </EditorHeadContainer>
                            <TagMainContainer>
                                <TagAddContainer>
                                    {tag.length > 0 &&
                                        tag.map((tagObj, index) => (
                                            <TagSmall key={index}>
                                                <TagText>{tagObj.name}</TagText>
                                                <TagSpan
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        removeSelectedTags(
                                                            tagObj.name
                                                        );
                                                    }}
                                                >
                                                    <img
                                                        src={
                                                            require("../../../assets/images/close-icon.svg")
                                                                .default
                                                        }
                                                        alt="Category"
                                                    />
                                                </TagSpan>
                                            </TagSmall>
                                        ))}
                                </TagAddContainer>
                                <TagContainer
                                    type="text"
                                    placeholder="Type to pic or create tag ...."
                                    onChange={(e) => {
                                        e.preventDefault();
                                        setNewTag(e.target.value);
                                        if (!isDraft) {
                                            setDraft(true);
                                        }
                                    }}
                                    value={newTag}
                                    onKeyPress={(e) => {
                                        if (
                                            e.key === "Enter" &&
                                            newTag.trim() !== ""
                                        ) {
                                            setNewTag("");
                                            setTag([
                                                ...tag,
                                                {
                                                    name: newTag.trim(),
                                                },
                                            ]);
                                        }
                                    }}
                                />
                            </TagMainContainer>

                            <EditorHeadContainer>
                                <EditorImgContainer>
                                    <img
                                        src={
                                            require("../../../assets/images/references.svg")
                                                .default
                                        }
                                        alt="Category"
                                    />
                                </EditorImgContainer>
                                <EditorHead>References</EditorHead>
                            </EditorHeadContainer>
                            <ReferencesContainer>
                                <References>
                                    <AddReferences>
                                        <RefTitile
                                            placeholder="Title"
                                            type="text"
                                            value={referanceTitle}
                                            onChange={(e) => {
                                                setReferanseTitle(
                                                    e.target.value
                                                );
                                            }}
                                        />
                                        <RefUrl
                                            placeholder="URL"
                                            type="url"
                                            value={referenceURL}
                                            onChange={(e) => {
                                                setReferanceURL(e.target.value);
                                            }}
                                        />
                                    </AddReferences>
                                </References>
                                <ReffAddButton
                                    onClick={() => {
                                        const newObj = {
                                            id: references.length,
                                            title: referanceTitle,
                                            url: referenceURL,
                                        };
                                        if (referanceTitle && referenceURL) {
                                            setReferences([
                                                ...references,
                                                newObj,
                                            ]);
                                            setReferanseTitle("");
                                            setReferanceURL("");
                                        }
                                        if (!isDraft) {
                                            setDraft(true);
                                        }
                                    }}
                                >
                                    <span>
                                        <img
                                            src={
                                                require("../../../assets/images/tech-update/add.svg")
                                                    .default
                                            }
                                            alt="add"
                                        />
                                    </span>
                                </ReffAddButton>
                            </ReferencesContainer>
                            {references.map((referance, index) => (
                                <AddedReferances key={index}>
                                    <References>
                                        <AddReferences>
                                            <RefTitileAdd>
                                                <small>{referance.title}</small>
                                            </RefTitileAdd>
                                            <RefUrlAdd>
                                                <small>{referance.url}</small>
                                            </RefUrlAdd>
                                        </AddReferences>
                                    </References>
                                    <ReffAddButton
                                        onClick={() => {
                                            const newArray = references.filter(
                                                (item) =>
                                                    item.id !== referance.id
                                            );
                                            setReferences(newArray);
                                        }}
                                        style={{
                                            background: "#1B1C1F",
                                            border: "1px solid #2c2c2c",
                                        }}
                                    >
                                        <span>
                                            <img
                                                src={
                                                    require("../../../assets/images/tech-update/remove.svg")
                                                        .default
                                                }
                                                alt="add"
                                            />
                                        </span>
                                    </ReffAddButton>
                                </AddedReferances>
                            ))}
                        </EditorContainer>
                    </Editorr>
                </Right>
            </Container>
        </SectionMain>
    );
}

export default TechUpdateEditingScreen;

const SectionMain = styled.section``;

const Container = styled.div`
    display: flex;
    justify-content: space-between;
`;

const Left = styled.div`
    width: 74%;
`;

const Right = styled.div`
    width: 24%;
`;

const HeadContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 18px;
`;

const Editorr = styled.div`
    border-radius: 8px;
    border: 1px solid #2c2c2c;
    background: #1b1c1f;
`;

const EditorContainer = styled.div`
    display: flex;
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
`;
const EditorHeadingContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 14px 24px;
    border-bottom: 1px solid #2c2c2c;
`;
const EditorButtonContainer = styled.div``;
const EditorHeading = styled.h1`
    color: #fff;
    font-size: 18px;
`;
const EditorButton = styled.button`
    border-radius: 8px;
    border: 1px solid #2c2c2c;
    color: #fff;
    width: 80px;
    height: 35px;
`;
const EditorHeadContainer = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 20px;
`;
const CategoryModalContainer = styled.div`
    position: relative;
    width: 100%;
`;
const EditorHead = styled.h5`
    color: #c9c3c2;
    font-size: 16px;
    font-family: gordita_medium;
    height: 16px;
`;

const EditorImgContainer = styled.div`
    width: 24px;
    margin-right: 10px;
    img {
        display: block;
        width: 100%;
    }
`;

const ButtonContainer = styled.div`
    display: flex;
`;

const CategorySelectContainer = styled.div`
    display: flex;
    width: 100%;
    height: 71px;
    padding: 22px 18px;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    border-radius: 8px;
    border: 1px solid #2c2c2c;
    background: #161619;
    position: relative;
    margin-bottom: 20px;
    cursor: pointer;
`;

const CategorySelect = styled.input`
    color: #fff;
    font-size: 18px;

    @media all and (max-width: 1380px) {
        font-size: 16px;
    }

    @media all and (max-width: 1280px) {
        font-size: 14px;
    }
`;

const ModalSelect = styled.div`
    color: #777;
    font-size: 16px;
    margin-bottom: 5px;
    font-family: gordita_medium;
    padding: 14px 20px;
    :hover {
        background: #2c2c2c;
    }

    @media all and (max-width: 1280px) {
        font-size: 12px;
    }
`;

const CategorySelectIcon = styled.div`
    width: 20px;
    cursor: pointer;
    img {
        display: block;
        width: 100%;
    }
    @media all and (max-width: 1380px) {
        width: 18px;
    }
`;

const MainModal = styled.div`
    /* width: 18%; */
    position: absolute;
    top: 70px;
    left: 0;
    width: 100%;
    z-index: 10;
    &.active {
        height: 210px;
        background: #161619;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    @media all and (max-width: 1380px) {
        width: 19%;
    }
`;

const CategorySelectModal = styled.div`
    border-radius: 8px;
    border: 1px solid #2c2c2c;
    border-bottom: 1px solid #2c2c2c;
    background: #161619;
    max-height: 210px;
    overflow-y: scroll;
    cursor: pointer;
    &::-webkit-scrollbar {
        display: none;
    }
    @media all and (max-width: 1380px) {
        width: 237px;
    }
    @media all and (max-width: 1280px) {
        width: 180px;
    }
`;

const BrowsFileContainer = styled.div`
    width: 100%;
    height: 242px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    background: #161619;
    border-radius: 8px;
    border: 1px solid #2c2c2c;
    cursor: pointer;
    margin-bottom: 20px;
    @media all and (max-width: 1380px) {
        height: 185px;
    }
    @media all and (max-width: 1280px) {
        height: 148px;
    }
`;

const FileAdd = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const FileImg = styled.div`
    width: 65px;
    img {
        display: block;
        width: 100%;
    }
`;

const FileHead = styled.p`
    color: #9f9f9f;
    font-size: 12px;
`;

const BrowsFile = styled.small`
    color: #fe673a;
    font-size: 12px;
    text-decoration-line: underline;
`;
const DropFile = styled.input`
    visibility: hidden;
`;

const TitleInput = styled.div`
    border-radius: 6px;
    border: 1px solid #2c2c2c;
    background: #161619;
    padding: 10px 20px;
    width: 100%;
    margin-bottom: 20px;
`;

const InputContainer = styled.textarea`
    display: flex;
    flex-direction: column;
    color: #fff;
    font-family: gordita_medium;
    font-size: 18px;
    width: 100%;
    &::-webkit-scrollbar {
        display: none;
    }
`;
const SubTitile = styled.div`
    border-radius: 6px;
    border: 1px solid #2c2c2c;
    background: #161619;
    padding: 10px 20px;
    width: 100%;
    margin-bottom: 20px;
`;
const SubInput = styled(InputContainer)`
    /* display: flex;
    flex-direction: column;
    color: #fff;
    font-family: gordita_medium;
    font-size: 18px;
    width: 100%; */
`;

const RichTextContainer = styled.div`
    width: 100%;
    border-radius: 8px !important;
    overflow: hidden;
`;

const TagMainContainer = styled.div`
    width: 100%;
    margin-bottom: 30px;
`;

const TagContainer = styled.input`
    border-radius: 6px;
    border: 1px solid #161619;
    background: #161619 !important;
    height: 71px;
    padding: 10px 20px;
    width: 100%;
    color: #fff;
    font-family: gordita_medium;
    font-size: 16px;
    @media all and (max-width: 1280px) {
        font-size: 13px;
        padding: 4px 4px;
    }
`;

const PreviewContainer = styled.div`
    margin-right: 15px;
    &:last-child {
        margin-right: 0;
    }
    p {
        color: red;
        margin-top: 8px;
    }
`;

const Review = styled.button`
    border-radius: 8px;
    background: #fe673a;
    display: flex;
    height: 44px;
    padding: 8px 16px;
    align-items: center;
    justify-content: center;
    color: #f2f2f2;
    font-size: 16px;
    cursor: pointer;
    width: 180px;
    font-family: "gordita_medium"; // padding: 12px 24px;
`;

const TagAddContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;
`;

const TagSmall = styled.span`
    text-overflow: ellipsis;
    border-radius: 4px;
    background: #161619;
    display: flex;
    padding: 8px 12px;
    align-items: center;
    margin-right: 10px;
    margin-bottom: 10px;
`;

const TagText = styled.p`
    color: #fff;
    font-family: gordita_medium;
    font-size: 16px;
    max-width: 180px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    @media all and (max-width: 1280px) {
        max-width: 120px;
    }
`;

const TagSpan = styled.span`
    display: inline-block;
    cursor: pointer;
    img {
        display: block;
        width: 100%;
    }
`;

const References = styled.div`
    border-radius: 6px;
    border: 1px solid #2c2c2c;
    background: #1b1c1f;
    padding: 10px 0px 10px 10px;
    width: 100%;
    display: flex;
`;

const AddReferences = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`;
const AddedReferances = styled.div`
    display: flex;
    margin-bottom: 18px;
    &:last-child {
        margin-bottom: 0px;
    }
`;
const RefTitile = styled.input`
    border-radius: 6px;
    border: 1px solid #161619;
    background: #161619 !important;
    padding: 10px 20px;
    font-size: 16px;
    width: 100%;
    height: 60px;
    color: #fff;
    margin-bottom: 10px;
`;

const RefUrl = styled(RefTitile)`
    margin-bottom: 0px;
`;

const ReffAddButton = styled.button`
    display: flex;
    width: 40px;
    height: auto;
    justify-content: center;
    align-items: center;
    width: 10%;
    cursor: pointer;
    border-radius: 0px 4px 4px 0px;
    background: #2c2c2c;
    color: #fff;
    span {
        display: inline-block;
        width: 20px;
        img {
            display: block;
            width: 100%;
        }
    }
`;

const RefTitileAdd = styled.div`
    border-radius: 6px;
    border: 1px solid #161619;
    background: #161619 !important;
    padding: 10px 10px;
    display: flex;
    align-items: center;
    font-size: 16px;
    width: 100%;
    height: 60px;
    color: #707070;
    margin-bottom: 10px;

    small {
        display: inline-block;
        width: 215px;
        overflow: hidden;
        color: #fff;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    @media all and (max-width: 1380px) {
        padding: 16px 16px;
        small {
            width: 155px;
        }
    }
    @media all and (max-width: 1280px) {
        font-size: 14px;
        small {
            width: 68px;
        }
    }
`;

const RefUrlAdd = styled(RefTitileAdd)`
    margin-bottom: 0px;
`;

const PreviewImage = styled.div`
    min-width: 230px;
    max-width: 240px;
    max-height: 240px;

    img {
        display: block;
        width: 100%;
        height: 100%;
    }
    @media all and (max-width: 1380px) {
        min-width: 190px;
        max-width: 190px;
        max-height: 190px;
    }
    @media all and (max-width: 1280px) {
        min-width: 140px;
        max-width: 140px;
        max-height: 140px;
    }
`;
const Close = styled.span`
    display: inline-block;
    width: 20px;
    position: absolute;
    top: 15px;
    right: 15px;
    color: #fff;
    cursor: pointer;
    @media all and (max-width: 1380px) {
        top: 5px;
        right: 3px;
    }
`;

const ReferencesContainer = styled.div`
    display: flex;
    margin-bottom: 18px;
`;
