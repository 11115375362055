import styled from "styled-components";
import { Link } from "react-router-dom";
import ProjectTable from "./ProjectTable";

function ProjectSubmissionsPage({
    setSearchWord,
    searchWord,
    setSearchParams,
}) {
    return (
        <Container>
            <FormBody>
                <ProjectTable />
            </FormBody>
        </Container>
    );
}

export default ProjectSubmissionsPage;

const Container = styled.div``;

const FormHead = styled.div`
    border-radius: 4px;
    padding: 16px;
    border: 1px solid #2c2c2c;
    background: #1b1c1f;
    margin-bottom: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 32px;
`;

const SearchContainer = styled.div`
    display: flex;
    width: 350px;
    flex-direction: column;
    align-items: flex-start;
    gap: 6px;
`;
const Label = styled.label`
    color: #c9c9c9;
    font-size: 14px;
    font-family: "gordita_regular";
`;

const FormBody = styled.div``;
const InputConatainer = styled.div`
    border-radius: 6px;
    border: 1px solid #3d3d3d;
    background: #161619;
    padding: 8px 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
`;

const Input = styled.input`
    color: #fff;
    font-size: 16px;
    width: 100%;
    &::placeholder {
        color: #4f5360;
        font-size: 14px;
    }
`;
const Icon = styled.span`
    display: inline-block;
    width: 20px;
    margin-right: 12px;
`;
const SearchIcon = styled.img`
    display: block;
    width: 100%;
`;
