import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { learnConfig } from "../../../../axiosConfig";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Context } from "../../../contexts/Store";
import ButtonLoader from "../../../genaral/Loader/ButtonLoader";
import moment from "moment/moment";

function ProjectAddModal({
    EvaluationID,
    bugEditData,
    addBugData,
    setReLoad,
    setStatus,
    setModal,
    pkvlaue,
    reLoad,
    status,
    modal,
    setBugEditData,
    editingIssue,
}) {
    const [selectedAttachment, setSelectedAttachment] = useState([]);
    const [chiefAttachment, setChiefAttachment] = useState([]);
    const [isSecurityDrop, setSecurityDrop] = useState(false);
    const [isDragActive, setIsDragActive] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [isStatusDrop, setStatusDrop] = useState(false);
    const [description, setDescription] = useState("");
    const [screenText, setScreenText] = useState("");
    const [qaComment, setQaComment] = useState("");
    const [BugData, setBugData] = useState(addBugData);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();
    const [valueError, setValueError] = useState(false);
    const [security, setSecurity] = useState("");
    const fileInputRef = useRef(null);
    const [cancelToken, setCancelToken] = useState(null);

    const params = useParams();
    useEffect(() => {
        setBugData(addBugData);
    }, [addBugData]);

    useEffect(() => {
        if (bugEditData) {
            const status = bugStatus.filter(
                (item) => item.title === bugEditData?.status
            );
            setStatus(status[0]);
            setDescription(bugEditData?.description);
            setQaComment(bugEditData?.qa_note);
            setScreenText(bugEditData?.title);
            const severityLevel = securityLevel.filter(
                (item) => item.title === bugEditData?.severity
            );
            setSecurity(severityLevel[0]);
            setBugData({
                issue_id: bugEditData?.issue_id,
                added_date: bugEditData?.date_added,
                id: bugEditData?.id,
            });
            let attachments = [];
            bugEditData?.chief_attachments?.length > 0 &&
                bugEditData?.chief_attachments?.map((item) => {
                    let data = {
                        id: item?.id,
                        file: {
                            name: item?.file_name,
                            size: item?.file_size,
                            type: `${item?.file_type}/${item.file_extension}`,
                        },
                        uploaded: true,
                    };
                    attachments = [...attachments, data];
                });
            setSelectedAttachment(attachments);
            // setChiefAttachment([...chiefAttachment, file]);
        }
    }, [bugEditData]);

    const handleClear = () => {
        setModal(false);
        setStatus("");
        setSecurity("");
        setQaComment("");
        setScreenText("");
        setDescription("");
        setUploadProgress(0);
        setChiefAttachment([]);
        setSelectedAttachment([]);
        setBugEditData("");
    };

    const {
        state: {
            user_data: { access_token },
        },
    } = useContext(Context);

    const handleDragOver = (e) => {
        e.preventDefault();
        setIsDragActive(true);
    };

    // mb kb formater
    const formatFileSize = (sizeInBytes) => {
        if (!isNaN(sizeInBytes)) {
            const sizeInKB = sizeInBytes / 1024;
            const sizeInMB = sizeInKB / 1024;

            if (sizeInMB >= 1) {
                return sizeInMB.toFixed(1) + " MB";
            } else {
                return sizeInKB.toFixed(1) + " KB";
            }
        } else {
            return sizeInBytes;
        }
    };

    const handleFiles = (file) => {
        let new_file;
        new_file = {
            id: selectedAttachment?.length + 1,
            file: file,
            uploaded: false,
        };
        setSelectedAttachment([...selectedAttachment, new_file]);
        setChiefAttachment([...chiefAttachment, file]);
        // simulateFileUpload(file, new_file);
        AddIssueAttachment(file, new_file);
    };

    const handleDrop = (e) => {
        e.preventDefault();
        setIsDragActive(false);
        const files = e.dataTransfer.files;
        if (files.length > 0) {
            handleFiles(files[0]);
        }
        setStatusDrop(false);
    };

    const handleFileChange = (event) => {
        const file = event?.target.files[0];
        if (file.size <= 26109542.4) {
            handleFiles(file);
        } else {
            alert("File size should be less than 25 MB");
        }
    };

    const handleChange = (e, type) => {
        const newValue = e.target.value;
        if (type === "qa") {
            setQaComment(newValue);
        } else if (type === "screen") {
            setDescription(newValue);
        }
    };

    const handleDivClick = () => {
        fileInputRef.current.click();
    };

    const maxCharacters = 300;
    const qaCharactersLeft = maxCharacters - qaComment?.length;
    const charactersLeft = maxCharacters - description?.length;
    const isScreenOverLimit = charactersLeft <= 0;
    const isQaOverLimit = qaCharactersLeft <= 0;

    // Remove overflow scroll on modal
    useEffect(() => {
        if (modal) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "unset";
        }
    }, [modal]);

    const bugStatus = [
        {
            id: 1,
            name: "Open",
            title: "open",
            color: "#2E90FA",
        },
        {
            id: 2,
            name: "Reopen",
            title: "re_open",
            color: "#F79009",
        },
        {
            id: 3,
            name: "Done",
            title: "done",
            color: "#16B364",
        },
    ];

    const securityLevel = [
        {
            id: 1,
            name: "Low",
            title: "low",
            color: "#0FA76F",
        },
        {
            id: 2,
            name: "Medium",
            title: "medium",
            color: "#D97706",
        },
        {
            id: 3,
            name: "High",
            title: "high",
            color: "#FE673A",
        },
    ];
    const removeFile = (attachment) => {
        cancelToken?.cancel();
        let successfullyRemoved = bugImageDelete(attachment.id);
        if (successfullyRemoved) {
            let selected_attachment = selectedAttachment.filter(
                (item) => item.file !== attachment.file
            );
            setSelectedAttachment(selected_attachment);
            let chief_attachment = chiefAttachment.filter(
                (item) => item !== attachment.file
            );
            setChiefAttachment(chief_attachment);
        }
    };

    const handleValues = () => {
        setValueError(true);
        if (!screenText) {
            return true;
        } else if (!description) {
            return true;
        } else if (!security) {
            return true;
        } else {
            setValueError(false);
            return false;
        }
    };

    const postBug = async () => {
        setLoading(true);
        let errorStatus = handleValues();
        if (!errorStatus) {
            let formData = new FormData();
            formData.append("project_id", params?.evaluation_id);
            formData.append("title", screenText);
            formData.append("description", description ?? "");
            formData.append("qa_note", qaComment ?? "");
            formData.append("status", status.title ?? "");
            formData.append("severity", security.title ?? "");
            formData.append("evaluation_pk ", pkvlaue ?? "");

            try {
                const res = await learnConfig.post(
                    `projects/student-issues-posting/${BugData?.id}/`,
                    formData,
                    {
                        headers: {
                            Authorization: `Bearer ${access_token}`,
                        },
                    }
                );

                let { StatusCode, data } = res.data;
                if (StatusCode === 6000) {
                    setLoading(false);
                    handleClear();
                    setReLoad(!reLoad);
                } else {
                    setLoading(false);
                }
            } catch (err) {
                setLoading(false);
            }
        } else {
            setLoading(false);
        }
    };

    // delete image

    const bugImageDelete = async (id) => {
        const uuidRegex =
            /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
        if (uuidRegex.test(id)) {
            try {
                const res = await learnConfig.post(
                    `projects/delete-issue-attachments/${id}/`,
                    {},
                    {
                        headers: {
                            Authorization: `Bearer ${access_token}`,
                        },
                    }
                );

                let { StatusCode, data } = res.data;
                if (StatusCode === 6000) {
                    return true;
                } else {
                    return false;
                }
            } catch (err) {
                return false;
            }
        } else {
            let newList = selectedAttachment.filter((item) => item.id !== id);
            setSelectedAttachment(newList);
            return true;
        }
    };

    const AddIssueAttachment = async (file, new_file) => {
        const source = axios.CancelToken.source();
        setCancelToken(source);
        let formData = new FormData();
        formData.append("chief_attachment", file);
        console.log("Uploading....");
        try {
            const res = await learnConfig.post(
                `projects/upload-issue-attachments/${BugData?.id}/`,
                formData,
                {
                    headers: {
                        Authorization: `Bearer ${access_token}`,
                    },
                    cancelToken: source.token,
                    onUploadProgress: (progressEvent) => {
                        const progress = Math.round(
                            (progressEvent.loaded / progressEvent.total) * 100
                        );
                        setUploadProgress(progress);
                    },
                }
            );

            let { StatusCode, data } = res.data;
            if (StatusCode === 6000) {
                new_file.id = data?.id;
                new_file.uploaded = true;
                return true;
                setUploadProgress(0);
            } else {
                return false;
            }
        } catch (error) {
            if (axios.isCancel(error)) {
                console.log("Request canceled:");
            } else {
                console.error("Error uploading file:", error);
            }
            return false;
        }
    };

    useEffect(() => {
        if (error) {
            const timer = setTimeout(() => {
                setError("");
            }, 4000);

            return () => clearTimeout(timer);
        }
    }, [error]);

    const getAttachmentType = (attachment) => {
        let type = "";
        if (attachment?.file?.type == "image/svg+xml") {
            type = attachment?.file?.type?.split("/")[1].split("+")[0];
        } else {
            type = attachment?.file?.type?.split("/")[1];
        }
        return type;
    };

    return (
        <MainContainer>
            {modal && <Overlay onClick={() => setModal(false)}></Overlay>}
            <ContainerDiv className={modal ? "active" : ""}>
                <Top>
                    <ContainerHead>
                        <HeadText>Bug ID: {BugData?.issue_id}</HeadText>
                        <HeadIcon>
                            <img
                                src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/20-01-2024/x-close.svg "
                                alt="close-icon"
                                onClick={() => {
                                    handleClear();
                                    setReLoad(!reLoad);
                                }}
                            />
                        </HeadIcon>
                    </ContainerHead>
                    {BugData?.parent_issue && (
                        <SubText>
                            Parent Bug ID: {BugData?.parent_issue?.issue_id}
                        </SubText>
                    )}

                    <TimeAndDate>
                        {BugData?.added_date
                            ? moment(BugData?.added_date).format("MMM DD")
                            : "--"}{" "}
                        ,{" "}
                        {BugData?.added_date
                            ? moment(BugData?.added_date).format("LT")
                            : "--"}
                    </TimeAndDate>
                    <MainHead>QA report</MainHead>
                    <StatusContainer>
                        <Label>Bug status</Label>
                        <StatusDiv onClick={() => setStatusDrop(!isStatusDrop)}>
                            <ValueDiv bugColor={status?.color}>
                                <Dot bugColor={status?.color}></Dot>
                                <ValueText>
                                    {status ? status.name : ""}
                                </ValueText>
                            </ValueDiv>
                            <Icon>
                                <img
                                    src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/17-01-2024/chevron-down.svg"
                                    alt="down-icon"
                                />
                            </Icon>
                        </StatusDiv>
                        {/* {isStatusDrop && (
                            <StatusDropDiv>
                                <StatusUl>
                                    {bugStatus?.map((item) => (
                                        <StatusLi
                                            key={item.id}
                                            onClick={() => {
                                                setStatus(item);
                                                setStatusDrop(false);
                                            }}
                                        >
                                            {item.name}
                                        </StatusLi>
                                    ))}
                                </StatusUl>
                            </StatusDropDiv>
                        )} */}
                    </StatusContainer>
                    <ScreenDiv>
                        <Label>
                            Title <Small>*</Small>
                        </Label>
                        <ScreenDivInput
                            type="text"
                            value={screenText}
                            onChange={(e) => {
                                const inputValue = e.target.value;
                                const capitalizedText = inputValue
                                    .split(" ")
                                    .map(
                                        (word) =>
                                            word.charAt(0).toUpperCase() +
                                            word.slice(1)
                                    )
                                    .join(" ");
                                setScreenText(capitalizedText);
                            }}
                        />
                        <ErrorMessage>
                            {valueError && !screenText && (
                                <small>Title is required</small>
                            )}
                        </ErrorMessage>
                    </ScreenDiv>

                    <ScreenContainer>
                        <Label>
                            Description <Small>*</Small>
                        </Label>
                        <ScreenInput
                            maxLength={maxCharacters}
                            value={description}
                            onChange={(e) => handleChange(e, "screen")}
                        />
                        <ErrorMessage>
                            {valueError && !description && (
                                <small>Description is required</small>
                            )}
                        </ErrorMessage>
                        <BottomText isOverLimit={isScreenOverLimit}>
                            {charactersLeft ? charactersLeft : maxCharacters}{" "}
                            characters left
                        </BottomText>
                    </ScreenContainer>
                    <HrLine />
                    <SeveritySection>
                        <Label>
                            Severity <Small>*</Small>
                        </Label>
                        <SeverityDiv
                            onClick={() => setSecurityDrop(!isSecurityDrop)}
                        >
                            <SeverityValue severityColor={security?.color}>
                                {security?.name ? security.name : ""}
                            </SeverityValue>

                            <Icon>
                                <img
                                    src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/17-01-2024/chevron-down.svg"
                                    alt="down-icon"
                                />
                            </Icon>
                        </SeverityDiv>
                        <ErrorMessage>
                            {valueError && !security && (
                                <small>Severity is required</small>
                            )}
                        </ErrorMessage>
                        {isSecurityDrop && (
                            <SecurityDropDiv>
                                <SecurityUl>
                                    {securityLevel.map((item) => (
                                        <SecurityLi
                                            key={item.id}
                                            onClick={() => {
                                                setSecurity(item);
                                                setSecurityDrop(false);
                                            }}
                                        >
                                            {item.name}
                                        </SecurityLi>
                                    ))}
                                </SecurityUl>
                            </SecurityDropDiv>
                        )}
                    </SeveritySection>
                    <AttachmentSection
                        onClick={handleDivClick}
                        onDragOver={handleDragOver}
                        onDrop={handleDrop}
                    >
                        <Label>Attachments</Label>
                        <AttachmentDiv
                            className={isDragActive ? "drag-active" : ""}
                        >
                            <Icon className="upload">
                                <img
                                    src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/22-01-2024/upload-cloud.svg"
                                    alt="upload-icon"
                                />
                            </Icon>
                            <AttachmentText>
                                <span>Click to upload</span>
                                or drag and drop SVG, PNG, JPG or MP4 (max.
                                25MB)
                            </AttachmentText>
                            <FileInput
                                type="file"
                                ref={fileInputRef}
                                accept="image/*,video/*"
                                onChange={handleFileChange}
                            />
                        </AttachmentDiv>
                    </AttachmentSection>
                    {selectedAttachment?.length > 0 && (
                        <UploadedContainer>
                            {selectedAttachment.map((attachment, index) => (
                                <UploadFile key={index}>
                                    <UploadContent>
                                        <Left>
                                            {attachment?.file?.type?.split(
                                                "/"
                                            )[0] === "video" ? (
                                                <Icon className="file-icon">
                                                    <small>
                                                        {
                                                            attachment?.file?.type?.split(
                                                                "/"
                                                            )[1]
                                                        }
                                                    </small>
                                                    <img
                                                        src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/05-02-2024/blue-icon.svg"
                                                        alt="file-icon"
                                                    />
                                                </Icon>
                                            ) : (
                                                <Icon className="file-icon">
                                                    <small>
                                                        {getAttachmentType(
                                                            attachment
                                                        )}
                                                    </small>
                                                    <img
                                                        src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/05-02-2024/green-icon.svg"
                                                        alt="file-icon"
                                                    />
                                                </Icon>
                                            )}

                                            <UploadName>
                                                <Name>
                                                    {attachment.file?.name
                                                        ? attachment.file?.name
                                                              .length > 20
                                                            ? attachment.file?.name.slice(
                                                                  0,
                                                                  17
                                                              ) +
                                                              "..." +
                                                              attachment.file?.name.slice(
                                                                  -4
                                                              )
                                                            : attachment.file
                                                                  ?.name
                                                        : ""}
                                                </Name>
                                                <Size>
                                                    {attachment.file?.size
                                                        ? formatFileSize(
                                                              attachment.file
                                                                  ?.size
                                                          )
                                                        : ""}
                                                </Size>
                                            </UploadName>
                                        </Left>
                                        <Right
                                            Clickable={true}
                                            onClick={() => {
                                                removeFile(attachment);
                                            }}
                                        >
                                            <Icon>
                                                <img
                                                    src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/09-02-2024/trash-01.svg"
                                                    alt="Delete"
                                                />
                                            </Icon>
                                        </Right>
                                    </UploadContent>

                                    <ProgressBarContainer>
                                        <MainProgressDiv>
                                            {index ===
                                            selectedAttachment.length - 1 ? (
                                                <ProgressBar
                                                    progress={
                                                        attachment.uploaded
                                                            ? 100
                                                            : uploadProgress
                                                    }
                                                />
                                            ) : (
                                                <ProgressBar />
                                            )}
                                        </MainProgressDiv>

                                        {index ===
                                        selectedAttachment.length - 1 ? (
                                            <UploadCount>
                                                {attachment.uploaded
                                                    ? 100
                                                    : uploadProgress
                                                    ? uploadProgress
                                                    : 0}
                                                %
                                            </UploadCount>
                                        ) : (
                                            <UploadCount>100%</UploadCount>
                                        )}
                                        <>
                                            {(attachment.uploaded ||
                                                uploadProgress === 100) && (
                                                <Right Clickable={false}>
                                                    <Icon>
                                                        <img
                                                            src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/22-01-2024/round-tick.svg"
                                                            alt="Round-tick"
                                                        />
                                                    </Icon>
                                                </Right>
                                            )}
                                        </>
                                    </ProgressBarContainer>
                                </UploadFile>
                            ))}
                        </UploadedContainer>
                    )}
                    <HrLine />
                    <QaContainer>
                        <Label>QA comment</Label>
                        <QaInput
                            maxLength={maxCharacters}
                            value={qaComment}
                            onChange={(e) => handleChange(e, "qa")}
                        />
                        <BottomText isOverLimit={isQaOverLimit}>
                            {qaCharactersLeft
                                ? qaCharactersLeft
                                : maxCharacters}{" "}
                            characters left
                        </BottomText>
                    </QaContainer>
                </Top>
                <Bottom>
                    <HrLine />
                    <p className="error">{error ? error : ""}</p>

                    <ButtonContainer>
                        <Button
                            onClick={() => {
                                handleClear();
                                setReLoad(!reLoad);
                            }}
                        >
                            Cancel
                        </Button>

                        <Button
                            className={"add "}
                            onClick={() => {
                                postBug();
                            }}
                        >
                            {loading ? (
                                <ButtonLoader width={25} height={25} />
                            ) : editingIssue ? (
                                "Update"
                            ) : (
                                "Add bug"
                            )}
                        </Button>
                    </ButtonContainer>
                </Bottom>
            </ContainerDiv>
        </MainContainer>
    );
}

export default ProjectAddModal;

const Overlay = styled.div`
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(2px);
    position: fixed;
    z-index: 10;
    left: 0;
    top: 0px;
    width: 100%;
    z-index: 1000;
    min-height: 100vh;
    max-height: 100vh;
    filter: blur(1px);
`;
const MainContainer = styled.div`
    /* padding: 24px; */
`;
const ContainerDiv = styled.div`
    position: fixed;
    z-index: 1001;
    right: -555px;
    top: 0;
    min-height: 100vh;
    max-height: 100vh;
    min-width: 555px;
    max-width: 555px;
    transition: all ease 200ms;
    padding: 24px;
    overflow-y: scroll;
    &::-webkit-scrollbar {
        display: none;
    }
    &.active {
        right: 0;
    }
    border-left: 1px solid #2c2c2c;
    background: #1b1c1f;
    box-shadow: 0px 8px 8px -4px rgba(16, 24, 40, 0.03),
        0px 20px 24px -4px rgba(16, 24, 40, 0.08);
    p.error {
        /* position: absolute; */
        color: red;
        font-size: 14px;
        text-align: right;
        margin-bottom: 10px;
    }
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;
const ContainerHead = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 4px;
`;

const HeadText = styled.h4`
    color: #fff;
    font-size: 20px;
    font-family: "gordita_medium";
`;
const SubText = styled.h4`
    color: #fe673a;
    font-size: 15px;
    font-family: "gordita_medium";
`;
const HeadIcon = styled.span`
    display: inline-block;
    cursor: pointer;
    width: 20px;
    img {
        display: block;
        width: 100%;
    }
`;
const TimeAndDate = styled.div`
    font-size: 14px;
    color: #fff;
    font-family: "gordita_regular";
    opacity: 0.6;
    margin-bottom: 24px;
`;

const MainHead = styled.div`
    color: #fe673a;
    font-size: 14px;
    font-family: "gordita_medium";
    margin-bottom: 16px;
    border-bottom: 2px solid #fe673a;
    background: #440607;
    display: flex;
    padding: 12px;
    justify-content: center;
    align-items: center;
    margin-bottom: 32px;
`;

const StatusContainer = styled.div`
    position: relative;
    margin-bottom: 28px;
`;

const Label = styled.div`
    color: #94a3b8;
    font-size: 14px;
    font-family: "gordita_medium";
    margin-bottom: 6px;
`;

const Small = styled.small`
    color: #cc1515;
    font-size: 16px;
    display: inline-block;
`;

const StatusDiv = styled.div`
    border-radius: 8px;
    border: 1px solid #3d3d3d;
    background: #161619;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 14px 18px;
    height: 48px;
    cursor: pointer;
`;

const ValueDiv = styled.div`
    border-radius: 6px;
    /* border: ${({ bugColor }) =>
        bugColor ? `1px solid ${bugColor}` : "none"}; */
    border: 1px solid #fff;
    background: ${({ bugColor }) => (bugColor ? "#161619" : "none")};
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    display: flex;
    height: 24px;
    padding: 2px 8px;
    align-items: center;
`;
const Icon = styled.span`
    display: inline-block;
    position: relative;
    width: 20px;
    &.upload {
        width: 40px;
        height: 40px;
        padding: 10px;
        border-radius: 8px;
        border: 1px solid #3d3d3d;
        background: #161619;
    }
    &.file-icon {
        width: 40px;
    }
    img {
        width: 100%;
        display: block;
    }
    small {
        position: absolute;
        text-transform: uppercase;
        bottom: 5px;
        left: 50%;
        font-size: 10px;
        font-family: "gordita_medium";
        transform: translateX(-50%);
        color: #fff;
    }
`;
const HrLine = styled.hr`
    background: #2c2c2c;
    height: 1px;
    margin: 20px 0;
    width: 100%;
`;
const Top = styled.div``;
const Bottom = styled.div``;

const ValueText = styled.span`
    color: #d0d5dd;
    font-size: 14px;
    font-family: "gordita_regular";
    margin-left: 4px;
    display: inline-block;
`;

const Dot = styled.span`
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: ${({ bugColor }) => (bugColor ? `${bugColor}` : "none")};
    display: inline-block;
    margin-right: 8px;
`;

const StatusDropDiv = styled.div`
    border-radius: 6px;
    border: 1px solid #3d3d3d;
    background: #1b1c1f;
    box-shadow: rgba(16, 24, 40, 0.05) 0px 1px 2px 0px;
    position: absolute;
    width: 100%;
    top: 77px;
    z-index: 999;
    &.active {
        display: block;
    }
`;
const StatusUl = styled.ul``;
const StatusLi = styled.li`
    border-bottom: 1px solid #3d3d3d;
    padding: 10px 18px;
    color: #d0d5dd;
    &:hover {
        color: #fff;
        cursor: pointer;
        background-color: #3d3d3d;
    }
    &:last-child {
        border-bottom: none;
    }
`;

const QaContainer = styled.div``;

const QaInput = styled.textarea`
    border-radius: 8px;
    border: 1px solid #3d3d3d;
    background: #161619;
    min-height: 120px;
    padding: 8px;
    width: 100%;
    color: #fff;
    resize: none;
    font-size: 16px;
    font-family: "gordita_regular";
    &::-webkit-scrollbar {
        display: none;
    }
`;

const BottomText = styled.small`
    display: inline-block;
    color: ${(props) => (props.isOverLimit ? "red" : "#fff")};
    font-size: 12px;
    font-family: "gordita_regular";
`;

const ScreenContainer = styled.div`
    position: relative;
`;

const ScreenInput = styled(QaInput)``;

const ScreenDiv = styled.div`
    margin-bottom: 28px;
    position: relative;
`;
const ErrorMessage = styled.p`
    right: 0px;
    position: absolute;
    bottom: -23px;
    small {
        color: red;
        font-size: 12px;
        font-family: "gordita_regular";
    }
`;

const ScreenDivInput = styled.input`
    border-radius: 8px;
    border: 1px solid #3d3d3d;
    background: #161619 !important;
    min-height: 48px;
    padding: 8px;
    width: 100%;
    color: #fff;
    resize: none;
    font-size: 16px;
    font-family: "gordita_regular";
    &::-webkit-scrollbar {
        display: none;
    }
`;

const SeveritySection = styled(StatusContainer)``;
const SeverityDiv = styled(StatusDiv)``;
const SeverityValue = styled(ValueDiv)`
    color: ${(props) => (props.severityColor ? props.severityColor : "")};
    border-radius: 16px;
    font-size: 14px;
    border: ${(props) =>
        props.severityColor ? `1.5px solid ${props.severityColor}` : "none"};
    background: ${(props) => (props.severityColor ? "#161619" : "none")};
`;
const AttachmentSection = styled.div`
    cursor: pointer;
    margin-bottom: 20px;
`;
const AttachmentDiv = styled.div`
    border-radius: 12px;
    border: 1px solid #3d3d3d;
    background: #161619;
    display: flex;
    padding: 16px 24px;
    flex-direction: column;
    align-items: center;
    .drag-active {
        &:hover {
            filter: blur(5px);
            transition: filter 0.3s ease;
        }
    }
`;
const AttachmentText = styled.div`
    width: 50%;
    margin-top: 12px;
    font-size: 12px;
    color: #94a3b8;
    font-family: "gordita_regular";
    line-height: 18px;

    span {
        font-family: "gordita_medium";
        color: #fe673a;
        font-size: 14px;
        display: inline-block;
        margin-right: 4px;
        color: #fff;
    }
`;
const FileInput = styled.input`
    display: none;
`;

const UploadedContainer = styled.div`
    margin-bottom: 20px;
`;

const UploadFile = styled.div`
    border-radius: 12px;
    border: 1px solid #3d3d3d;
    background: #161619;
    padding: 16px 24px;
    margin-bottom: 10px;
    :last-child {
        margin-bottom: 0;
    }
`;

const UploadContent = styled.div`
    margin-top: 12px;
    font-size: 12px;
    color: #94a3b8;
    font-family: "gordita_regular";
    line-height: 18px;
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
`;
const Left = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    position: relative;
`;
const Right = styled.div`
    cursor: ${({ Clickable }) => Clickable && "pointer"};
`;

const UploadName = styled.div`
    margin-left: 10px;
`;

const Name = styled.div`
    color: #cbd5e1;
    font-size: 14px;
    font-family: "gordita_medium";
`;

const Size = styled.div`
    color: #94a3b8;
    font-family: "gordita_regular";
    font-size: 14px;
`;
const UploadCount = styled.div`
    /* transform: translate(-50%, -50%); */
    color: #94a3b8;
    font-family: "gordita_regular";
    font-size: 14px;
`;
const ProgressBarContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const ProgressBar = styled.div`
    height: 100%;
    width: ${(props) => props.progress}%;
    background-color: #fe673a;
    border-radius: 5px;
    transition: width 0.3s ease;
`;

const MainProgressDiv = styled.div`
    width: 82%;
    height: 10px;
    background-color: #cbd5e1;
    border-radius: 5px;
    overflow: hidden;
`;

const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
`;
const Button = styled.button`
    border-radius: 6px;
    border: 1px solid #d0d5dd;
    background: #fff;
    color: #344054;
    font-size: 14px;
    font-family: "gordita_medium";
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    padding: 10px 16px;
    margin-right: 10px;
    width: 20%;
    height: 40px;
    cursor: pointer;
    &:last-child {
        margin-right: 0;
    }
    &.add {
        background: #fe673a;
        color: #fff;
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        border: none;
    }
    &.inactive {
        cursor: not-allowed;
        opacity: 0.5;
    }
`;

const SecurityDropDiv = styled(StatusDropDiv)``;
const SecurityUl = styled(StatusUl)``;
const SecurityLi = styled(StatusLi)``;
