import React, { useEffect, useState } from "react";
import styled from "styled-components";
import moment from "moment/moment";
import ReactPaginate from "react-paginate";
import SectionLoader from "../../helpers/SectionLoader";
import Avatar from "react-avatar";
import ButtonLoader from "../../genaral/Loader/ButtonLoader";

function EvaluvationTable({
    setModal,
    setPause,
    setView,
    studentData,
    setBugOpen,
    bugOpen,
    setStudentPk,
    setProjectName,
    setSearchParams,
    pagination,
    setSearchWord,
    searchWord,
    handlePageClick,
    setModalType,
    bugsList,
    setPkValue,
    sectionLoader,
    bugCreate,
    viewIsuueDetails,
    buttonLoader,
    pauseProject,
    setBugEditData,
    editingIssue,
    setEditingIssue,
}) {
    useEffect(() => {
        if (studentData.evaluations?.length > 0) {
            setBugOpen(studentData.evaluations[0]?.id);
            setPkValue(studentData.evaluations[0]?.id);
        }
    }, [studentData.evaluations]);

    const ActionButtonClick = (e, issue) => {
        e.preventDefault();
        viewIsuueDetails(issue.id);

        if (issue?.developer_status === null) {
            setModal(true);
            setBugEditData(issue);
            setEditingIssue(true);
        } else if (issue?.status === "done") {
            e.preventDefault();
            setView(true);
        } else {
            setView(true);
        }
    };
    return (
        // <MainContainer>
        //     <ContainerDiv>
        <>
            {studentData.evaluations?.map((item, index) => (
                <Container
                    className={bugOpen === item.id ? "open" : ""}
                    key={index}
                >
                    <HedingContainer
                        onClick={() => {
                            if (bugOpen === item.id) {
                                return;
                            }
                            setBugOpen(item.id);
                            setPkValue(item.id);
                        }}
                    >
                        {item?.is_completed === false ? (
                            <HeadingRight>
                                <HeadingText>
                                    Evaluation{" "}
                                    {item.evaluation_count
                                        ? item.evaluation_count
                                        : "--"}
                                </HeadingText>
                                <HrLine></HrLine>
                                <HeadingButtonContainer>
                                    <Pause
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setPause(true);

                                            setStudentPk(
                                                studentData?.student_project
                                                    ?.student?.student_pk
                                            );
                                            setProjectName(
                                                studentData?.student_project?.id
                                            );
                                            item.is_paused
                                                ? pauseProject("restarted")
                                                : setModalType("pause");
                                        }}
                                    >
                                        {item.is_paused ? "Restart" : "Pause"}
                                    </Pause>
                                    {!item?.is_paused && (
                                        <Complete
                                            onClick={() => {
                                                setModalType("success");
                                                setPause(true);
                                                setStudentPk(
                                                    studentData?.student_project
                                                        ?.student?.student_pk
                                                );
                                                setProjectName(
                                                    studentData?.student_project
                                                        ?.id
                                                );
                                            }}
                                        >
                                            Complete Evaluation
                                        </Complete>
                                    )}
                                </HeadingButtonContainer>
                            </HeadingRight>
                        ) : (
                            <HeadingRight>
                                <HeadingText>
                                    Evaluation{" "}
                                    {item.evaluation_count
                                        ? item.evaluation_count
                                        : "--"}
                                </HeadingText>
                                <HrLine></HrLine>
                            </HeadingRight>
                        )}
                        <Headingleft
                            onClick={() => {
                                item.id === bugOpen
                                    ? setBugOpen("")
                                    : setBugOpen(item.id);
                            }}
                        >
                            <DownIcon
                                className={bugOpen === item.id && "rotate"}
                            >
                                <Icon
                                    src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/19-01-2024/chevron-down.svg "
                                    alt="downIcon"
                                />
                            </DownIcon>
                        </Headingleft>
                    </HedingContainer>
                    <FormHead>
                        <SearchContainer>
                            <Label>Search</Label>
                            <InputConatainer>
                                <DownIcon>
                                    <Icon
                                        src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/yiaai/01-02-2022/images/tefun/search-icon.svg"
                                        alt="SearchIcon"
                                    />
                                </DownIcon>
                                <Input
                                    type="text"
                                    placeholder="Search for screen, student etc.."
                                    value={searchWord}
                                    onChange={(e) => {
                                        setSearchWord(e.target.value);
                                        setSearchParams({
                                            q: e.target.value,
                                        });
                                    }}
                                />
                            </InputConatainer>
                        </SearchContainer>
                        {item?.is_paused === false && (
                            <BugButton
                                onClick={() => {
                                    bugCreate(item.id, "open", "");
                                    setEditingIssue(true);
                                }}
                            >
                                {buttonLoader ? (
                                    <ButtonLoader width={28} height={28} />
                                ) : (
                                    " Add new bug"
                                )}
                            </BugButton>
                        )}
                    </FormHead>
                    <FormBody>
                        <TableHead>
                            <TableHeadList>
                                <TableHeadItem type="number">
                                    Bug ID{" "}
                                    {/* <TableHeadIcon>
                                        <TableDownArrowIcon
                                            src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/19-01-2024/arrow-down.svg "
                                            alt="ArrowIcon"
                                        />
                                    </TableHeadIcon> */}
                                </TableHeadItem>
                                <TableHeadItem type="screen">
                                    Screen{" "}
                                    {/* <TableHeadIcon>
                                        <TableDownArrowIcon
                                            src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/19-01-2024/chevron-down-1.svg "
                                            alt="ArrowIcon"
                                        />
                                    </TableHeadIcon> */}
                                </TableHeadItem>
                                <TableHeadItem type="bugstatus">
                                    Bug status{" "}
                                    {/* <TableHeadIcon>
                                        <TableDownArrowIcon
                                            src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/19-01-2024/chevron-down-1.svg "
                                            alt="ArrowIcon"
                                        />
                                    </TableHeadIcon> */}
                                </TableHeadItem>
                                <TableHeadItem type="devstatus">
                                    Dev status
                                </TableHeadItem>
                                {/* <TableHeadItem type="attachment">
                                    Attachment
                                    // <TableHeadIcon>
                                    //     <TableDownArrowIcon
                                    //         src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/19-01-2024/chevron-down-1.svg "
                                    //         alt="ArrowIcon"
                                    //     />
                                    // </TableHeadIcon>
                                </TableHeadItem> */}
                                <TableHeadItem type="date">
                                    Date & time{" "}
                                    {/* <TableHeadIcon>
                                        <TableDownArrowIcon
                                            src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/19-01-2024/chevron-down-1.svg "
                                            alt="ArrowIcon"
                                        />
                                    </TableHeadIcon> */}
                                </TableHeadItem>
                                <TableHeadItem type="reportedby">
                                    Reported by
                                    {/* <TableHeadIcon>
                                        <TableDownArrowIcon
                                            src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/19-01-2024/chevron-down-1.svg "
                                            alt="ArrowIcon"
                                        />
                                    </TableHeadIcon> */}
                                </TableHeadItem>
                                <TableHeadItem type="severity">
                                    Severity
                                    {/* <TableHeadIcon>
                                        <TableDownArrowIcon
                                            src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/19-01-2024/chevron-down-1.svg"
                                            alt="ArrowIcon"
                                        />
                                    </TableHeadIcon> */}
                                </TableHeadItem>
                                <TableHeadItem type="action">
                                    Action
                                    {/* <TableHeadIcon>
                                        <TableDownArrowIcon
                                            src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/19-01-2024/chevron-down-1.svg "
                                            alt="ArrowIcon"
                                        />
                                    </TableHeadIcon> */}
                                </TableHeadItem>
                            </TableHeadList>
                        </TableHead>
                        <>
                            {sectionLoader ? (
                                <SectionLoader status={"followup_executive"} />
                            ) : (
                                <>
                                    {bugsList?.length > 0 ? (
                                        <>
                                            {bugsList.map((issue, index) => (
                                                <TableBody key={index}>
                                                    <TalbleList>
                                                        <TableItem
                                                            className="issueId"
                                                            type="number"
                                                        >
                                                            {issue.issue_id ??
                                                                "--"}
                                                            {issue.parent_issue_id && (
                                                                <SpanNumber>
                                                                    Parent:
                                                                    {
                                                                        issue.parent_issue_id
                                                                    }
                                                                </SpanNumber>
                                                            )}
                                                        </TableItem>
                                                        <TableItem
                                                            type="screen"
                                                            className="screen"
                                                        >
                                                            <ScreenName>
                                                                {issue.title ??
                                                                    "--"}
                                                            </ScreenName>
                                                            <ScreenText>
                                                                {issue.description ??
                                                                    "--"}
                                                            </ScreenText>
                                                        </TableItem>

                                                        <TableItem type="bugstatus">
                                                            <BugContainer>
                                                                <BugIcon
                                                                    securityColor={
                                                                        issue.status
                                                                    }
                                                                ></BugIcon>
                                                                <BugText>
                                                                    {issue.status
                                                                        ? issue.status
                                                                              .replace(
                                                                                  /_/g,
                                                                                  " "
                                                                              )
                                                                              .charAt(
                                                                                  0
                                                                              )
                                                                              .toUpperCase() +
                                                                          issue.status
                                                                              .replace(
                                                                                  /_/g,
                                                                                  " "
                                                                              )
                                                                              .slice(
                                                                                  1
                                                                              )
                                                                        : "--"}
                                                                </BugText>
                                                            </BugContainer>
                                                        </TableItem>

                                                        <TableItem type="devstatus">
                                                            <DevStatus>
                                                                {issue.developer_status
                                                                    ? issue.developer_status
                                                                          .replace(
                                                                              /_/g,
                                                                              " "
                                                                          )
                                                                          .charAt(
                                                                              0
                                                                          )
                                                                          .toUpperCase() +
                                                                      issue.developer_status
                                                                          .replace(
                                                                              /_/g,
                                                                              " "
                                                                          )
                                                                          .slice(
                                                                              1
                                                                          )
                                                                    : "--"}
                                                            </DevStatus>
                                                        </TableItem>

                                                        {/* <TableItem type="attachment">
                                                            <Attachment>
                                                                View
                                                            </Attachment>
                                                        </TableItem> */}

                                                        <TableItem
                                                            type="date"
                                                            className="screen"
                                                        >
                                                            <Date>
                                                                {issue?.date_added
                                                                    ? moment(
                                                                          issue?.date_added
                                                                      ).format(
                                                                          "MMM DD"
                                                                      )
                                                                    : "--"}
                                                            </Date>
                                                            <Time>
                                                                {issue?.date_added
                                                                    ? moment(
                                                                          issue?.date_added
                                                                      ).format(
                                                                          "LT"
                                                                      )
                                                                    : "--"}
                                                            </Time>
                                                        </TableItem>

                                                        <TableItem type="reportedby">
                                                            <IconImage>
                                                                {issue
                                                                    ?.reported_by
                                                                    ?.photo ? (
                                                                    <img
                                                                        src={
                                                                            issue
                                                                                ?.reported_by
                                                                                ?.photo
                                                                        }
                                                                        alt="avathar icon"
                                                                    />
                                                                ) : (
                                                                    <Avatar
                                                                        name={
                                                                            issue
                                                                                ?.reported_by
                                                                                ?.name
                                                                        }
                                                                        size="40"
                                                                        round
                                                                    />
                                                                )}
                                                            </IconImage>

                                                            <ReportContainer>
                                                                <DevName>
                                                                    {issue
                                                                        ?.reported_by
                                                                        ?.name ??
                                                                        "--"}
                                                                </DevName>
                                                                <DevPosition>
                                                                    Senior QA
                                                                </DevPosition>
                                                            </ReportContainer>
                                                        </TableItem>

                                                        <TableItem type="severity">
                                                            <ServityBox
                                                                securityColor={
                                                                    issue.severity
                                                                }
                                                            >
                                                                {issue.severity ??
                                                                    "--"}
                                                            </ServityBox>
                                                        </TableItem>

                                                        <TableItem type="action">
                                                            <ActionButton
                                                                onClick={(
                                                                    e
                                                                ) => {
                                                                    ActionButtonClick(
                                                                        e,
                                                                        issue
                                                                    );
                                                                }}
                                                            >
                                                                View details
                                                            </ActionButton>
                                                        </TableItem>
                                                    </TalbleList>
                                                </TableBody>
                                            ))}
                                        </>
                                    ) : (
                                        <TableBody>
                                            <TextContainer>
                                                <EvaluvationParagraph>
                                                    You have not added any bug.
                                                    Check the repository and
                                                    start the testing.
                                                </EvaluvationParagraph>
                                                <LinkContainer>
                                                    <Link
                                                        href={
                                                            studentData
                                                                ?.student_project
                                                                ?.repo_url
                                                        }
                                                        target="_blank"
                                                    >
                                                        View repository link
                                                    </Link>
                                                    <small>OR</small>
                                                    <Link
                                                        onClick={() => {
                                                            bugCreate(
                                                                item.id,
                                                                "open",
                                                                ""
                                                            );
                                                        }}
                                                    >
                                                        Add new bug
                                                    </Link>
                                                </LinkContainer>
                                            </TextContainer>
                                        </TableBody>
                                    )}
                                </>
                            )}
                            {pagination?.total_pages > 1 && (
                                <PaginationContainer>
                                    <PaginationText>
                                        {pagination?.last_item} of{" "}
                                        {pagination?.total_items}
                                    </PaginationText>
                                    <ReactPaginate
                                        previousLabel={"Previous"}
                                        nextLabel={"Next"}
                                        pageCount={pagination?.total_pages}
                                        onPageChange={handlePageClick}
                                        containerClassName={"pagination"}
                                        previousLinkClassName={
                                            "pagination__link"
                                        }
                                        nextLinkClassName={"pagination__link"}
                                        disabledClassName={
                                            "pagination__link--disabled"
                                        }
                                        activeClassName={
                                            "pagination__link--active"
                                        }
                                    />
                                </PaginationContainer>
                            )}
                        </>
                    </FormBody>
                </Container>
            ))}
        </>
        //     </ContainerDiv>
        // </MainContainer>
    );
}

export default EvaluvationTable;

const MainContainer = styled.div`
    width: 100%;
    overflow-x: scroll;
    &::-webkit-scrollbar {
        display: none;
    }
`;
const ContainerDiv = styled.div`
    /* min-width: 1620px; */
`;
const Container = styled.div`
    border-radius: 8px;
    border: 1px solid #334155;
    box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03),
        0px 12px 16px -4px rgba(16, 24, 40, 0.08);
    margin-bottom: 24px;
    height: 85px;
    overflow: hidden;
    &:last-child {
        margin-bottom: 0;
    }
    &.open {
        height: auto;
        overflow: unset;
    }
`;
const HedingContainer = styled.div`
    border-radius: 8px;
    background: #1b1c1f;
    padding: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
`;
const HeadingText = styled.h4`
    font-size: 24px;
    font-family: "gordita_medium";
    color: #fff;
`;
const HeadingButtonContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
`;
const Pause = styled.button`
    border-radius: 6px;
    border: 1px solid #d0d5dd;
    background: #fff;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    display: flex;
    height: 40px;
    padding: 10px 16px;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: #344054;
    font-family: "gordita_regular";
    cursor: pointer;
`;
const Complete = styled.button`
    border-radius: 6px;
    background: #fe673a;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    display: flex;
    height: 40px;
    padding: 10px 16px;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 14px;
    font-family: "gordita_regular";
    cursor: pointer;
`;
const HrLine = styled.hr`
    background: #3e3d3d;
    height: 1px;
    margin: 0px 48px 0 48px;
    width: 50px;
    transform: rotate(90deg);
`;

const HeadingRight = styled.div`
    display: flex;
    align-items: center;
`;
const Headingleft = styled.div``;
const DownIcon = styled.span`
    width: 20px;
    display: inline-block;
    margin-right: 10px;
    transition: 100ms ease-in all;
    /* transform: ${({ rotate }) =>
        rotate ? "rotate(0deg)" : "rotate(180deg)"}; */
    cursor: pointer;
    &.rotate {
        transform: rotate(180deg);
    }
`;
const Icon = styled.img`
    width: 100%;
    display: block;
`;

const FormHead = styled.div`
    /* border-radius: 4px; */
    padding: 16px;
    border: 1px solid #2c2c2c;
    background: #1b1c1f;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
const SearchContainer = styled.div`
    display: flex;
    width: 350px;
    flex-direction: column;
    align-items: flex-start;
    gap: 6px;
`;
const Label = styled.label`
    color: #c9c9c9;
    font-size: 14px;
    font-family: "gordita_regular";
`;
const InputConatainer = styled.div`
    border-radius: 6px;
    border: 1px solid #3d3d3d;
    background: #161619;
    padding: 8px 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
`;

const Input = styled.input`
    color: #fff;
    font-size: 16px;
    width: 100%;
    &::placeholder {
        color: #4f5360;
        font-size: 14px;
    }
`;
const FormBody = styled.div`
    min-height: 30vh;
    background: #1b1c1f;
    border-radius: 8px;
`;

const TableHead = styled.div`
    padding: 16px 24px;
    border-bottom: 1px solid #2c2c2c;
    background: #1b1c1f;
`;

const TableHeadList = styled.ul`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const TableHeadItem = styled.li`
    font-size: 14px;
    font-family: "gordita_medium";
    color: #7b7b7b;
    display: flex;
    align-items: center;
    width: ${({ type }) =>
        type === "number"
            ? "8%"
            : type === "screen"
            ? "13%"
            : type === "bugstatus"
            ? "9%"
            : type === "devstatus"
            ? "10%"
            : type === "attachment"
            ? "8%"
            : type === "date"
            ? "10%"
            : type === "reportedby"
            ? "19%"
            : type === "severity"
            ? "8%"
            : type === "action"
            ? "10%"
            : ""};
    &:last-child {
        justify-content: end;
    }
`;

const TableHeadIcon = styled.span`
    display: inline-block;
    width: 14px;
    margin-left: 8px;
`;

const TableDownArrowIcon = styled.img`
    display: block;
    width: 100%;
`;

const TableBody = styled.div`
    padding: 16px 24px;
    border-bottom: 1px solid #2c2c2c;
    background: #1b1c1f;
    border-radius: 8px;
    &:last-child {
        border-bottom: none;
    }
`;

const TalbleList = styled.ul`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
const TableItem = styled.li`
    font-size: 14px;
    font-family: "gordita_medium";
    color: #d2caca;
    display: flex;
    align-items: center;
    width: ${({ type }) =>
        type === "number"
            ? "8%"
            : type === "screen"
            ? "13%"
            : type === "bugstatus"
            ? "9%"
            : type === "devstatus"
            ? "10%"
            : type === "attachment"
            ? "8%"
            : type === "date"
            ? "10%"
            : type === "reportedby"
            ? "19%"
            : type === "severity"
            ? "8%"
            : type === "action"
            ? "10%"
            : ""};
    &.issueId {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
    }

    &.screen {
        flex-direction: column;
        align-items: flex-start;
    }
    &:last-child {
        justify-content: end;
    }
`;

const SpanNumber = styled.span`
    color: #fff;
    font-size: 10px;
    font-family: "gordita_regular";
`;

const ScreenName = styled.h4`
    color: #fff;
    font-size: 14px;
    font-family: "gordita_medium";
    margin-bottom: 8px;
`;
const ScreenText = styled.h5`
    font-family: "gordita_regular";
    color: #d2caca;
    font-size: 12px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    max-width: 180px;
    word-break: break-word;
`;

const BugContainer = styled.div`
    border-radius: 6px;
    border: 1px solid #d0d5dd;
    background: #1b1c1f;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    display: flex;
    padding: 4px 6px;
    align-items: center;
`;
const BugIcon = styled.span`
    width: 8px;
    height: 8px;
    border-radius: 50%;
    display: inline-block;
    background: ${({ securityColor }) =>
        securityColor === "open"
            ? "#2E90FA"
            : securityColor === "done"
            ? "#16B364"
            : securityColor === "re_open"
            ? "#F79009"
            : "#2E90FA"};
`;
const BugText = styled.h5`
    color: #d0d5dd;
    font-family: "gordita_regular";
    font-size: 14px;
    margin-left: 8px;
    text-transform: capitalize;
`;

const BugButton = styled.button`
    font-size: 16px;
    font-family: "gordita_regular";
    color: #fff;
    display: flex;
    padding: 8px 18px;
    height: 44px;
    width: 150px;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    background: #fe673a;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    cursor: pointer;
`;

const DevStatus = styled.div`
    color: #d0d5dd;
    display: flex;
    text-transform: capitalize;
    padding: 8px 12px;
    align-items: center;
    border-radius: 6px;
    border: 1px solid #d0d5dd;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    height: 32px;
`;

const Attachment = styled.button`
    color: #fe673a;
    font-size: 14px;
    font-family: "gordita_regular";
    border: none;
    background: none;
    cursor: pointer;
`;

const Date = styled.h5`
    color: #fff;
    font-size: 14px;
    font-family: "gordita_medium";
    margin-bottom: 3px;
`;
const Time = styled(Date)`
    color: #d2caca;
    font-family: "gordita_regular";
`;
const IconImage = styled.div`
    width: 40px;
    height: 40px;
    overflow: hidden;
    border-radius: 50%;
    margin-right: 10px;
    img {
        display: block;
        width: 100%;
    }
`;

const ReportContainer = styled.div``;

const DevName = styled.h5`
    font-size: 14px;
    color: #fff;
    font-family: "gordita_medium";
`;
const DevPosition = styled.h5`
    color: #fe673a;
    font-size: 14px;
    font-family: "gordita_regular";
`;

const ServityBox = styled.button`
    color: ${({ securityColor }) =>
        securityColor === "low"
            ? "#087443"
            : securityColor === "medium"
            ? "#B54708"
            : securityColor === "high"
            ? "#B42318"
            : "#fff"};
    font-size: 14px;
    font-family: "gordita_medium";
    padding: 2px 10px;
    border-radius: 16px;
    text-transform: capitalize;
    border: 1.5px solid
        ${({ securityColor }) =>
            securityColor === "low"
                ? "#087443"
                : securityColor === "medium"
                ? "#B54708"
                : securityColor === "high"
                ? "#B42318"
                : "#fff"};
    /* cursor: pointer; */
`;
const ActionButton = styled.button`
    border-radius: 6px;
    border: 1px solid #3b3b3b;
    background: #1b1c1f;
    padding: 11px 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fe673a;
    font-size: 12px;
    font-family: "gordita_medium";
    cursor: pointer;
`;

const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 20vh;
`;

const LinkContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
    margin-top: 10px;
    small {
        color: #cbd5e1;
    }
`;

const EvaluvationParagraph = styled.p`
    color: #fff;
    font-size: 1rem;
    font-family: "gordita_regular";
    width: 20%;
    text-align: center;
`;
const Link = styled.a`
    cursor: pointer;
    color: #fe673a;
    font-size: 1rem;
    font-family: "gordita_regular";
`;
const PaginationContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 40px 0 15px;
    @media all and (max-width: 890px) {
        flex-direction: column;
    }
`;
const PaginationText = styled.p`
    color: #898989;
    font-size: 14px;
    @media all and (max-width: 890px) {
        margin-bottom: 10px;
    }
`;
