import React, { useContext, useState } from "react";
import styled from "styled-components";
import { learnConfig } from "../../../../axiosConfig";
import { Context } from "../../../contexts/Store";
import ButtonLoader from "../../../genaral/Loader/ButtonLoader";

function ProjectCompleteModal({
    modal,
    setModal,
    buttonLoader,
    apiError,
    setApiError,
    projectComplete,
    setSelectedScore,
    selectedScore,
}) {
    const handleModal = () => {
        setModal(false);
        setSelectedScore("");
        setApiError("");
    };

    const score = [
        {
            id: 1,
            name: "1",
        },
        {
            id: 2,
            name: "2",
        },
        {
            id: 3,
            name: "3",
        },
        {
            id: 4,
            name: "4",
        },
        {
            id: 5,
            name: "5",
        },
        {
            id: 6,
            name: "6",
        },
        {
            id: 7,
            name: "7",
        },
        {
            id: 8,
            name: "8",
        },
        {
            id: 9,
            name: "9",
        },
        {
            id: 10,
            name: "10",
        },
    ];

    return (
        <MainContainer>
            {modal ? (
                <Overlay
                    onClick={() => setModal(false)}
                    style={{ display: modal ? "block" : "none" }}
                ></Overlay>
            ) : (
                ""
            )}
            <ContainerDiv style={{ transform: modal && "scale(1,1)" }}>
                <Modal>
                    <Container>
                        <Top>
                            <Head>
                                <Headtitle>
                                    Make sure the project is completed and give
                                    appropriate score for the developer.{" "}
                                    <SmallText>
                                        This action will complete the project
                                        and give ratings for the developer.{" "}
                                    </SmallText>
                                </Headtitle>
                                <Closeicon onClick={handleModal}>
                                    <img
                                        src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/05-04-2022/close.png"
                                        alt="Icon"
                                    />
                                </Closeicon>
                            </Head>
                        </Top>
                        <Body>
                            <ScreenContainer>
                                <Label>Give a score</Label>
                                <NumberCard>
                                    <NumberList>
                                        {score.map((score, index) => (
                                            <NuberItem
                                                key={index}
                                                isActive={
                                                    selectedScore === score.name
                                                }
                                                onClick={() =>
                                                    setSelectedScore(score.name)
                                                }
                                            >
                                                {score.name}
                                            </NuberItem>
                                        ))}
                                    </NumberList>
                                </NumberCard>
                            </ScreenContainer>
                        </Body>
                        {apiError && <ErrorSpan>{apiError?.message}</ErrorSpan>}
                        <ButtonDiv>
                            <Button
                                className="close"
                                onClick={() => {
                                    handleModal();
                                }}
                            >
                                Cancel
                            </Button>
                            <Button onClick={() => projectComplete()}>
                                {buttonLoader ? (
                                    <ButtonLoader />
                                ) : (
                                    "Yes, completed"
                                )}
                            </Button>
                        </ButtonDiv>
                    </Container>
                </Modal>
            </ContainerDiv>
        </MainContainer>
    );
}

export default ProjectCompleteModal;
const MainContainer = styled.div``;
const ContainerDiv = styled.div`
    position: fixed;
    transition: 0.3s;
    transform: scale(0, 0);
    width: 100%;
    height: 100vh;
    z-index: 1000;
    left: 0;
    top: 0px;
`;
const Overlay = styled.div`
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(2px);
    position: fixed;
    z-index: 10;
    left: 0;
    top: 0px;
    width: 100%;
    z-index: 1000;
    min-height: 100vh;
    max-height: 100vh;
    filter: blur(1px);
`;
const Modal = styled.div`
    width: 90%;
    max-width: 640px;
    max-height: 100vh;
    position: absolute;
    margin: 0 auto;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: 0.5s;
    z-index: 101;
    border: 1px solid #26272a;
    background: #1b1c1f;
    border-radius: 5px;
    overflow-y: hidden;
    box-shadow: 0px 3px 56px #00000048;

    @media all and (max-width: 1280px) {
        max-height: 93vh;
        overflow-y: scroll;
        &::-webkit-scrollbar {
            display: none;
        }
    }
    @media all and (max-width: 400px) {
        max-height: 70vh;
        overflow-y: scroll;
        &::-webkit-scrollbar {
            display: none;
        }
    }
`;
const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 40px 35px;
    width: 100%;
    height: 100%;
    @media all and (max-width: 768px) {
        padding: 30px;
    }
    @media all and (max-width: 480px) {
        width: 500px;
        overflow-x: scroll;
        &::-webkit-scrollbar {
            display: none;
        }
    }
    @media all and (max-width: 360px) {
        padding: 10px;
    }
`;
const Top = styled.div`
    border-bottom: 2px solid #26272a;
    margin-bottom: 15px;
    &.top {
        border-bottom: none;
        margin-bottom: 25px;
    }
`;
const Head = styled.div`
    display: flex;
    /* align-items: center; */
    justify-content: space-between;
    margin-bottom: 15px;
`;
const Headtitle = styled.p`
    font-size: 16px;
    color: #fff;
    font-family: "gordita_medium";
`;
const SmallText = styled.span`
    display: inline-block;
    font-size: 14px;
    color: #fff;
    font-family: "gordita_regular";
`;
const Closeicon = styled.div`
    width: 28px;
    cursor: pointer;
    img {
        width: 100%;
        display: block;
    }
`;

const ScreenContainer = styled.div``;
const Label = styled.div`
    color: #94a3b8;
    font-size: 14px;
    font-family: "gordita_medium";
    margin-bottom: 6px;
`;
const Body = styled.div`
    border-bottom: 2px solid #26272a;
    padding-bottom: 15px;
    margin-bottom: 15px;
`;

const ButtonDiv = styled.div`
    display: flex;
    flex-direction: row;
    gap: 25px;
    justify-content: flex-end;
    &.save {
        justify-content: flex-end;
    }
`;
const Button = styled.button`
    border-radius: 6px;
    background: #fe673a;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    font-size: 14px;
    display: flex;
    height: 40px;
    width: 120px;
    padding: 8px 0px;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-family: "gordita_medium";
    cursor: pointer;

    &.close {
        border-radius: 6px;
        border: 1px solid #e2e8f0;
        background: #fff;
        color: #0f172a;
    }
    &.save {
        width: 40px;
    }
    &.save_btn {
        width: 20%;
        height: 48px;
        padding: 8px 12px;
        font-size: 14px;
    }
`;
const ErrorSpan = styled.span`
    color: #f44336;
    font-size: 12px;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 4px;
`;

const NumberCard = styled.div``;

const NumberList = styled.ul`
    display: flex;
    justify-content: space-between;
`;
const NuberItem = styled.li`
    border-color: 1px solid ${({ isActive }) => (isActive ? "none" : "#e2e8f0")};
    background-color: ${({ isActive }) => (isActive ? "#FE673A" : "#e2e8f0")};
    width: 5%;
    border-radius: 6px;
    padding: 8px 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${({ isActive }) => (isActive ? "#fff" : "#0f172a")};
    font-size: 14px;
    font-family: "gordita_medium";
    cursor: pointer;
    &:hover {
        background-color: #fe673a;
        color: #fff;
        border: none;
    }
`;
