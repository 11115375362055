import React, { useContext, useEffect, useState } from "react";
import ProjectsAddingScreen from "../../includes/projects-section/ProjectsAddingScreen";
import ProjectAddHeader from "../../includes/projects-section/ProjectAddHeader";
import SteypHelmet from "../../helpers/SteypHelmet";
import { learnConfig } from "../../../axiosConfig";
import { Context } from "../../contexts/Store";
import { useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components";

function AddProjectScreen() {
    const [isDraftLoading, setDraftLoading] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const [selectedSkill, setSelectedSkill] = useState(null);
    const [dateToComplete, setDateToComplete] = useState(10);
    const [editorContent, setEditorContent] = useState({});
    const [draftMessage, setDraftMessage] = useState("");
    const [githubLink, setGithubLink] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [thumbnail, setThumbnail] = useState(null);
    const [figmaLink, setFigmaLink] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const [editerData, setEditorData] = useState();
    const [videoUrl, setVideoUrl] = useState("");
    const [assets, setAssets] = useState(null);
    const [orderID, setOrderID] = useState(null);
    const project_pk = searchParams.get("pk");
    const [skills, setSkills] = useState([]);
    const [newTag, setNewTag] = useState("");
    const [title, setTitle] = useState("");
    const [error, setError] = useState("");
    const [point, setPoint] = useState(10);
    const [tag, setTag] = useState([]);
    const [projectStatus, setProjectStatus] = useState("");
    const navigate = useNavigate();

    const currentParams = Object.fromEntries([...searchParams]);
    const handlePageClick = ({ selected: selectedPage }) => {
        setCurrentPage(selectedPage + 1);
        setSearchParams({
            ...currentParams,
            page: selectedPage + 1,
        });
    };

    const {
        state: {
            user_data: { access_token },
        },
    } = useContext(Context);

    //projects skills
    useEffect(() => {
        const fetcSkills = async () => {
            try {
                await learnConfig
                    .get("projects/skills/", {
                        headers: {
                            Authorization: `Bearer ${access_token}`,
                        },
                    })

                    .then((res) => {
                        let { StatusCode, data } = res.data;
                        if (StatusCode === 6000) {
                            setSkills(data);
                        } else {
                            console.log(data);
                        }
                    });
            } catch (error) {
                console.log(error);
            }
        };

        fetcSkills();
    }, []);

    //add new project
    const addNewProject = async (status) => {
        if (!title || !selectedSkill) {
            setError("Please fill all required fields.");
            return;
        }

        if (status === "draft") {
            setDraftLoading(true);
        } else {
            setLoading(true);
        }
        const formData = new FormData();
        formData.append(
            "description",
            project_pk
                ? JSON.stringify(editerData)
                : JSON.stringify(editorContent)
        );
        formData.append("skill", selectedSkill.id);
        formData.append("cover_video_url", videoUrl);
        formData.append("figma_url ", figmaLink);
        formData.append("git_url ", githubLink);
        formData.append("days_to_complete", dateToComplete);
        formData.append("asset_file", assets);
        formData.append("image", thumbnail);
        formData.append("total_points", point);
        formData.append("title", title);
        formData.append("order", orderID);
        formData.append("project_status", status);
        formData.append("tags", JSON.stringify(tag));
        if (project_pk) {
            formData.append("project_pk", project_pk);
        }

        try {
            await learnConfig
                .post("projects/update-draft/", formData, {
                    headers: {
                        Authorization: `Bearer ${access_token}`,
                    },
                })

                .then((res) => {
                    let { StatusCode, data, message } = res.data;
                    if (StatusCode === 6000) {
                        if (status === "publish") {
                            navigate("/projects/current/");
                        } else {
                            setDraftMessage(message?.message);
                            if (data?.id) {
                                setSearchParams({
                                    ...currentParams,
                                    pk: data?.id,
                                });
                            }
                        }
                        setLoading(false);
                        setDraftLoading(false);
                        setError("");
                    } else {
                        setError(message?.message);
                        setLoading(false);
                        setDraftLoading(false);
                    }
                });
        } catch (error) {
            setLoading(false);
            setDraftLoading(false);
            if (error.response.status === 400) {
                const { message, StatusCode } = error.response.data;
                console.log(message);
                setError(message?.message);
            }
            // console.log(error);
        }
    };

    // fetch project datas
    useEffect(() => {
        const fetchSingleProject = async () => {
            try {
                await learnConfig
                    .get(`projects/get-project/${project_pk}/`, {
                        headers: {
                            Authorization: `Bearer ${access_token}`,
                        },
                    })

                    .then((res) => {
                        let { StatusCode, data } = res.data;
                        if (StatusCode === 6000) {
                            setProjectStatus(data.status);
                            setSelectedSkill(data.skill);
                            setDateToComplete(data.days_to_complete);
                            setPoint(data.total_points);
                            setGithubLink(data.assets.git_url);
                            const imageUrl = data.image;
                            if (imageUrl) {
                                const parts = imageUrl.split("/");
                                const filename = parts[parts.length - 1];
                                setThumbnail({ name: filename });
                            }
                            setTag(data.tags);
                            setFigmaLink(data.assets.figma_url);
                            setVideoUrl(data.cover_video_url);
                            const assetsUrl = data.assets.asset_name;
                            if (assetsUrl) {
                                const assetParts = assetsUrl.split("/");
                                const assetFilename =
                                    assetParts[assetParts.length - 1];
                                setAssets({ name: assetFilename });
                            }
                            setOrderID(data.order);
                            setTitle(data.title);
                            setEditorData(
                                data === null
                                    ? ""
                                    : JSON.parse(data?.description)
                            );
                            setEditorContent(
                                data === null
                                    ? {}
                                    : JSON.parse(data?.description)
                            );
                        } else {
                            console.log(data);
                        }
                    });
            } catch (error) {
                console.log(error);
            }
        };
        if (project_pk) {
            fetchSingleProject();
        }
    }, [project_pk]);

    const getLatestOrderId = async (skillId) => {
        try {
            await learnConfig
                .get(`projects/get-latest-order-id/${skillId}/`, {
                    headers: {
                        Authorization: `Bearer ${access_token}`,
                    },
                })

                .then((res) => {
                    let { StatusCode, data } = res.data;
                    if (StatusCode === 6000) {
                        let orderId = data?.order_id;
                        orderId = parseInt(orderId);
                        setOrderID(orderId + 1);
                        // console.log(data);
                    } else {
                    }
                });
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <Container id="main">
            <MainContainer>
                <ContainerDiv>
                    <SteypHelmet title="Projects Add" />
                    <ProjectAddHeader />
                    <ProjectsAddingScreen
                        setEditorContent={
                            project_pk ? setEditorData : setEditorContent
                        }
                        setSelectedSkill={setSelectedSkill}
                        setGithubLink={setGithubLink}
                        editorContent={project_pk ? editerData : editorContent}
                        selectedSkill={selectedSkill}
                        addNewProject={addNewProject}
                        setThumbnail={setThumbnail}
                        setFigmaLink={setFigmaLink}
                        setVideoUrl={setVideoUrl}
                        setLoading={setLoading}
                        githubLink={githubLink}
                        isLoading={isLoading}
                        figmaLink={figmaLink}
                        thumbnail={thumbnail}
                        setSkills={setSkills}
                        setAssets={setAssets}
                        setTitle={setTitle}
                        videoUrl={videoUrl}
                        skills={skills}
                        assets={assets}
                        title={title}
                        tag={tag}
                        setTag={setTag}
                        newTag={newTag}
                        setNewTag={setNewTag}
                        error={error}
                        setError={setError}
                        setOrderID={setOrderID}
                        orderId={orderID}
                        setDraftLoading={setDraftLoading}
                        isDraftLoading={isDraftLoading}
                        draftMessage={draftMessage}
                        setDraftMessage={setDraftMessage}
                        dateToComplete={dateToComplete}
                        setDateToComplete={setDateToComplete}
                        point={point}
                        setPoint={setPoint}
                        projectStatus={projectStatus}
                        getLatestOrderId={getLatestOrderId}
                    />
                </ContainerDiv>
            </MainContainer>
        </Container>
    );
}

export default AddProjectScreen;
const MainContainer = styled.div`
    width: 100%;
    overflow-x: scroll;
    &::-webkit-scrollbar {
        display: none;
    }
`;
const ContainerDiv = styled.div`
    /* min-width: 1620px; */
    min-width: 1100px;
`;
const Container = styled.div``;
const HrLine = styled.hr`
    background: #28292d;
    height: 1px;
    margin: 24px 0;
`;
