import React, { useEffect, useRef, useState, useContext } from "react";
import styled from "styled-components";
import $ from "jquery";
import { accountsConfig, learnConfig } from "../../../../axiosConfig";
import { Context } from "../../../contexts/Store";
import DatePicker from "react-date-picker";
import ButtonLoader from "../../../genaral/Loader/ButtonLoader";

function CreateSubscriptionModal({
    isModal,
    setModal,
    campusPk,
    campusUserPk,
    setSuccessful,
}) {
    //State
    const [selectedInput, setSelectedInput] = useState(false);
    const [selectedValue, setSelectedValue] = useState(null);
    const [selectedProgram, setSelectedProgram] = useState("jobsure-campus");
    const [address, setAddress] = useState("");
    const [addressTwo, setAddressTwo] = useState("");
    const [addressThree, setAddressThree] = useState("");
    const [zipCode, setZipCode] = useState("");
    const [transactionId, setTransactionId] = useState("");
    const [fromDate, setFromDate] = useState(new Date());
    const [errorMessage, setErrorMessage] = useState("");

    const [countryList, setCountryList] = useState([]);
    const [country, setCountry] = useState("");
    const [countryCode, setCountryCode] = useState("");
    const [filteredCountries, setFilteredCountries] = useState([]);

    const [states, setStates] = useState([]);
    const [selectedState, setSelectedState] = useState(null);
    const [stateId, setStateId] = useState("");
    const [filteredStates, setFilteredStates] = useState([]);

    const [district, setDistrict] = useState([]);
    const [selectedDistrict, setSelectedDistrict] = useState("");
    const [filteredDistrict, setFilteredDistrict] = useState([]);

    const [selectedCountry, setSelectedCountry] = useState(false);
    const [isButtonLoading, setButtonLoading] = useState(false);

    const formatDate = (date) => {
        const month = date.getMonth() + 1;
        const day = date.getDate();
        const year = date.getFullYear();

        const formattedMonth = month < 10 ? `0${month}` : `${month}`;
        const formattedDay = day < 10 ? `0${day}` : `${day}`;

        return `${formattedMonth}-${formattedDay}-${year}`;
    };
    const formattedDate = formatDate(fromDate);

    const today = new window.Date();

    const [plans, setPlans] = useState([
        {
            id: 1,
            title: "1 Lakhs",
            amount: "100000",
        },
        {
            id: 2,
            title: "5 Lakhs",
            amount: "500000",
        },
    ]);

    const handleProgramChange = (event) => {
        setSelectedProgram(event.target.value);
    };

    const handleZipCodeChange = (e, name) => {
        const inputValue = e.target.value;

        const numericInput = inputValue.replace(/[^0-9]/g, "");

        if (name === "zipCode") {
            setZipCode(numericInput);
        }
    };

    const { state } = useContext(Context);
    const access_token = state.user_data.access_token;

    //Out Side Click
    function useOutsideClick(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setModal(false);
                }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    const wrapperRef = useRef(null);
    useOutsideClick(wrapperRef);

    //No Scroll
    useEffect(() => {
        if (isModal) {
            $("html").addClass("modal-enabled");
        } else {
            $("html").removeClass("modal-enabled");
        }
    }, [isModal]);

    //Country list

    const fetchCountryList = () => {
        accountsConfig
            .get("/api/v1/users/settings/countries/")
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setCountryList(data);
                    setFilteredCountries(data);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    //State List

    const fetchStateList = () => {
        accountsConfig
            .get("/general/list-states/", {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setStates(data);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const fetchDistrictList = () => {
        accountsConfig
            .get("general/list-districts/", {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setDistrict(data);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    useEffect(() => {
        fetchCountryList();
        fetchStateList();
        fetchDistrictList();
    }, []);

    const handileSubscription = (e) => {
        e.preventDefault();
        const combinedAddresses = `${address}\n${addressTwo}\n${addressThree}`;
        const formData = new FormData();
        formData.append("campus_id", campusPk);
        formData.append("amount", selectedValue?.amount);
        formData.append("country", country || "");
        formData.append("country_code", countryCode || "");
        formData.append("address", combinedAddresses);
        formData.append("transaction_id", transactionId);
        formData.append("date", formattedDate);
        formData.append("subscription_type", "regular");
        console.log("Selected State:", selectedState);
        formData.append("state", selectedState);
        formData.append("state_code", stateId);
        formData.append("district_name ", selectedDistrict || "");
        formData.append("pincode ", zipCode || "");
        formData.append("user_pk", campusUserPk);

        setButtonLoading(true);
        learnConfig
            .post(
                `/subscriptions/start-campus-subscription/${selectedProgram}/`,
                formData,
                {
                    headers: {
                        Authorization: `Bearer ${access_token}`,
                    },
                }
            )
            .then((response) => {
                const { StatusCode, data, message } = response.data;
                if (StatusCode === 6000) {
                    setButtonLoading(false);
                    setModal(false);
                    setSuccessful(true);
                    handleModal();
                } else {
                    setErrorMessage(message.message);
                    setButtonLoading(false);
                }
            })
            .catch((error) => {
                console.log(error);
                setButtonLoading(false);
            });
    };

    const handleInputChange = (e, name) => {
        const inputValue = e.target.value;
        if (name === "country") {
            setCountry(inputValue);
            let filteredCountries = countryList.filter((country) =>
                country.name.toLowerCase().includes(inputValue.toLowerCase())
            );
            setFilteredCountries(filteredCountries);
        } else if (name === "state") {
            setSelectedState(inputValue);
            let filteredStates = states.filter((state) =>
                state.name.toLowerCase().includes(inputValue.toLowerCase())
            );
            setFilteredStates(filteredStates);
        } else if (name === "district") {
            setSelectedDistrict(inputValue);
            let filteredDistrict = district.filter((district) =>
                district.name.toLowerCase().includes(inputValue.toLowerCase())
            );
            setFilteredDistrict(filteredDistrict);
        }
    };

    const handleCountrySelect = (selectedCountry) => {
        setCountry(selectedCountry);
        setZipCode("");
        setFilteredCountries([]);
        setStateId("");
        setSelectedState("");
    };
    const handleStateSelect = (selectedState) => {
        setSelectedState(selectedState.name);
        setStateId(selectedState.state_code);
        setSelectedDistrict("");
        setZipCode("");
        setFilteredStates([]);
    };
    const handleDistrictSelect = (selectedDistrictValue) => {
        setSelectedDistrict(selectedDistrictValue);
        setZipCode("");
        setFilteredDistrict([]);
    };

    //Clear All
    const handleModal = () => {
        setSelectedInput(false);
        setSelectedValue(null);
        setTransactionId("");
        setCountry("");
        setSelectedState("");
        setSelectedDistrict("");
        setZipCode("");
        // setAddress("");
        setModal(false);
    };
    const isSubmitDisabled =
        !selectedValue ||
        !address ||
        !addressTwo ||
        !addressThree ||
        !selectedProgram ||
        !transactionId ||
        !country ||
        !fromDate;

    return (
        <BackContainer>
            {isModal ? (
                <Overlay
                    onClick={() => setModal(false)}
                    style={{ display: isModal ? "block" : "none" }}
                ></Overlay>
            ) : (
                ""
            )}
            <Cover style={{ transform: isModal && "scale(1,1)" }}>
                <Modal>
                    <ContainerBox>
                        <Container>
                            <Top>
                                <Head>
                                    <Headtitle>Create Subscription</Headtitle>
                                    <Closeicon onClick={handleModal}>
                                        <img
                                            src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/05-04-2022/close.png"
                                            alt="Icon"
                                        />
                                    </Closeicon>
                                </Head>
                            </Top>
                            <DetailContainer>
                                <SubHead>Program * :</SubHead>
                                <ProgramDiv>
                                    <ProgramLabel>
                                        Job sure
                                        <RadioInput
                                            type="radio"
                                            value="jobsure-campus"
                                            name="program"
                                            checked={
                                                selectedProgram ===
                                                "jobsure-campus"
                                            }
                                            onChange={handleProgramChange}
                                        />
                                    </ProgramLabel>
                                    <ProgramLabel>
                                        Tech @ school
                                        <RadioInput
                                            type="radio"
                                            value="tech-at-school"
                                            name="program"
                                            checked={
                                                selectedProgram ===
                                                "tech-at-school"
                                            }
                                            onChange={handleProgramChange}
                                        />
                                    </ProgramLabel>
                                </ProgramDiv>
                            </DetailContainer>
                            <DetailContainer className="drop">
                                <SubHead>Amount * :</SubHead>
                                <DurationSelect
                                    onClick={() =>
                                        setSelectedInput(!selectedInput)
                                    }
                                >
                                    <DurationOptions>
                                        {selectedValue
                                            ? selectedValue.title
                                            : "Select your amount"}
                                    </DurationOptions>
                                </DurationSelect>
                                {selectedInput && (
                                    <SelectDuration>
                                        {plans.map((plan) => (
                                            <Option
                                                onClick={() => {
                                                    setSelectedValue(plan);
                                                    setSelectedInput(false);
                                                }}
                                                key={plan.id}
                                            >
                                                {plan.title}
                                            </Option>
                                        ))}
                                    </SelectDuration>
                                )}
                            </DetailContainer>
                            <DetailContainer>
                                <SubHead>Mode of payment :</SubHead>
                                <Content>Single Payment</Content>
                            </DetailContainer>
                            <DetailContainer>
                                <SubHead>Duration :</SubHead>
                                <Content>3 Years</Content>
                            </DetailContainer>
                            <DetailContainer>
                                <SubHead>Transaction ID * :</SubHead>
                                <ValueInpt
                                    placeholder="Enter Transaction Id"
                                    value={transactionId}
                                    onChange={(e) =>
                                        setTransactionId(e.target.value)
                                    }
                                />
                            </DetailContainer>
                            {/* <DetailContainer>
                            <SubHead>Invoice ID * :</SubHead>
                            <ValueInpt placeholder="Enter Invoice Id" />
                        </DetailContainer>
                        <DetailContainer>
                            <SubHead>GST Number * :</SubHead>
                            <ValueInpt placeholder="Enter GST Number" />
                        </DetailContainer> */}
                            <DetailContainer>
                                <SubHead>Transaction date * :</SubHead>
                                <DateContainer>
                                    <DatePicker
                                        format={"dd-MM-yy"}
                                        dayPlaceholder="DD"
                                        monthPlaceholder="MM"
                                        yearPlaceholder="YY"
                                        onChange={(value) => setFromDate(value)}
                                        value={fromDate}
                                        clearIcon={null}
                                        calendarIcon={null}
                                        maxDate={today}
                                    />
                                </DateContainer>
                            </DetailContainer>
                            {/* <DetailContainer>
                                <SubHead>Address * :</SubHead>
                                <ValueText
                                    placeholder="Enter Address"
                                    value={address}
                                    onChange={(e) => setAddress(e.target.value)}
                                />
                            </DetailContainer> */}
                            <DetailContainer>
                                <SubHead>Address Line 1* :</SubHead>
                                <ValueInpt
                                    placeholder="Enter your address 1 "
                                    value={address}
                                    onChange={(e) => setAddress(e.target.value)}
                                />
                            </DetailContainer>
                            <DetailContainer>
                                <SubHead>Address Line 2 /Road * :</SubHead>
                                <ValueInpt
                                    placeholder="Enter your address 2"
                                    value={addressTwo}
                                    onChange={(e) =>
                                        setAddressTwo(e.target.value)
                                    }
                                />
                            </DetailContainer>
                            <DetailContainer>
                                <SubHead>Address Line 3 /District * :</SubHead>
                                <ValueInpt
                                    placeholder="Enter your address 3"
                                    value={addressThree}
                                    onChange={(e) =>
                                        setAddressThree(e.target.value)
                                    }
                                />
                            </DetailContainer>
                            <DetailContainer className="country">
                                <SubHead>Country * :</SubHead>
                                <div style={{ width: "50%" }}>
                                    <ValueInpt
                                        placeholder="Select Country"
                                        value={country}
                                        className="country"
                                        onChange={(e) => {
                                            handleInputChange(e, "country");
                                            setSelectedCountry(true);
                                        }}
                                    />
                                    <CountryDiv>
                                        {selectedCountry === true && (
                                            <SelectCountry>
                                                {filteredCountries.length >
                                                0 ? (
                                                    filteredCountries.map(
                                                        (
                                                            filteredCountry,
                                                            index
                                                        ) => (
                                                            <Option
                                                                key={index}
                                                                onClick={() => {
                                                                    handleCountrySelect(
                                                                        filteredCountry.name
                                                                    );
                                                                    setCountryCode(
                                                                        filteredCountry.country_code
                                                                    );
                                                                    setSelectedCountry(
                                                                        false
                                                                    );
                                                                }}
                                                            >
                                                                {
                                                                    filteredCountry.name
                                                                }
                                                            </Option>
                                                        )
                                                    )
                                                ) : (
                                                    <Option>No data</Option>
                                                )}
                                            </SelectCountry>
                                        )}
                                    </CountryDiv>
                                </div>
                            </DetailContainer>
                            {country === "India" && (
                                <>
                                    <DetailContainer className="country">
                                        <SubHead>State * :</SubHead>
                                        <div style={{ width: "50%" }}>
                                            <ValueInpt
                                                placeholder="Select State"
                                                value={selectedState}
                                                className="country"
                                                onChange={(e) => {
                                                    handleInputChange(
                                                        e,
                                                        "state"
                                                    );
                                                    setSelectedCountry(false);
                                                }}
                                            />
                                            <StateDiv>
                                                {selectedCountry === false && (
                                                    <SelectState>
                                                        {filteredStates?.map(
                                                            (
                                                                filteredState,
                                                                index
                                                            ) => (
                                                                <Option
                                                                    key={index}
                                                                    onClick={() =>
                                                                        handleStateSelect(
                                                                            filteredState
                                                                        )
                                                                    }
                                                                >
                                                                    {
                                                                        filteredState.name
                                                                    }
                                                                </Option>
                                                            )
                                                        )}
                                                    </SelectState>
                                                )}
                                            </StateDiv>
                                        </div>
                                    </DetailContainer>
                                    <DetailContainer className="district">
                                        <SubHead>District * :</SubHead>
                                        <div style={{ width: "50%" }}>
                                            <ValueInpt
                                                placeholder="Select District"
                                                value={selectedDistrict}
                                                className="country"
                                                onChange={(e) => {
                                                    handleInputChange(
                                                        e,
                                                        "district"
                                                    );
                                                    setSelectedCountry(false);
                                                }}
                                            />
                                            <DistrictDiv>
                                                {selectedCountry === false && (
                                                    <SelectState>
                                                        {filteredDistrict?.map(
                                                            (
                                                                filteredDistrict,
                                                                index
                                                            ) => (
                                                                <Option
                                                                    key={index}
                                                                    onClick={() =>
                                                                        handleDistrictSelect(
                                                                            filteredDistrict.name
                                                                        )
                                                                    }
                                                                >
                                                                    {
                                                                        filteredDistrict.name
                                                                    }
                                                                </Option>
                                                            )
                                                        )}
                                                    </SelectState>
                                                )}
                                            </DistrictDiv>
                                        </div>
                                    </DetailContainer>
                                </>
                            )}
                            <DetailContainer>
                                <SubHead>Pincode Code / Zip Code * :</SubHead>
                                <ValueInpt
                                    placeholder="Enter your pincode"
                                    value={zipCode}
                                    onChange={(e) =>
                                        handleZipCodeChange(e, "zipCode")
                                    }
                                    type="text"
                                    maxLength="6"
                                />
                            </DetailContainer>
                            <Bottom>
                                <SubmitButton
                                    onClick={handileSubscription}
                                    disabled={isSubmitDisabled}
                                >
                                    {isButtonLoading ? (
                                        <ButtonLoader />
                                    ) : (
                                        "Create"
                                    )}
                                </SubmitButton>
                            </Bottom>
                            <Error className="error">
                                {errorMessage ? errorMessage : ""}
                            </Error>
                        </Container>
                    </ContainerBox>
                </Modal>
            </Cover>
        </BackContainer>
    );
}

export default CreateSubscriptionModal;

const Cover = styled.div`
    position: fixed;
    transition: 0.3s;
    transform: scale(0, 0);
    width: 100%;
    height: 100vh;
    z-index: 1000;
    left: 0;
    top: 0px;
`;

const BackContainer = styled.div``;
const Overlay = styled.div`
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(2px);
    position: fixed;
    z-index: 10;
    left: 0;
    top: 0px;
    width: 100%;
    z-index: 1000;
    min-height: 100vh;
    max-height: 100vh;
    filter: blur(1px);
`;
const Modal = styled.div`
    width: 90%;
    max-width: 640px;
    max-height: 100vh;
    position: absolute;
    margin: 0 auto;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: 0.5s;
    z-index: 101;
    border: 1px solid #26272a;
    background: #1b1c1f;
    border-radius: 5px;
    /* overflow-y: hidden; */
    box-shadow: 0px 3px 56px #00000048;

    @media all and (max-width: 1280px) {
        max-height: 93vh;
        /* overflow-y: scroll; */
        &::-webkit-scrollbar {
            display: none;
        }
    }
    @media all and (max-width: 400px) {
        max-height: 70vh;
        /* overflow-y: scroll; */
        &::-webkit-scrollbar {
            display: none;
        }
    }
`;
const ContainerBox = styled.div``;
const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 40px 35px;
    width: 100%;
    /* height: 100%; */
    max-height: 65vh;
    overflow-y: scroll;
    overflow-x: hidden;
    &::-webkit-scrollbar {
        display: none;
    }
    @media all and (max-width: 768px) {
        padding: 30px;
    }
    @media all and (max-width: 480px) {
        width: 500px;
        overflow-x: scroll;
        &::-webkit-scrollbar {
            display: none;
        }
    }
    @media all and (max-width: 360px) {
        padding: 10px;
    }
`;
const Top = styled.div`
    border-bottom: 2px solid #26272a;
`;
const Head = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
`;
const Headtitle = styled.p`
    font-size: 18px;
    color: #fff;
    font-family: gordita_medium;
`;
const Closeicon = styled.div`
    width: 18px;
    cursor: pointer;
    img {
        width: 100%;
        display: block;
    }
`;

const DetailContainer = styled.form`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 12px;
    align-items: center;
    &.drop {
        position: relative;
    }
    &.country {
        display: flex;
        position: relative;
    }
    &.district {
        display: flex;
        position: relative;
    }
`;

const SubHead = styled.div`
    color: #fff;
    font-size: 16px;
    font-family: "gordita_regular";
`;

const ProgramDiv = styled.div`
    display: flex;
    gap: 12px;
    align-items: center;
    width: 50%;
`;
const DateContainer = styled.div`
    position: relative;
    width: 50%;
    & div.react-date-picker {
        /* padding: 10px 20px; */
    }
    & div.react-date-picker__calendar {
        /* position: absolute;
        right: 300px; */
        inset: -100% auto auto -100px !important;
    }
`;

const ProgramLabel = styled.label`
    color: #fff;
    font-size: 15px;
    font-family: "gordita_regular";
    margin-left: 10px;
    display: flex;
    align-items: center;

    &:first-child {
        margin-left: 0;
    }
`;

const RadioInput = styled.input`
    margin-left: 10px;
    height: 20px;
    width: 20px;
    appearance: none;
    /* outline: none; */
    border-radius: 50%;
    border: 3px solid rgb(38, 39, 42);
    background-color: #fff;
    &:checked {
        border: 3px solid rgb(38, 39, 42);
        background-color: rgb(254, 103, 58);
    }
`;
const DurationSelect = styled.div`
    width: 50%;
    color: rgb(255, 255, 255);
    padding: 10px;
    border: 1px solid rgb(38, 39, 42);
    cursor: pointer;
    background-color: rgb(22, 22, 25) !important;
    border-radius: 5px !important;
`;
const DurationOptions = styled.div`
    font-size: 15px;
    font-family: "gordita_regular";
    padding: 4px;
`;
const SelectDuration = styled.div`
    width: 50%;
    color: rgb(255, 255, 255);

    border: 1px solid rgb(38, 39, 42);
    cursor: pointer;
    background-color: rgb(22, 22, 25) !important;
    border-radius: 5px !important;
    position: absolute;
    right: 0;
    top: 47px;
`;
const CountryDiv = styled.div`
    width: 100%;
    position: absolute;
    z-index: 888;
`;
const SelectCountry = styled.div`
    width: 50%;
    color: rgb(255, 255, 255);
    border: 1px solid rgb(38, 39, 42);
    cursor: pointer;
    background-color: rgb(22, 22, 25) !important;
    border-radius: 5px !important;
    max-height: 180px;
    overflow-y: scroll;
    &::-webkit-scrollbar {
        display: none;
    }
`;
const StateDiv = styled.div`
    width: 100%;
    position: absolute;
    z-index: 999;
`;

const SelectState = styled.div`
    width: 50%;
    color: rgb(255, 255, 255);
    border: 1px solid rgb(38, 39, 42);
    cursor: pointer;
    background-color: rgb(22, 22, 25) !important;
    border-radius: 5px !important;
    max-height: 180px;
    overflow-y: scroll;
    &::-webkit-scrollbar {
        display: none;
    }
`;

const DistrictDiv = styled.div`
    width: 100%;
    position: absolute;
    z-index: 888;
`;

const SelectDistrict = styled.div`
    width: 50%;
    color: rgb(255, 255, 255);
    border: 1px solid rgb(38, 39, 42);
    cursor: pointer;
    background-color: rgb(22, 22, 25) !important;
    border-radius: 5px !important;
    max-height: 180px;
    overflow-y: scroll;
    &::-webkit-scrollbar {
        display: none;
    }
`;

const Option = styled.h4`
    font-size: 15px;
    margin-bottom: 4px;
    border-bottom: 1px solid rgb(38, 39, 42);
    padding: 10px;
    &:last-child {
        border-bottom: 0;
    }
    &:hover {
        background-color: rgb(27, 28, 31);
    }
`;

const Content = styled.h4`
    color: #fff;
    font-size: 15px;
    font-family: "gordita_regular";
    width: 48%;
`;
const ValueInpt = styled.input`
    width: 50%;
    color: rgb(255, 255, 255);
    padding: 15px;
    border-radius: 1px solid rgb(38, 39, 42);
    /* cursor: pointer; */
    background-color: rgb(22, 22, 25) !important;
    border-radius: 5px !important;
    &.country {
        width: 100%;
    }
    &.state {
        width: 100%;
    }
    &.district {
        width: 100%;
    }
`;
const ValueText = styled.textarea`
    resize: none;
    width: 100%;
    min-height: 150px;
    color: rgb(255, 255, 255);
    padding: 14px;
    border: ${(props) =>
        props.isSelected ? "1px solid #fff" : "1px solid rgb(38, 39, 42)"};
    cursor: pointer;
    background-color: rgb(22, 22, 25) !important;
    border-radius: 5px !important;
`;

const Bottom = styled.div`
    display: flex;
    justify-content: flex-end;
`;
const Error = styled.p`
    font-size: 12px;
    color: #ff3333;
    margin-left: 2px;
`;

const SubmitButton = styled.button`
    background: rgb(254, 103, 58);
    color: rgb(255, 255, 255);
    width: 120px;
    height: 40px;
    justify-content: center;
    display: flex;
    font-size: 14px;
    align-items: center;
    cursor: pointer;
    font-family: "gordita_medium";
    border-radius: 5px !important;
    &:disabled {
        cursor: not-allowed;
    }
`;
