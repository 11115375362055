import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { accountsConfig } from "../../../axiosConfig";
import TechUserModal from "../../includes/request-dp/modal/TechUserModal";
import { Context } from "../../contexts/Store";
import {
    getDateStr,
    getTimeStrFromDate,
    secondsTohms,
} from "../../helpers/functions";
import NoData from "../../includes/jobdesk/NoData";
import SectionLoader from "../../helpers/SectionLoader";
import ButtonLoader from "../../genaral/Loader/ButtonLoader";
import { Button } from "@mui/material";
import ReactPaginate from "react-paginate";
import { useSearchParams } from "react-router-dom";
import ApproveModal from "../../includes/request-dp/modal/ApproveModal";
import RejectedModal from "../../includes/request-dp/modal/RejectedModal";
import SteypHelmet from "../../helpers/SteypHelmet";

function ProfilePictures() {
    const [isModal, setModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [studentDetails, setStudentDetails] = useState([]);
    const { state } = useContext(Context);
    const access_token = state.user_data.access_token;
    const [clickedAvatar, setClickedAvatar] = useState();
    const [approvemodal, setApprovemodal] = useState(false);
    const [userId, setUserId] = useState();
    const [varifyLoading, setVarifyLoading] = useState(false);
    const [RejectedLoading, setRejectedLoading] = useState(false);
    const [isSelected, setSelected] = useState("approved");
    const [rejectSelect, setRejectSelect] = useState(false);
    const [reason, setReason] = useState("");
    const [pagination, setPagination] = useState();

    //search params
    const [searchQuery, setSearchQuery] = useState("");
    const [searchParams, setSearchParams] = useSearchParams();
    const currentParams = Object.fromEntries([...searchParams]);
    const [currentPage, setCurrentPage] = useState(1);

    const filteredStudentDetails = studentDetails?.filter((student) =>
        student.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const handlePageClick = ({ selected: selectedPage }) => {
        setCurrentPage(selectedPage + 1);
        setSearchParams({
            ...currentParams,
            page: selectedPage + 1,
        });
    };

    const fetchStudentDetails = () => {
        setLoading(true);
        accountsConfig
            .get(`api/v1/users/student-profile/pictures/`, {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
                params: {
                    page: currentPage,
                },
            })
            .then((response) => {
                const { StatusCode, data, pagination_data } = response.data;
                if (StatusCode === 6000) {
                    setStudentDetails(data);
                    setLoading(false);
                    setPagination(pagination_data);
                } else if (StatusCode === 6001) {
                    setLoading(false);
                }
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
            });
    };

    const updateStatus = () => {
        setRejectedLoading(true);
        const formData = new FormData();
        formData.append("status", isSelected);
        if (reason) {
            formData.append("reason", reason);
        }

        accountsConfig
            .post(`api/v1/users/student-profile/verify/${userId}/`, formData, {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setStudentDetails(data);
                    setUserId();
                    setApprovemodal(false);
                    setReason("");
                    setSelected("approved");
                    setRejectSelect(false);
                    fetchStudentDetails();
                    setRejectedLoading(false);
                } else if (StatusCode === 6001) {
                    setLoading(false);
                    setRejectedLoading(false);
                }
            })
            .catch((err) => {
                console.log(err);
                setVarifyLoading(false);
                setRejectedLoading(false);
            });
    };

    useEffect(() => {
        fetchStudentDetails();
    }, [currentPage, searchParams]);

    return (
        <>
            <SteypHelmet title="Verification" />
            <TechUserModal
                isModal={isModal}
                setModal={setModal}
                clickedAvatar={clickedAvatar}
            />

            <ApproveModal
                isModal={approvemodal}
                setModal={setApprovemodal}
                setSelected={setSelected}
                updateStatus={updateStatus}
                setRejectSelect={setRejectSelect}
                RejectedLoading={RejectedLoading}
                varifyLoading={varifyLoading}
                setReason={setReason}
                isSelected={isSelected}
            />
            <RejectedModal
                isModal={rejectSelect}
                setModal={setRejectSelect}
                setApprovemodal={setApprovemodal}
                setReason={setReason}
                setRejectSelect={setRejectSelect}
                setSelected={setSelected}
                updateStatus={updateStatus}
                RejectedLoading={RejectedLoading}
                reason={reason}
            />
            <Container>
                <TopContainer>
                    <Heading>Profile Pictures</Heading>
                    <SearchContainer>
                        <SearchBar>
                            <InputField
                                placeholder="Search Here"
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                            />
                        </SearchBar>
                        <SearchImageContainer>
                            <SearchImg
                                src={
                                    require("../../../assets/images/request/search-icon.svg")
                                        .default
                                }
                                alt="Search-icon"
                            />
                        </SearchImageContainer>
                    </SearchContainer>
                </TopContainer>

                <BottomContainer>
                    <MainHead>
                        <MainSub>
                            <Contents type="slno">Sl.No</Contents>
                            <Contents type="student">Student</Contents>
                            <Contents type="program">Program</Contents>
                            <Contents type="date&time">Date&Time</Contents>

                            {/* <Contents type="time">Time</Contents> */}
                            <Contents type="action">Action</Contents>
                        </MainSub>
                    </MainHead>
                    {filteredStudentDetails?.length > 0 ? (
                        filteredStudentDetails?.map((student, index) => (
                            <>
                                <UlList key={student.id}>
                                    {pagination?.first_item <=
                                        pagination?.last_item && (
                                        <Li type="slno">
                                            {pagination?.first_item + index}
                                        </Li>
                                    )}
                                    <Li type="student">
                                        <ImageContainer
                                            onClick={() =>
                                                setClickedAvatar(
                                                    student.cropped_image
                                                )
                                            }
                                        >
                                            <img
                                                src={student.cropped_image}
                                                alt="Profile"
                                                onClick={() =>
                                                    setModal(!isModal)
                                                }
                                            />
                                        </ImageContainer>
                                        <StudentInfo>
                                            <span>{student.name}</span>
                                            <Phone>{student.phone}</Phone>
                                        </StudentInfo>
                                    </Li>
                                    <Li type="program">
                                        <Program>{student.program}</Program>
                                    </Li>
                                    <Li type="date&time">
                                        <Date>
                                            {getDateStr(student.uploaded_time)}
                                        </Date>
                                        <Time>
                                            {getTimeStrFromDate(
                                                student.uploaded_time
                                            )}
                                        </Time>
                                    </Li>
                                    {/* <Li type="time">
                                        {getTimeStrFromDate(
                                            student.uploaded_time
                                        )}
                                    </Li> */}

                                    <Li type="action">
                                        {student.approvel_status ===
                                        "approved" ? (
                                            <Updated>Verified</Updated>
                                        ) : student.approvel_status ===
                                          "rejected" ? (
                                            <NotApprov>Rejected</NotApprov>
                                        ) : (
                                            <Update
                                                onClick={() => {
                                                    setApprovemodal(true);
                                                    setUserId(student.id);
                                                }}
                                            >
                                                Verify
                                            </Update>
                                        )}
                                    </Li>
                                </UlList>
                            </>
                        ))
                    ) : loading ? (
                        <SectionLoader />
                    ) : (
                        <NoData />
                    )}
                </BottomContainer>
            </Container>
            {pagination?.total_pages > 1 && studentDetails?.length > 0 && (
                <PaginationContainer>
                    <PaginationText>
                        Showing students {pagination?.first_item} -{" "}
                        {pagination?.last_item} of {pagination?.total_items}
                    </PaginationText>
                    <ReactPaginate
                        previousLabel={"< Prev"}
                        nextLabel={"Next >"}
                        pageCount={pagination?.total_pages}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination"}
                        previousLinkClassName={"pagination__link"}
                        nextLinkClassName={"pagination__link"}
                        disabledClassName={"pagination__link--disabled"}
                        activeClassName={"pagination__link--active"}
                    />
                </PaginationContainer>
            )}
        </>
    );
}

export default ProfilePictures;

const Container = styled.div`
    @media all and (max-width: 1350px) {
    }
    overflow-x: scroll;
    ::-webkit-scrollbar {
        display: none;
    }
`;
const Top = styled.div`
    margin-bottom: 15px;
`;

const Head = styled.div`
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #26272a;
    align-items: center;
    padding-bottom: 15px;
    @media all and (max-width: 480px) {
        padding-bottom: 10px;
    }
`;
const MainTitle = styled.h3`
    font-size: 18px;
    color: #fff;
    font-family: "gordita_medium";
    @media all and (max-width: 640px) {
        font-size: 20px;
    }
    @media all and (max-width: 480px) {
        font-size: 18px;
    }
`;
const Close = styled.span`
    cursor: pointer;
    width: 18px;
    @media all and (max-width: 640px) {
        width: 15px;
    }
`;
const CloseIcon = styled.img`
    width: 100%;
    display: block;
`;
const TopContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
`;
const Heading = styled.p`
    font-size: 18px;
    color: rgb(255, 255, 255);
    font-family: "gordita_medium";
`;

const SearchContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-right: 10px;
    border: 2px solid #1b1c20;
    border-radius: 3px;
`;
const SearchBar = styled.div`
    // padding: 10px 24px;
    background: #161619;
    border-radius: 5px;
    width: 200px;
    margin-right: 10px;
`;
const SearchImageContainer = styled.div`
    background: #161619;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 10%;
    padding: 10px 0;
    cursor: pointer;
`;
const SearchImg = styled.img`
    width: 100%;
    display: block;
`;
const InputField = styled.input`
    color: #fff;
    font-size: 14px;
    padding: 10px;
    width: 100%;
`;

const ImageBox = styled.div`
    margin: 0 auto;
    width: 20%;
    margin-top: 100px;
    height: calc(100% - (-50px));
`;

const BottomContainer = styled.ul`
    color: rgb(255, 255, 255);
    border-radius: 5px;
    width: 100%;
    background-color: #1b1c1f;
    border: 1px solid rgb(38, 39, 42);
    min-width: 1180px;
    // display: grid;
    // grid-template-columns: 1fr 1fr 1fr;
    // grid-gap: 10px;
    // @media all and (max-width: 1080px) {
    //     grid-template-columns: 1fr 1fr;
    // }
    // @media all and (max-width: 640px) {
    //     grid-template-columns: 1fr;
    // }
`;
const MainHead = styled.div`
    display: flex;
    flex-direction: column;
    font-size: 18px;
    color: rgb(255, 255, 255);
    border-bottom: 1px solid #000;
`;
const UpdatedContainer = styled.div`
    border-top: 3px solid #000;
`;
const NotApproval = styled.div`
    border-top: 3px solid #000;
`;
const MainSub = styled.div`
    display: flex;
    -webkit-box-align: center;
    -webkit-box-pack: justify;
    justify-content: space-between;
    width: 100%;
    padding: 20px;
    color: rgb(255, 255, 255);
    border-radius: 5px;
`;
const Contents = styled.div`
    font-family: "gordita_medium";
    font-size: 14px;
    text-align: left;
    width: ${({ type }) =>
        type === "slno"
            ? "5%"
            : type === "student"
            ? "20%"
            : type === "program"
            ? "20%"
            : type === "date&time"
            ? "20%"
            : // : type === "time"
            //     ? "10%"
            type === "action"
            ? "15%"
            : ""};
    :nth-child(5) {
        /* text-align: center; */
    }
    :nth-child(2) {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    :last-child {
        align-items: center;
        text-align: center;
    }
`;
const UlList = styled.ul`
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 20px;
    color: rgb(255, 255, 255);
    border-radius: 5px;
    align-items: center;
`;
const Li = styled.li`
    display: flex;
    font-size: 14px;
    width: ${({ type }) =>
        type === "slno"
            ? "5%"
            : type === "student"
            ? "20%"
            : type === "program"
            ? "20%"
            : type === "date&time"
            ? "20%"
            : // : type === "time"
            //     ? "10%"
            type === "action"
            ? "15%"
            : ""};
    :nth-child(5) {
        /* text-align: center; */
    }
    :nth-child(2) {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    :nth-child(4) {
        display: flex;
        flex-direction: column;
    }
    :last-child {
        text-align: center;
        justify-content: center;
    }
`;
const Date = styled.div`
    color: #d2caca;
    font-family: "gordita_medium";

    font-size: 16px;
    font-style: normal;
    font-weight: 400;
`;
const Time = styled.div`
    color: rgba(254, 103, 58, 1);
    font-family: "gordita_medium";
`;

const StudentInfo = styled.div`
    margin-left: 10px;
`;
const ImageContainer = styled.div`
    width: 60px;
    height: 60px;
    overflow: hidden;
    border-radius: 50%;
    cursor: pointer;
    img {
        display: block;
        width: 100%;
        height: 100%;
    }
`;
const Phone = styled.p`
    text-align: left;
    color: rgb(254, 103, 58);
    font-size: 14px;
`;
const AImg = styled.a`
    display: inline-block;
    width: 30%;
`;
const Program = styled.p`
    color: #fff;
`;
const DownIcon = styled.img`
    display: block;
    width: 100%;
`;
const Update = styled.button`
    border: 1px solid #fe673a;
    background-color: #fe673a;
    cursor: pointer;
    color: #fff;
    padding: 5px 15px;
    border-radius: 5px;
    margin-bottom: 5px;
    width: 150px;
    height: 40px;
`;
const Updated = styled.button`
    border: 1px solid #0fa76f;
    background-color: #0fa76f;
    cursor: not-allowed;
    color: #fff;
    padding: 5px 15px;
    border-radius: 5px;
    margin-bottom: 5px;
    width: 150px;
    height: 40px;
`;
const NotApprov = styled.button`
    border: 1px solid #ff3030;
    background-color: #ff0000;
    color: #fff;
    padding: 5px 15px;
    cursor: not-allowed;
    border-radius: 5px;
    margin-bottom: 5px;
    width: 150px;
    height: 40px;
`;
const Remarks = styled.div``;
const Overlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
`;
const VerifyOverlayContent = styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #1b1c20;
    height: 173px;
    border-radius: 8px;
    padding: 20px;
    z-index: 1001;
    display: flex;
    flex-direction: column;
    width: 450px;

    @media (max-width: 640px) {
        height: 175px;
    }
    @media (max-width: 480px) {
        width: 290px;
        height: 190px;
    }
    @media (max-width: 360px) {
        width: 290px;
        height: 190px;
    }
`;
const SureOption = styled.h2`
    margin-bottom: 10px;
    color: #ffff;
    font-size: 18px;
    line-height: 28px;
    font-family: "gordita_bold";
`;
const CloseButton = styled.button`
    display: flex;
    justify-content: flex-end;
    width: 60px;
    cursor: pointer;
    @media (max-width: 360px) {
        justify-content: center;
    }
`;
const Paragraph = styled.p`
    margin-bottom: 20px;
    color: #64748b;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
`;
const CancelButton = styled.button`
    width: 80px;
    height: 40px;
    border: 1px solid #64748b;
    border-radius: 6px;
    cursor: pointer;
    padding: 8px, 16px, 8px, 16px;
    gap: 10px;
    color: white;
    font-family: "gordita_medium";
`;

const VerifyButton = styled.button`
    width: 103px;
    height: 40px;
    background-color: #ce582f;
    color: #fff;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    margin-left: 10px;
    padding: 8px, 16px, 8px, 16px;
    gap: 10px;
    font-family: "gordita_medium";
`;

const PaginationContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 40px 0 15px;
    @media all and (max-width: 890px) {
        flex-direction: column;
    }
`;
const PaginationText = styled.p`
    color: #898989;
    font-size: 14px;
    @media all and (max-width: 890px) {
        margin-bottom: 10px;
    }
`;
